import { useEffect, useRef, useState } from "react"
import { shuffle } from "gsap/all"
import { GameBg } from ".."
import './game-ali-word.scss'
import { WordfindGame } from "./Wordfind/WordfindGame"
import { random } from "gsap/all"

const WORDS = [
  [
    "Фича", "Приложение", "Поиск", "Миллион", "Радость", "Корзина", "Покупка", "Аливойс", "Сток", "Доставка", "Посылка", "Китай", "Склад", "Скорость", "Красный", "Скидка", "Отзыв", "Купон", "Шопинг", "Команда", "Китайский", "Алик", "Товар", "Альпака", "Дракон", "Динг", "Заказ", "Цена", "Выгода", "Селлер", "Распродажа", "Топтовар", "Чойс", "Тюбмен", "Промокод", "Купон"
  ],
  [
    "Квакция", "Логин", "Биллинг", "Выгодно", "Пользователь", "Редкость", "Промокод", "Таскмеханика", "Стикер", "Быстро", "Бренд", "Демо", "Поддержка", "Продавец", "Карточка", "Даймонд", "Корзина", "Дешевле", "Диспут", "Наличие", "Архив", "Аутинг", "Трешхолд", "Хит", "Алиланг", "Склад", "Десктоп", "Демо", "Полка", "Валюта", "Горящий", "Реклама", "Товар", "Бабазина", "Спор", "Посылка"
  ],
  [
    "Трафик", "Аккаунт", "Уведомление", "Баллы", "Пользователь", "Новинка", "Топтовар", "Брендбук", "Распродажа", "Структура", "Заказ", "Ретро", "Дейлик", "Продавать", "Полка", "Адрес", "Бондовый", "Рейтинг", "Фулфилмент", "Промо", "Корзина", "Корпоратив", "Дежурство", "Бесплатно", "Активация", "Возврат", "Посылка", "Постмортем", "Горящий", "Доставка", "Рассылка", "Логистика", "Ачивка", "Скидка", "Избранное", "Постамат"
  ]
]

const getWords = (index, count) => shuffle(WORDS[index])
  .slice(0, count)
  .map(w => w.toLowerCase())


export function GameAliWord({
  index = 0,
  wordsCount = 6,
  onFind = () => {},
  onFinish = () => { },
  showWords
}) {  
  const [words, setWords] = useState([])
  const [foundWords, setFoundWords] = useState([])
  const orientations = useRef(['horizontal', 'vertical'])
  const forceOrientation = useRef()


  useEffect(() => {
    setWords(getWords(index, wordsCount))

    if (index === 1) {
      orientations.current = ['diagonal', 'horizontal', 'vertical']
      forceOrientation.current = 'diagonal'
    } else if (index === 2) {
      const special = random(['topRightCornerCW', 'bottomRightCornerCCW', 'topLeftCornerCW', 'bottomLeftCornerCCW'])

      orientations.current = ['diagonal', special, 'horizontal', 'vertical']
      forceOrientation.current = special
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFindWord = (word) => {
    setFoundWords([...foundWords, word])
    onFind()
  }

  return (
    <>
      <div className="game-wordfind">
        <GameBg />
        <div className="game-wordfind__container">
          {words.length && (
            <WordfindGame
              orientations={orientations.current}
              forceOrientation={forceOrientation.current}
              words={words}
              onFind={onFindWord}
              onComplete={onFinish}
            />
          )}
          {showWords && (
            <div className="game-wordfind__words">{words.map((word, i) => (
            <span key={word}>
              <span
                className={`game-wordfind__word ${foundWords.includes(word) ? 'game-wordfind__word_found' : ''}`.trim()}>
                {word}
              </span>
              {i < words.length - 1 && <>, </>}
            </span>
            ))}</div>
          )}
        </div>
      </div>
    </>
  )
}
