import { memo } from "react"

import Title from '../../common/Title';
import ScrollBar from "../ScrollBar/ScrollBar";
import './PrizesPopup.scss'

import { getImageUrl } from '@assets';

const prizes = [1, 2, 3, 4, 5]

const PrizesPopup = () => {
    return (
        <div className="PrizesPopup">
            <Title cssClass="PrizesPopup-title">
                Призы
            </Title>

            <ScrollBar>
                <div className="PrizesPopup-list">
                    {prizes.map((item, i)=> (
                        <div className="PrizesPopup-img" key={i}>
                            <picture>
                                <source media="(max-width: 480px)" srcSet={getImageUrl( `place/${item}-m.webp`)}  width={343}  height={190} />
                                <source media="(max-width: 768px)" srcSet={getImageUrl( `place/${item}-t.webp`)}  width={672}  height={190} />
                                <img src={getImageUrl( `place/${item}.webp` )} alt="" width={775}  height={190} />
                            </picture>
                        </div>
                    ))}
                </div>
            </ScrollBar>
        </div>
    )
}

export default memo(PrizesPopup);