import { memo } from "react"
import styled from "styled-components"

const StyledText = styled.p`
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    text-align: center;
    color: ${({ $color }) => $color};
    @media (max-width: 480px) {
        font-size: 16px;
        line-height: 1.2;
    }
`
const Text = ({ children, cssClass, color = 'var(--c-red)' }) => {
    return (
        <StyledText $color={color} className={cssClass}>
            {children}
        </StyledText>
    )
}

export default memo(Text);