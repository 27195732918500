import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocalStorage } from "@hooks";
import { setProduct, setFormOrders, setOpenSuccess, fetchUser } from '@store/features';
import axios from "axios";

export const useBasket = () => {
    const dispatch = useDispatch();
    const { localProducts, openSuccessScene } = useSelector(state => state.products);
    const user = useSelector(state => state.user);
    const [balance, setBalance] = useState((user.profile.bonuses || 0));
    const { storedValue, setValue: setSelectOrders, hardRemove: removeOrders } = useLocalStorage('_ali_orders');
    const { setValue: setBasket } = useLocalStorage('_ali_basket');
    const [openForm, setOpenForm] = useState(null);
    const [orders, setOrders] = useState(storedValue || null);
    const [isNotEnough, setIsNotEnough] = useState(false);
    const [enough, setEnough] = useState(0);
    const [sum, setSum] = useState(0);
    const [isOrderError, setOrderError] = useState(false);
    const [products, setProducts] = useState(null);
    const [availability, setAvailability] = useState(null);
    const [fails, setFails] = useState(null);
    const [sales, setSales] = useState(null);
    useEffect(() => {
        let timer = null;
        clearTimeout(timer);
        const query = localProducts?.map(item => 'product_ids[]=' + item.id).join('&');

        const get = async () => {
            const res = await axios.get('/api/v1/products?' + query);
            setAvailability(res.data.response);
        }

        if (query) {
            timer = setInterval(async () => {
                get();
            }, 2 * 1000);
            get();
        }
        return () => clearInterval(timer);
    }, [localProducts]);

    useEffect(() => {
        return () => {
            dispatch(setOpenSuccess(null));
        };
    }, [dispatch]);


    useEffect(() => {
        setBalance((user.profile.bonuses || 0));
    }, [user]);

    useEffect(() => {
        if (orders) {
            setSelectOrders(orders);
        } else {
            removeOrders();
        }
    }, [orders, setSelectOrders, removeOrders]);

    useEffect(() => {
        setEnough(sum - balance);
        setIsNotEnough(sum > balance);
    }, [sum, user, balance]);

    useEffect(() => {
        if (orders && localProducts) {
            const sum = orders?.reduce((acc, id) => acc + localProducts.find(item => item.id === id)?.price, 0) || 0;
            setSum(sum);
        }

        const hasCorrespondence = (array, word1, word2) => array?.includes(word1) && array?.includes(word2);
        const isMixed = hasCorrespondence(localProducts?.filter(item => orders?.includes(item.id)).map(item => item.type), 'ru', 'global');

        setOrderError(isMixed)
    }, [orders, localProducts])

    const deleteOrder = useCallback((id) => {
        return () => {
            if (localProducts) {
                const filtered = localProducts.filter(item => item.id !== id);
                setBasket(filtered);
                dispatch(setProduct(filtered));
            }

            if (orders) {
                const filtered = orders.filter(item => item !== id);
                setOrders(filtered)
            }
        };
    }, [localProducts, setBasket, orders, dispatch]);

    const addProduct = useCallback((id) => {
        return () => {
            if (!orders) {
                setOrders([id]);
            } else if (orders.indexOf(id) !== -1) {
                const filtered = orders.filter(item => item !== id);
                setOrders(filtered);
            } else {
                const clone = [...orders];
                clone.push(id);
                setOrders(clone);
            }
        };
    }, [orders])

    const onOrdered = () => {
        if (isNotEnough) return;
        const products = localProducts.filter(item => orders.includes(item.id));
        const type = products.some(item => item.type === 'global') ? 'global' : products.some(item => item.type === 'ru') ? 'ru' : 'online';

        dispatch(setFormOrders(products.map(item => ({
            id: item.id,
            size_id: item.size_id,
            quantity: 1
        }))));

        setProducts(products);
        setOpenForm(type);
    };


    const orderFinish = (onlineOrOffice, failsData, saleOrders, address) => {
        const failIds = failsData.filter(item => item.quantity === 0).map(item => item.id);
        const saleIds = saleOrders.map(item => item.id);
        const fails = localProducts.filter(item => failIds.includes(item.id));
        const sales = localProducts.filter(item => saleIds.includes(item.id));
        setFails(fails.length > 0 ? fails : null);
        setSales(sales.length > 0 ? sales : null);

        const reaming = localProducts.filter(item => !orders.includes(item.id))
        const isWithOnline = !!localProducts.filter(item => orders.includes(item.id)).find(item => item.type === 'online');
        setOrders(null);

        if (reaming && reaming.length > 0) {
            dispatch(setProduct(reaming));
            setBasket(reaming);
        } else {
            dispatch(setProduct(null));
            setBasket(null);
        }
        dispatch(setFormOrders(null));
        dispatch(fetchUser(user.accessToken));

        if (typeof onlineOrOffice !== 'string') {
            dispatch(setOpenSuccess(
                isWithOnline ? {
                    name: onlineOrOffice ? 'success-office-online' : 'success-courier-online',
                    data: onlineOrOffice ? address || null : null,
                } : {
                    name: onlineOrOffice ? 'success-office' : 'success-courier',
                    data: onlineOrOffice ? address || null : null,
                }
            ));
        } else if (onlineOrOffice === 'online') {
            dispatch(setOpenSuccess({
                name: 'success-online',
                data: null,
            }));
        }
    }

    return {
        products,
        openForm,
        localProducts,
        sum,
        enough,
        balance,
        orders,
        isNotEnough,
        isLogin: user.isLogin,
        addProduct,
        deleteOrder,
        onOrdered,
        isOrderError,
        openSuccessScene,
        orderFinish,
        availability,
        fails,
        sales,
    };
};