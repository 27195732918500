import { useState, useEffect } from 'react';

const useTimer = (initialTime) => {
    const [timeLeft, setTimeLeft] = useState(initialTime);
    const [isStart, setIsStart] = useState(false);
    useEffect(()=> {
        if (timeLeft === 0) {
            setIsStart(false);
        }
    }, [timeLeft]);
    useEffect(() => {
        if (!isStart || timeLeft <= 0) return;
        const intervalId = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);
        // Cleanup interval when component unmounts or time reaches 0
        return () => clearInterval(intervalId);
    }, [timeLeft, isStart]);

    const start = () => {
        if (isStart) return;
        setIsStart(true);
        setTimeLeft(initialTime);
    }

    const stop = () => {
        setIsStart(false);
    }


    return { 
        timeLeft: timeLeft < 10 ? '0'+timeLeft : timeLeft, 
        start,
        stop,
        isStart: isStart, 
        isStop: !isStart 
    };
};

export { useTimer };
