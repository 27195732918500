import { memo } from "react"
import styled from "styled-components"
import Loader from "./Loader";

const StyledButton = styled.button`
    position: relative; 
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    text-align: center;
    color: ${({ $variant }) => (
        !$variant ? 'var(--c-black)' : 
            ['blue', 'red', 'pink'].includes($variant) ? '#fff' :
            '#000'
    )};
    background-color: ${({ $variant }) => (
        $variant === 'blue' ? '#00C0FF' :
        $variant === 'red' ? '#FF5F5B' :
        $variant === 'pink' ? '#FFA0FF' :
        'var(--c-yellow)' 
    )};
    cursor: pointer;
    padding: 22px 32px;
    border-radius: 10px;
    border: 0;
    transition: background-color .3s linear;

    @media (max-width: 480px) {
        font-size: 16px;
        line-height: 17px;
        padding: 20px;
    }

    &:disabled {
        opacity: .6;
        cursor: no-drop !important;
    }

    &:hover:not(:active):not([disabled]) {
        background-color: ${({ $variant }) => (
            !$variant ? 'var(--c-yellow-h)' :
            $variant === 'blue' ? '#0193c4' : 
            $variant === 'red' ? '#e42c28' : 
            $variant === 'pink' ? '#d87ed8' : 
            ''
        )};
    }

    .btn-loader {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 10;
        transform: translate(-50%, -50%);
        zoom: .5;
        color: ${({ $variant }) => (
            ['red', 'blue', 'pink'].includes($variant) ? '#fff' : 
            '#000'
        )};
    }
`
const Button = ({ 
    children, 
    cssClass, 
    disabled,
    onClick,
    variant,
    isLoading,
}) => {
    return (
        <StyledButton 
            onClick={onClick}
            $variant={variant} 
            className={cssClass}
            disabled={disabled}
        >
            {isLoading && <Loader cssClass="btn-loader" />}
            <span style={{ opacity: isLoading ? 0 : 1 }}>{children}</span>
        </StyledButton>
    )
}

export default memo(Button);