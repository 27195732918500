import { useEffect, useState } from "react"
import { prognoz } from "./data";

function isDateInRange(date, startDate, endDate) {
    const year = 2024;  
    const isNewYear =  +date.split('.')[0] <= 19 && date.split('.')[1] === '01';
    const isYearFinish = startDate === '22.12' && endDate === '19.01';
    const dateToCheck = new Date(`${isNewYear ? year+1 : year}-${date.split('.').reverse().join('-')}`);
    const start = new Date(`${year}-${startDate.split('.').reverse().join('-')}`);
    const end = new Date(`${isYearFinish ? year+1 : year}-${endDate.split('.').reverse().join('-')}`);
    return dateToCheck.getTime() >= start.getTime() && dateToCheck.getTime() <= end.getTime();
}

const usePrognoz = () => {
    const [dateInput, setDateInput] = useState('');
    const [current, setCurrent] = useState(null);
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    useEffect(()=> {
        const current = [...prognoz].reverse().find(item => item.time < (new Date()).getTime());
        if (current) {
            setData(current);
        }
    }, []);

    useEffect(()=> {
        if (data) {
            const date = new Date().toLocaleDateString().slice(0, 5)
            const current = data.list.find(item => isDateInRange(date, item.dateRange[0], item.dateRange[1]));
            setCurrent(current);
        }
    }, [data]);

    const onSubmit = () => {
        setIsLoading(true);
        const current = data.list.find(item => isDateInRange(dateInput, item.dateRange[0], item.dateRange[1]));
        const timeOut = setTimeout(()=> {
            clearTimeout(timeOut);
            setCurrent(current);
            setIsLoading(false);
        }, 1000);
    }

    const onChange = (e) => {
        let newValue = 0;
        const value = e.target.value;
        newValue = value;
        if (+value.split('.')[0] > 31) {
            newValue = '31.' + (value.split('.')[1] || '');
        } else if (value.split('.')[0] === '00') {
            newValue = '01.' + (value.split('.')[1] || '');
        }

        if (+value.split('.')[1] > 12) {
            newValue =  (value.split('.')[0] || '')+'.12';
        } else if (value.split('.')[1] === '00') {
            newValue =  (value.split('.')[0] || '')+'.01';
        }
        setDateInput(newValue);
    }

    return {
        dateInput,
        current,
        isLoading,
        onSubmit,
        onChange,
    }
}

export default usePrognoz;