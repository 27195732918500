import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { setOpenAuth } from "@store/features";
import Register from "./modules/Register";
import SendCode from "./modules/SendCode";
import CreatePassword from "./modules/CreatePassword";

import styles from './Forms.module.scss';

export const RegisterFrom = () => {
    const dispatch = useDispatch();
    const [stage, setStage] = useState('register');

    useEffect(()=> {
        if (stage === 'success') {
            dispatch(setOpenAuth({
                isOpenAuth: false,
                stage: null
            }));
        }
    }, [stage, dispatch]);

    const changeStage = useCallback((stage)=> {
        return ()=> {
            setStage(stage)
        }
    }, []);

    return (
        <div className={styles.Forms}>
            <div className={styles.Forms_title}>Регистрация</div>

            {stage === 'register' ?
                <Register changeStage={changeStage('code')} /> : 
                stage === 'code' ? <SendCode isRegister changeStage={changeStage('password')} /> :
                <CreatePassword changeStage={changeStage('success')} />
            }
        </div>
    );
};
