import { useEffect, useRef } from 'react';

export const useMounted  = (cb) => {
    const init = useRef(false);

    useEffect(()=> {
        if (!init.current) {
            cb()
            init.current = true;
        }
    }, [cb]);
};