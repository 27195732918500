import { getImageUrl } from '@assets';

const buttons = {
    backgrounds: (isShow, styles) => (
        <div className={[styles.background, isShow ? styles.isShow : ''].join(' ')} data-selector="background">
            <img
                src={getImageUrl('photo-booth/cam/1.png')}
                alt=""
                className={['selected', styles.is_acitve].join(' ')}
                data-photo-anchor="background-1"
            />
            <img 
                src={getImageUrl('photo-booth/cam/2.png')}
                alt=""
                data-photo-anchor="background-2"
            />
            <img 
                src={getImageUrl('photo-booth/cam/3.png')}
                alt=""
                data-photo-anchor="background-3"
            />
            <img 
                src={getImageUrl('photo-booth/cam/t-4.png')}
                alt=""
                data-photo-anchor="background-4"
            />
        </div>
    ),

    stickers: (isShow, styles) => (
        <div className={[styles.sticker, isShow ? styles.isShow : ''].join(' ')} data-selector="sticker">
            <img
                src={getImageUrl('photo-booth/cam/sticker/t-1.png')}
                data-photo-anchor="sticker-1"
                alt=""
                width={138}
                height={79}
                data-top={40}
                className={['selected', styles.is_acitve].join(' ')}
            />
            <img
                src={getImageUrl('photo-booth/cam/sticker/t-2.png')}
                data-photo-anchor="sticker-2"
                alt=""
                data-top={50}
                width={125}
                height={49}
            />
            <img
                src={getImageUrl('photo-booth/cam/sticker/t-3.png')}
                data-photo-anchor="sticker-3"
                alt=""
                data-top={0}
                width={196}
                height={176}
            />
            <img
                src={getImageUrl('photo-booth/cam/t-4.png')}
                data-photo-anchor="sticker-4"
                alt=""
            />
        </div>
    ),

    frames: (isShow, styles) => (
        <div className={[styles.frames, isShow ? styles.isShow : ''].join(' ')} data-selector="frames">
            <img
                src={getImageUrl('photo-booth/cam/frames/t-1.png')}
                data-photo-anchor="frame-1"
                alt=""
                className={['selected', styles.is_acitve].join(' ')}
            />
            <img
                src={getImageUrl('photo-booth/cam/frames/t-2.png')}
                data-photo-anchor="frame-2"
                alt=""
            />
        </div>
    ),
    preLoads: (
        <div style={{ position: 'absolute', left: '-100000px', opacity: 0, zIndex: -1000 }} data-selector="data.photo.anchor"> 
            <img
                src={getImageUrl('photo-booth/cam/1.png')}
                data-photo-anchor="background-1"
            />
            <img 
                src={getImageUrl('photo-booth/cam/2.png')}
                data-photo-anchor="background-2"
            />
            <img 
                src={getImageUrl('photo-booth/cam/3.png')}
                data-photo-anchor="background-3"
            />
            <img 
                src={getImageUrl('photo-booth/cam/transparent.png')}
                data-photo-anchor="background-4"
            />
            <img
                src={getImageUrl('photo-booth/cam/sticker/1.png')}
                data-photo-anchor="sticker-1"
                width={138}
                height={79}
                data-top={40}
            />
            <img
                src={getImageUrl('photo-booth/cam/sticker/2.png')}
                data-photo-anchor="sticker-2"
                data-top={50}
                width={125}
                height={49}
            />
            <img
                src={getImageUrl('photo-booth/cam/sticker/3.png')}
                data-photo-anchor="sticker-3"
                data-top={0}
                width={196}
                height={176}
            />
            <img
                data-photo-anchor="sticker-4"
                src={getImageUrl('photo-booth/cam/transparent.png')}
                alt=""
            />

            <img
                src={getImageUrl('photo-booth/cam/frames/1.png')}
                data-photo-anchor="frame-1"
                alt=""
            />
            <img
                src={getImageUrl('photo-booth/cam/frames/2.png')}
                data-photo-anchor="frame-2"
                alt=""
            />
        </div>
    )
}

export default buttons;