import IconScore from '../../assets/icon-score.svg'
import './game-panel.scss'

export function GamePanel({ score, round, timeleft, leftContent }) {
  const roundNumber = round + 1

  const timer = () => {
    const time = timeleft ?? 0
    const min = Math.floor(time / 60)
    const sec = time % 60
    return `${min}`.padStart(2, '0') + ':' + `${sec}`.padStart(2, '0')
  }

  return (
    <>
    <div className="game-panel">
        <div className="game-panel__left">
          <>{leftContent}</>
          {!leftContent && <div className="game-panel__round">Раунд {roundNumber}</div>}
      </div>
      <div className="game-panel__center">
          {!!leftContent && <div className="game-panel__round">Раунд {roundNumber}</div>}
        <div className="game-panel__timer">{ timer() }</div>
      </div>
      <div className="game-panel__right">
        Заработано
        <div className="game-panel__score">
            <img src={IconScore} /> { score }
        </div>
      </div>
    </div>
    </>
  )
}
