import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getApiRoute } from '@config/routes';

export const fetchUser = createAsyncThunk('user/fetchUser', async (token) => {
    const response = await axios.get(getApiRoute('user'), {
        headers: {
            Authorization: 'Bearer '+token || '',
        }
    });
    return response.data.response;
});

const initialState = {
    profile: null,
    accessToken: null, 
    isLogin: false,
    isLoading: false,
    isPromoOpen: false,
    attemptId: null,
    userEmail: null,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setIsLoading(state, action) {
            state.isLoading = action.payload.isLoading
        },
        setUserProfile(state, action) {
            state.profile = action.payload.profile
        },
        setUserToken(state, action) {
            if (action.payload.accessToken) {
                state.isLogin = true
            } else {
                state.isLogin = false
            }
            state.accessToken = action.payload.accessToken
        },
        setAttemptId(state, action) {
            state.attemptId = action.payload
        },
        setUserDestroy(state) {
            state.profile = null
            state.accessToken = null
            state.isLogin = false
            state.attemptId = null
        },
        setUserEmail(state, action) {
            state.userEmail = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.profile = action.payload;
            })
            .addCase(fetchUser.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            });
    },
})

export const {
    setUserProfile,
    setUserToken,
    setUserDestroy,
    setIsLoading,
    setAttemptId,
    setUserEmail,
} = userSlice.actions

export default userSlice.reducer