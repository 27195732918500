import { memo } from "react"

import Title from '../../common/Title';
import ScrollBar from "../ScrollBar/ScrollBar";
import './RulesPopup.scss'

import Arrow from '@assets/svg/arrow.svg?react';

import rule1 from '@assets/img/rules/1.png';
import rule2 from '@assets/img/rules/2.png';
import rule3 from '@assets/img/rules/3.png';
import rule4 from '@assets/img/rules/4.png';
import rule5 from '@assets/img/rules/5.png';
import rule6 from '@assets/img/rules/6.png';
import doc3 from '@assets/docs/promo_conditions.pdf';

const rules = [
    {
        id: 1,
        label: rule1,
        desc: 'Получи свой уникальный <br/>  промокод <span>на скидку 500 руб. <br/> от 2 тысяч в заказе</span> в разделе  <br/>  «Промокод»',
    },
    {
        id: 2,
        XMLHttpRequestlabel: rule2,
        desc: 'В период распродажи 11-11 (с 11  <br/> по 17 ноября) отправляй промокод знакомыми и близким в личных сообщениях и чатах ',
    },
    {
        id: 3,
        label: rule3,
        desc: '️<span>Обрати внимание: </span> перед распространением промокода нужно обязательно ознакомиться <br/> с инструкцией в «Условиях акции», чтобы не нарушить Закон о рекламе',
    },
    {
        id: 4,
        label: rule4,
        desc: 'Отслеживай свой результат <br/>  в рейтинге участников',
    },
    {
        id: 5,
        label: rule5,
        desc: 'Напоминай друзьям и близким совершать покупки в период распродажи и применять промокод',
    },
    {
        id: 6,
        label: rule6,
        desc: '21 ноября присоединяйся к церемонии награждения – 5 победителей получат крутые призы',
    }
]

const RulesPopup = () => {
    return (
        <div className="RulesPopup">
            <Title cssClass="RulesPopup-title">
                Правила конкурса
            </Title>

            <ScrollBar>
                <div className="RulesPopup_wrapper">
                    <div className="RulesPopup-list">
                        {rules.map(item=> (
                            <div className="RulesPopup-card" key={item.id}>
                                <div className="RulesPopup-card-label">
                                    <picture>
                                        <img src={item.label} alt="" width={310}  height={130} />
                                    </picture>
                                </div>
                                <div className="RulesPopup-card-num">0{item.id}</div>
                                <div className="RulesPopup-card-dec" dangerouslySetInnerHTML={{__html: item.desc}} />
                            </div>
                        ))}
                    </div>

                    <div className="RulesPopup-bottom">
                        <ul>
                            <li>период действия промокода – 11.11 – 17.11</li>
                            <li>
                                условия применения – скидка 500₽ при заказе от 2000₽ на AliExpress. Для получения скидки необходимо ввести промокод на странице оплаты заказа
                            </li>
                            <li>
                                промокод доступен сотрудникам, привязавшим данные от аккаунта AliExpress к личному кабинету Aero до 21.10.2024
                            </li>
                            <li>
                                максимальное количество применений каждого промокода – 100 раз (1 раз на 1 пользователя)
                            </li>
                        </ul>

                        <a href={doc3} target="_blank"  rel="noopener noreferrer" className="RulesPopup-link">
                            <span>Условия акции</span>
                            <Arrow />
                        </a>
                    </div>
                </div>
            </ScrollBar>
        </div>
    )
}

export default memo(RulesPopup);