import { Btn } from '../Btn/Btn'
import IconPower from '../../assets/icon-power.svg'
import './game-intro.scss'

export function GameIntro({ image, title, desc, features, attempts, maxAttempts, onStart }) {
  return (
    <>
    <div className="game-intro">
      <img className="game-intro__image" src={image} />
      <div className="game-intro__content">
        <div className="game-intro__title">{ title }</div>
        <div className="game-intro__desc">{ desc }</div>
        <div className="game-intro__features">
            {features.map((feature, i) => (
              <div className="game-intro__feature" key={i}>{ feature }</div>
            ))}
        </div>
        <div className="game-intro__footer">
          <div className="game-intro__attempts">
            Попытки на сегодня: <img src={IconPower} />{ attempts }/{ maxAttempts }
          </div>
          <Btn
              className="game-intro__start"
              disabled={!attempts}
              onClick={() => onStart()}
            >{!attempts ? 'Не осталось попыток' : 'Начать игру'}</Btn>
        </div>
      </div>
    </div>
    </>
  )
}
