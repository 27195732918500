const routes = {
    'auth.password': '/api/v1/auth/password',
    'auth.login': '/api/v1/auth/login',
    'auth.logout': '/api/v1/auth/logout',
    'auth.refresh': '/api/v1/auth/refresh',

    'user': '/api/v1/user',
    'products': '/api/v1/products',
    'sale': '/api/v1/sale',
    'photo': '/api/v1/photo',
    'test': '/api/v1/test',
    'leaders': '/api/v1/leaders',
    'bonuses.transfer': '/api/v1/user/bonuses/transfer',
    'results': '/api/v1/results',
}

export const getApiRoute = (endpoint) => {
    return routes[endpoint]
}