export const priceFormatter = (num, separator=" ") => {
    return String(num).replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}

export const isDisableFormik = ({ values, errors }) => {
    return !!Object.keys(values).find(k => !values[k]) || !!Object.keys(errors).find(k => !!errors[k]);
}

export const isErrorFormik = ({ touched, errors }, name) => {
    return (touched[name] && errors[name]);
}

export const onlyNum = (num) => {
    return String(num).replace(/\D/g, '');
}

function kebabToCamel(str) {
    return str.replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());
}

export function convertKeysToCamelCase(obj) {
    if (Array.isArray(obj)) {
        return obj.map(item => convertKeysToCamelCase(item));
    } else if (obj !== null && typeof obj === 'object') {
        return Object.entries(obj).reduce((acc, [key, value]) => {
        const camelKey = kebabToCamel(key);
        acc[camelKey] = convertKeysToCamelCase(value);
        return acc;
        }, {});
    }
    return obj;
}