import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Title from "@components/common/Title";
import Button from "@components/common/Button";
import { setOpenTest } from '@store/features';

import './Tests.scss';

import Coin from '@assets/svg/coin.svg?react';
import Lock from '@assets/svg/lock.svg?react';
import logo1 from '@assets/img/test/1.png';
import logo2 from '@assets/img/test/2.png';
import logo3 from '@assets/img/test/3.png';

const cards = [
    { 
        id: 1, 
        title: 'Профессиональный <br/> отзовик', 
        text: 'Сколько товаров ты сможешь опознать по отзыву? Проверь себя – внимательно читай реальные отзывы, которые мы нашли на просторах AliExpress, и за 10 секунд попытайся понять, о чём же рассказывает его автор?',
        coins: 500,
        logo: logo1,
        style: 'pink',
        available: true,
        type: 'review',
        name: 'review',
        startDate: 11,
        startAt: '2024-11-11T00:00:00',
        finishAt: '2024-11-25T00:00:00'
    },
    { 
        id: 2, 
        title: 'Very очень-очень affordable', 
        text: 'Порой понять карточки товаров на русском языке в AliExpress — та ещё задача… А что, если прогнать это странное описание через переводчик? Проверь, сможешь ли ты сопоставить cringe-описание товара с его названием на английском языке, а за его improvement-ом обращайся к нашим друзьям из SkyEng!',
        coins: 1000,
        logo: logo2,
        style: 'gray',
        available: true,
        type: 'skyeng',
        name: 'skyeng',
        startDate: 15,
        startAt: '2024-11-15T00:00:00',
        finishAt: '2024-11-25T00:00:00',
    },
    { 
        id: 3, 
        title: 'КМС по AliExpress', 
        text: 'Пройти этот тест без ошибок = сдать экзамен на знание AliExpress. Не волнуйся, мы никому не расскажем, если ты его завалишь! Зато за хороший результат отсыпем горстку Ali Coin',
        coins: 500,
        logo: logo3,
        style: 'red',
        available: true,
        type: 'aliexpress',
        name: 'aliexpress',
        startDate: 19,
        startAt: '2024-11-19T00:00:00',
        finishAt: '2024-11-25T00:00:00',
    }
]

const isAvailableRange = (s, f) => {
    const date = new Date().getTime();
    const start = new Date(s).getTime();
    const finish = new Date(f).getTime();

    return [
        date > start,
        date > finish
    ];
}

const Tests = () => {
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const onOpenTest = (type)=> ()=> {
        dispatch(setOpenTest({
            isOpenTest: true,
            testType: type,
        }));
    };

    const isTestStart = (type) => user?.profile?.test_attempts?.some(item => item.test === type);
   
    return (
        <div className="tests">
            <Title cssClass="tests-title">Тесты</Title>

            <div className="test-list">
                {cards.map(item => {
                    const [isStart, isFinish] = isAvailableRange(item.startAt, item.finishAt);

                    return (
                        <div key={item.id} className="test-card" data-style={item.style}>
                            <div className="test-card-title" dangerouslySetInnerHTML={{ __html: item.title }} />
                            <div className="test-card-text">{item.text}</div>
                            <div className="test-card-coins">
                                +{item.coins} <Coin /> ALI COINS
                            </div>
                            {item.logo &&
                                <div className="test-card-logo">
                                    <picture>
                                        <img src={item.logo} alt="" />
                                    </picture>
                                </div>
                            }
                            <div className="test-card-bottom">
                                {!isStart ?
                                    <div className="test-card-close">
                                        <Lock />
                                        Тест будет доступен с {item.startDate} ноября
                                    </div> :
                                    (isStart && isFinish) ?
                                    <div className="test-card-close">
                                        <Lock />
                                        Проведение тестов окончено
                                    </div> : 
                                    <>
                                        {!isTestStart(item.name) ? 
                                            <Button
                                                disabled={!item.available}
                                                variant={(item.style === 'gray' && 'blue' ) || (item.style)}
                                                onClick={onOpenTest(item.type)}
                                            >
                                                Пройти тест
                                            </Button> :
                                            <Button
                                                disabled
                                                variant={(item.style === 'gray' && 'blue' ) || (item.style)}
                                            >
                                            Тест пройден
                                            </Button> 
                                        }
                                    </>    
                                }
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default memo(Tests);