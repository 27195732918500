import { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import classNames from "classnames";
import axios from "axios";

import Button from '@components/common/Button';
import Input from "@components/common/Input";
import { fetchUser } from '@store/features';
import { useEmailField } from "@hooks";
import { getApiRoute } from "@config";
import { priceFormatter, onlyNum } from "@utils";

import Coin from '@assets/svg/coin.svg?react';
import styles from '../Forms.module.scss';

const validate = (bonuses) => (values) => {
    const errors = {};
    if (!values.email) {
        errors.email = 'Это поле обязательно к заполнению';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Неверный адрес электронной почты';
    } 

    if (!values.bonuses) {
        errors.coins = 'Это поле обязательно к заполнению';
    } else if (bonuses <  Number(onlyNum(values.bonuses))) {
        errors.bonuses = 'У тебя не хватает Ali Coin';
    } 
    return errors;
}

const SendEmail = ({ changeStage }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const [isLoading, setIsLoading] = useState(false);

    const formik = useFormik({
        initialValues: { email: '', bonuses: '', },
        validate: validate(user.profile.bonuses),
        async onSubmit(values) {
            setIsLoading(true);
            try {
                await axios.post(getApiRoute('bonuses.transfer'), {
                    email: values.email,
                    bonuses: +values.bonuses.replace(/\D+/g, ""),
                }, {
                    headers: {
                        Authorization: 'Bearer '+user.accessToken || '',
                    }
                });
                dispatch(fetchUser(user.accessToken));
                changeStage();
            } catch(err) {
                const error = err.response.data.message;
                formik.setFieldError('email', error)
                formik.setFieldError('password', error)
            } finally {
                setIsLoading(false);
            }
        }
    });

    const [emailMask, changeEmail, fucusEmail] = useEmailField((value)=> {
        formik.setFieldValue('email', value)
    });

    const onChange = (e) => {
        const newValue = onlyNum(e.target.value);
        if ((!isNaN(Number(newValue)) || newValue === '')) {
            formik.setFieldValue('bonuses', priceFormatter(newValue));
        }
    };

    const [
        emailError,  
        emailValue,
        bonusesError,
        bonusesValue
    ] = [
        !formik.isValid && formik.errors.email,
        formik.values.email,
        !formik.isValid && formik.errors.bonuses,
        formik.values.bonuses,
    ];
    
    return (
        <>
            <div className={styles.Forms_coin}>
                У тебя: <Coin /> <span>{priceFormatter(user.profile.bonuses || 0)}</span>
            </div>
            <form
                onSubmit={formik.handleSubmit}
                className={styles.Forms_form}
            >
                <label className={classNames(styles.Forms_field, emailError && styles.is_error)}>
                    <span className={styles.Forms_label}>Укажи почту коллеги</span>
                    <Input
                        name="email"
                        placeholder="______ @aliexpress.ru"
                        value={emailMask}
                        onChange={changeEmail}
                        onFocus={fucusEmail}
                    />
                    {emailError && 
                        <span className={styles.Forms_error}>{emailError}</span>
                    }
                </label>

                <label className={classNames(styles.Forms_field, bonusesError && styles.is_error)}>
                    <span className={styles.Forms_label}>Введи сумму</span>
                    <Input
                        name="bonuses"
                        placeholder="Введи сумму"
                        value={bonusesValue}
                        onChange={onChange}
                    />
                    {bonusesError && 
                        <span className={styles.Forms_error}>{bonusesError}</span>
                    }
                </label>

                <Button 
                    cssClass={styles.Forms_submit}
                    variant="red"
                    isLoading={isLoading}
                    disabled={
                        (emailError || !emailValue?.length || bonusesError || !bonusesValue.length) 
                        || isLoading
                    }
                >
                    Отправить
                </Button>
            </form>
        </>
    );
};

export default memo(SendEmail);