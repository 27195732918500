import { useCallback, useEffect, useState } from "react"
import { useTimer } from "@hooks";
import { useTestFinish } from "./useTestFinish";

export const useTesting = ({
    data,
    type,
    onFinish
}) => {
    const { start, stop, timeLeft, isStop } = useTimer(30);
    const [isLoading, setIsLoading] = useState(false);
    const [currentQuestion, setCuerrntQuestion] = useState(null);
    const [isComplete, setIsComplete] = useState();
    const [total, setTotal] = useState(null);
    const [current, setCurrent] = useState(1);
    const [selectAnswer, setSelectAnswer] = useState(null);
    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [assembly, setAsembly] = useState({
        questions: [],
        answers: [],
    });

    const { postTestFinish } = useTestFinish({
        idx: type,
        assembly,
        onSuccess() {
            onFinish && onFinish(correctAnswers, total);
            const timeOut = setTimeout(() => {
                clearTimeout(timeOut);
                setIsLoading(false);
            }, 300);
        } 
    });

    const sendTestResult = useCallback(async ()=> {
        setIsLoading(true);
        postTestFinish();
    }, [postTestFinish]);

    useEffect(()=> {
        start();
        return ()=> stop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=> {
        setCuerrntQuestion(data[current-1])
    }, [data, current]);

    useEffect(()=> {
        let timeout = null;
        if (current === total) {
            timeout = setTimeout(()=> {
                clearTimeout(timeout);
                setIsComplete(true);
            }, 300);
        }
        return () => clearTimeout(timeout);
    }, [current, total]);

    useEffect(()=> {
        setTotal(data.length);
        setAsembly(state => ({
            ...state,
            questions: data.map(item => item.number)
        }))
    }, [data]);
    
    useEffect(() => {
        if (selectAnswer === null && timeLeft === '00') {
            setAsembly(state => ({
                ...state,
                answers: [...state.answers, 0]
            }));
        }
    }, [selectAnswer, timeLeft])

    useEffect(() => {
        if ((current === total) && selectAnswer === null && timeLeft === '00') {
            sendTestResult();
        }
    }, [current, total, selectAnswer, timeLeft, sendTestResult])

    useEffect(() => {
        if (assembly.answers?.length === assembly.questions?.length && isComplete) {
            sendTestResult();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assembly, isComplete]);

    const onSelect = (item) => () => {
        if (item?.isCorrect) {
            setCorrectAnswers(correctAnswers + 1);
        }
         setAsembly(state => ({
            ...state,
            answers: [...state.answers, item.id]
        }));
        if (selectAnswer || (isStop && !selectAnswer)) return;
        setSelectAnswer(item);
        stop();
    };

    const nextStage = () => {
        if (isComplete) return;
        setSelectAnswer(null);
        setCurrent(current+1);
        start();
    };

    return {
        isLoading,
        currentQuestion,
        nextStage,
        assembly,
        onSelect,
        timeLeft,
        current,
        total,
        selectAnswer,
        isStop,
        isComplete,
    }
};