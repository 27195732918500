import { useState } from 'react';
import Column from '@components/common/Column';

import Checked from '@assets/svg/checked.svg?react';
import './PromocodePopup.scss'
import classNames from 'classnames';

const fragmet = (code) => `Смотри, что у меня есть! Делюсь своим уникальным промокодом на скидку 500 руб. при заказе от 2 тысяч на AliExpress.
Промокод активен только с 11 по 18 ноября, — успевай сделать свой заказ ещё более выгодным 😉

Вводи мой промокод ALIFRIENDS${code} при оплате заказа, воспользоваться можно только 1 раз!  👉 https://aliexpress.ru`;

const PromocodePopup = ({ promocode }) => {
    const [copied, setCopied] = useState(false);
    const copyToClipboard = async () => {
        if (copied) return;
        try {
            await navigator.clipboard.writeText(
                fragmet(promocode)
            );
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    return (
        <>
            <Column align='flex-start' className='promocode__top'>
                <span className='promocode__title'>Приводи друзей -<br/>получай подарки!</span>
                <span className='promocode__article'>
                    Хочешь отправиться в путешествие или получить новый<br/>
                    смартфон? Приводи друзей на AliExpress! С 11 по 17 ноября<br/>
                    делись своим уникальным промокодом со всеми, –<br/>
                    чем больше применений, тем выше шанс выиграть один<br/>
                    из пяти главных призов!
                </span>
            </Column>
            
            <Column align='flex-start' className='promocode__bottom'>
                <span className='promocode__yours'>
                    Твой промокод - <br/> ALIFRIENDS{promocode}
                </span>
                <button
                    className={classNames('promocode__share', copied && 'is_copy')}
                    onClick={copyToClipboard}
                >
                    {copied ? 
                        <><Checked /> Промокод скопирован</> : 
                        'Отправить промокод'
                    }
                </button>
            </Column>
        </>
    );
}

export default PromocodePopup
