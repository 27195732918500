import classNames from "classnames";

import Title from "../common/Title";
import Text from "../common/Text";
import Button from "../common/Button";
import { useTestStart } from "./useTestStart";

import './TestBanner.scss';

export const ThirdBanner = ({ onOpen }) => {
    const { isLoading, getTestStart } = useTestStart({
        idx: 'aliexpress',
        onSuccess() {
            onOpen();
        }
    });

    const cn = classNames('TestBanner', 'TestBanner_third');
    return (
        <div className={cn}>
            <div className="TestBanner-content">
                <Title cssClass="TestBanner-title">
                    КМС по AliExpress
                </Title>
                <Text cssClass="TestBanner-text">
                    Пройти этот тест без ошибок = сдать экзамен на знание AliExpress. Не волнуйся, мы никому не расскажем, если ты его завалишь! Зато за хороший результат отсыпем горстку Ali Coin
                </Text>
                <Button isLoading={isLoading} cssClass="TestBanner-btn" onClick={getTestStart}>
                    Начать!
                </Button>
            </div>
        </div>
    );
};