import { memo, useEffect, useState } from 'react';

import styles from './PhotoBoothPopup.module.scss';
import PhotoCam from './PhotoCam';
import Result from './Result';

const PhotoBoothPopup = () => {
    const [result, setResult] = useState(null);
    const [isResult, setIsResult] = useState(null);

    useEffect(() => {
        let timeOut = null;
        if (result) {
            timeOut = setTimeout(() => {
                setIsResult(true);
            }, 1000);
        }
        return () => clearTimeout(timeOut);
    }, [result]);

    const onReset = () => {
        setIsResult(false);
        setResult(null);
    };

    return (
        <div className={styles.block}>
            {!isResult ? <PhotoCam  result={result} setResult={setResult} /> :
                <Result result={result} onReset={onReset} />
            }
        </div>
    );
};

export default memo(PhotoBoothPopup);
