import { GameContainer, GameIntro, GamePanel, GameResult } from '../components'
import { GameAliWord } from '../components/GameAliWord/GameAliWord'
import IconDiagonal from '../assets/icon-diagonal.svg'
import IconCorner from '../assets/icon-corner.svg'
import WordfindCover from '../assets/wordfind-cover.svg'
import { useGame } from '../hooks/useGame'
import { pluralize } from '../utils/pluralize'
import { useState } from 'react'

export function AliWord({
  state = { attempts: 0 },
  onClose = () => {},
  onFinish = () => { },
  onStart = () => { }
}) {

  const WORDS_COUNT = 6
  const SHOW_WORDS_ON_TIMELEFT = 30

  const [found, setFound] = useState(0)

  const game = useGame({
    rounds: 3,
    lives: 3,
    attempts: 1,
    durationRound: [60, 60, 60],
    scoreRound: [50, 60, 90],
    onStart: onStart,
    onGameover: onFinish,
    onRoundOver: () => {
      setFound(0)
    }
  }, state)

  const onFind = () => {
    setFound(found + 1)
    game.score()
  }

  const close = () => {
    game.gameover(true)
    onClose()
  }

  return (
    <>
      <GameContainer
        onClose={close}
        onInitClose={() => !game.state.attempts ? close() : game.pause()}
        onCancelClose={() => game.resume()}
        lightCloseBtn={game.status === 'game'}
        smallCloseBtn={game.status === 'game'}
      >
        {game.status === 'intro' && (
          <GameIntro
            title="Найди Али-слово"
            desc="Мы создали для тебя целое собрание распродажных филвордов! Твоя задача – найти слова, связанные с AliExpress, за ограниченное время. Чем больше слов найдёшь, тем больше Ali Coin заработаешь"
            image={WordfindCover}
            features={[
              pluralize(game.config.rounds, ['раунд', 'раунда', 'раундов'], true),
              `${pluralize(Math.floor(game.config.durationRound[0]/60), ['минута', 'минуты', 'минут'], true)} на раунд`,
              `${pluralize(game.config.attempts, ['попытка', 'попытки', 'попыток'])} в день`]}
            maxAttempts={game.config.attempts}
            attempts={game.state.attempts}
            onStart={() => game.start()}
          />
        )}
        {game.status === 'game' && (
          <>
            <GameAliWord
              wordsCount={WORDS_COUNT}
              showWords={game.state.timeleft <= SHOW_WORDS_ON_TIMELEFT}
              index={game.state.round}
              onFind={onFind}
              onFinish={() => game.gameover()}
            />
            <GamePanel
              score={game.state.score}
              timeleft={game.state.timeleft}
              round={game.state.round}
              leftContent={
                <>
                  Найдено слов:<br />
                  <div className="game-words">
                    {`${found}/${WORDS_COUNT}`}
                  </div>
                </>
              }
            />
          </>
        )}
        {game.status === 'result' && (
          <GameResult
            final={game.isFinished}
            hideAttempts={!game.isFinished}
            title={game.isFinished ? 'Игра окончена!' : 'Ты отлично справляешься!'}
            desc={game.isFinished
              ? undefined
              : game.state.round === 0
                ? (
                  <div className="ali-word-desc">
                    Готов к следующему раунду?<br />Теперь мы спрятали слова<br />ещё и по диагонали
                    <img src={IconDiagonal} />
                  </div>
                )
                : game.state.round === 1
                  ? (
                    <div className="ali-word-desc">
                      Готов к следующему раунду?<br />В этот раз мы добавили слова<br />под углом 90°
                      <img src={IconCorner} />
                    </div>
                  )
                  : ''
            }
            score={game.state.score}
            image={WordfindCover}
            maxAttempts={game.config.attempts}
            attempts={game.state.attempts}
            btnText={game.isFinished ? 'Играть повторно' : 'Отлично'}
            onStart={() => game.start()}
            onClose={onClose}
          />
        )}
      </GameContainer>
    </>
  )
}
