import { useState } from 'react';

const data = [
    { id: 5, fio: 'Татьяна Киселева', position: '41', GMV: '144 663 руб', },
    { id: 4, fio: 'Анастасия Карпушкина', position: '48', GMV: '169 540.86 руб', },
    { id: 3, fio: 'Софья Акулова', position: '78', GMV: '268 586.65 руб', },
    { id: 2, fio: 'Маргарита Зозуля', position: '100', GMV: '334 108.29 руб', },
    { id: 1, fio: 'Клим Богданов', position: '100', GMV: '346 866.16 руб', }
]

const useLeaders = () => {
    const [toplist] = useState(data);
    return {
        toplist,
    }
}

export default useLeaders;