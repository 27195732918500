import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getApiRoute } from '@config/routes';

export const getProducts = createAsyncThunk('products/getProducts', async () => {
    const response = await axios.get(getApiRoute('products'));
    return response.data.response;
});

const initialState = {
    data: null,
    isLoading: false,
    error: null,
    localProducts: null,
    orders: null,
    openSuccessScene: null,
};

export const roductsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setProduct(state, action) {
            state.localProducts = action.payload;
        },
        setDestroyProduct(state) {
            state.localProducts = null;
        },
        setFormOrders(state, action) {
            state.orders = action.payload
        },
        setOpenSuccess(state, action) {
            state.openSuccessScene = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProducts.pending, (state) => {
                state.loading = true;
            })
            .addCase(getProducts.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getProducts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { setProduct, setDestroyProduct, setFormOrders, setOpenSuccess } = roductsSlice.actions;

export default roductsSlice.reducer;