import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import classNames from 'classnames';

import Title from '@components/common/Title';
import ProductCard from '@components/ProductCard';
import { useMounted, useScreenSize } from '@hooks';
import { getProducts } from '@store/features';

import Prev from '@assets/svg/slide-prev.svg?react';
import Next from '@assets/svg/slide-next.svg?react';

import styles from './Merch.module.scss';
import 'swiper/css';
import 'swiper/css/pagination';

const tabs = [
    { id: 1, label: 'Россия', value: 'ru' },
    { id: 2, label: 'Узбекистан', value: 'global', },
    { id: 3, label: 'Онлайн', value: 'online' },
];

const Merch = () => {
    const { data } = useSelector(state => state.products);
    const [filtered, setFiltered] = useState(null);
    const dispatch = useDispatch();
    const [swiper, setSwiper] = useState(null);
    const [selected, setSelected] = useState(1);
    const { isLaptop, isTablet, isMobile2 } = useScreenSize();
    const [hideBtn, setHideBnt] = useState(null); 
    useMounted(()=> {
        dispatch(getProducts());
    })

    useEffect(()=> {
        const option = tabs.find(item => item.id === selected);
        data && setFiltered(
            option.value === 'ru' ? data.filter(item => item.type !== 'online')
                : data.filter(item => item.type === option.value)
        );
    }, [data, selected]);

    const selectType = useCallback((id)=> {
        return () => {
            setSelected(id);
        }
    }, []);

    const onPrev = () => swiper?.slidePrev();

    const onNext = () => swiper?.slideNext();

    const setDisableButton = (swiper) => {
        if (isMobile2) return setHideBnt(null);
        if (swiper.activeIndex === 0) {
            setHideBnt('prev');
        } else if (swiper.isEnd) {
            setHideBnt('next');
        } else {
            setHideBnt(null);
        };
    };
    
    const sliderConfig = {
        modules: [Pagination],
        pagination: { clickable: true },
        spaceBetween: 10,
        slidesPerView: 2,
        onSlidesUpdated(swiper) {
            swiper.slideTo(0)
            setDisableButton(swiper);
        },
        onRealIndexChange(swiper) {
            setDisableButton(swiper);
        },
        onSwiper: swiper => {
            setDisableButton(swiper);
            setSwiper(swiper)
        },
        breakpoints: {
            700: {
                slidesPerView: 3,
                spaceBetween: 20
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 10
            },
            1280: {
                slidesPerView: 5,
                spaceBetween: 10
            }
        }
    }

    const isHideNav = isLaptop ? (filtered?.length < 4) : isTablet ?  (filtered?.length < 3) : isMobile2 ? (filtered?.length < 2) : (filtered?.length < 5);

    const products = filtered;
    const option = tabs.find(item => item.id === selected);
    
    return (
        <div className={styles.block}>
            <Title cssClass={styles.title}>
                Мерч
                <ul className={styles.prompt}>
                    <li>
                        Принимаем заказы с 25 по 29 ноября
                    </li>
                    <li>
                        Заказать можно до 5 товаров
                    </li>
                </ul>
            </Title>

            <ul className={styles.tabs}>
                {tabs.map(item => (
                    <li key={item.id}>
                        <button 
                            onClick={selectType(item.id)}
                            className={classNames(item.id === selected && styles.is_active )}
                        >
                            {item.label}
                        </button>
                    </li>
                ))}
            </ul>

            <div className={styles.products}>
                <Swiper {...sliderConfig}>
                    {products?.map(item => (
                        <SwiperSlide key={item?.id}>
                            <ProductCard 
                                data={item} 
                                currentType={option.value}
                                cssClass={styles?.card}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
                {(!isHideNav && hideBtn !== 'prev') && <button className={styles.prev} onClick={onPrev}><Prev /></button>}
                {(!isHideNav && hideBtn !== 'next') && <button className={styles.next} onClick={onNext}><Next /></button>}
            </div>
        </div>
    );
};

export default memo(Merch);