import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import axios from 'axios';

import Input from '@components/common/Input';
import Radio from '@components/common/Radio';
import Button from '@components/common/Button';
import { priceFormatter } from '@utils';
import { getApiRoute } from '@config';

import Coin from '@assets/svg/coin.svg?react';
import styles from '../Forms.module.scss';

const BasketUz = ({ sum, orderFinish }) => {
    const { accessToken, profile } = useSelector(state => state.user);
    const { orders } = useSelector(state => state.products);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const formik = useFormik({
        initialValues: {
            fullName: profile.name + ' ' + profile.surname,
            email: profile.email,
            fio: '',
            phone: '',
            street: '',
            house: '',
            apartment: '',
            postcode: '',
            office_address: 'Ташкент, ул. Инжобод, 32 Б, 2 этаж (коворкинг Ziyookline)',
        },
        async onSubmit(values) {
            const { fullName, ...rest } = values;

            const newData = {
                ...rest,
                fio: fullName,
                products: orders,
            }
            setError(null);
            setIsLoading(true);
            try {
                const res = await axios.post(getApiRoute('sale'), newData, {
                    headers: {
                        Authorization: 'Bearer ' + accessToken
                    }
                });
                orderFinish(
                    true,
                    res.data.response.products,
                    orders,
                    newData.office_address,
                );
            } catch (err) {
                setError(err.response.data.message);
            } finally {
                setIsLoading(false);
            }
        }
    });

    const { values } = formik;

    return (
        <form className={styles.Forms_form} onSubmit={formik.handleSubmit}>
            <div className={styles.Forms_row}>
                <label className={styles.Forms_field}>
                    <Input
                        name="fullName"
                        placeholder="Имя"
                        value={values.fullName}
                        readOnly
                        disabled
                    />
                </label>
                <label className={styles.Forms_field}>
                    <Input
                        name="email"
                        placeholder="E-mail"
                        readOnly
                        value={values.email}
                        disabled
                    />
                </label>
            </div>

            <div className={styles.Forms_radios}>
                <Radio checked={true}>
                    Забрать заказ из офиса Aliexpress
                </Radio>
            </div>

            <div className={styles.Forms_address}>
                <h5>Адрес офиса</h5>
                <div className={styles.content}>
                    <header>
                        Ташкент, ул. Инжобод, 32б 2 этаж (коворкинг Ziyookline)
                    </header>
                </div>
            </div>

            <div className={styles.Forms_order_submit}>
                <div className={styles.Forms_error} style={{ marginBottom: 14 }}>{error}</div>

                <Button
                    variant="red"
                    isLoading={isLoading}
                >
                    <Coin />
                    <i>{priceFormatter(sum)}</i>
                    Оформить заказ
                </Button>
            </div>
        </form>
    )
}
export default memo(BasketUz);