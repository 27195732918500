import { memo } from "react";
import { useSelector } from "react-redux";

import { LoginForm, RegisterFrom, ResetPass } from "../../Forms";
import './AuthPopup.scss';

const AuthPopup = () => {
    const modals = useSelector(state => state.modals);
    
    return (
        <div className="AuthPopup">
            {modals.stage === 'register' ? <RegisterFrom /> : 
                modals.stage === 'login' ? <LoginForm /> : 
                modals.stage === 'password' ? <ResetPass /> : null
            }
        </div>
    );
};

export default memo(AuthPopup);