import { useEffect, useRef } from 'react';

export const useDomObserver = ({
    isLogin,
    showModal,
}) => {
    const popupRef = useRef(null);
    useEffect(()=> {
        if (import.meta.env.PROD && isLogin) {
            document.addEventListener('contextmenu', event => event.preventDefault());
        }

        const observer = new MutationObserver(() => {
            if (popupRef.current) {
                const modal = document.querySelector('.'+popupRef.current.className.replaceAll(' ', '.'));
                if ((!modal && !isLogin) || ((modal && modal.children.length === 0) && !isLogin)) {
                    window.location.href = '/';
                }
            }
        });
    
        observer.observe(document.body, { childList: true, subtree: true });
        
        const observeStyles = new MutationObserver(()=> {        
            popupRef.current.style.position = 'fixed';
            popupRef.current.style.top = 0;
            popupRef.current.style.left = 0;
            popupRef.current.style.right = 0;
            popupRef.current.style.bottom = 0;
            popupRef.current.style.width = '100%';
            popupRef.current.style.height = '100%';
            popupRef.current.style.zIndex = 2000;
            popupRef.current.style.backgroundColor = 'color(srgb 0 0 0 / 0.5)';
            popupRef.current.style.backdropFilter = 'blur(15px)';
            popupRef.current.style.display = 'flex';
            popupRef.current.style.visibility = 'visible';
            popupRef.current.style.opacity = 1;
            popupRef.current.style.transtorm = 'translate(0, 0) scale(1)';
            popupRef.current.style.filter = 'opacity(1)';
            popupRef.current.style.clipPath = 'inset(0)';
        });
    
        if (popupRef.current) {
            observeStyles.observe(popupRef.current, { attributes: true });
        }
    
        return () => {
            observer.disconnect();
            observeStyles.disconnect();
            document.removeEventListener('contextmenu', event => event.preventDefault());
        }
    }, [isLogin, showModal]);

    return {
        popupRef,
    }
}