import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocalStorage } from "@hooks";

import MyBalance from '@components/MyBalance';
// import Promocode from '@components/Promocode';
import Basket from '@components/Basket';
import Profile from '@components/Profile';
import Row from '@components/common/Row';
import Popup from '@components/Popup/Popup';
import RegistrationPopup from '@components/Popup/RegistrationPopup/RegistrationPopup';
import PromocodePopup from '@components/Popup/PromocodePopup/PromocodePopup';

import Logo from '@assets/img/logo.svg';

import './Header.scss';

const isFinish = () => {
    const current = new Date().getTime();
    const start = new Date('2024-11-19T00:00:00').getTime();
    return current >= start;
}

const ForAuth = () => {
    const { storedValue: openPromo, setValue } = useLocalStorage('_is_open_promo');
    const [isOpenPromo, setIsOpenPromo] = useState(false);
    const { user, modals } = useSelector(state => state);    

    useEffect(() => {
        let timeout = setTimeout(() => {
            if (!openPromo && user.isLogin && !modals.isOpenAuth && user.profile.promocode) {
                setIsOpenPromo(true);
                setValue(true);
            }
        }, 3000);
        return () => clearTimeout(timeout);
    }, [user, modals, openPromo, setValue]);
    
    return (
        <Row className='header'>
            <Row className='header__left' justyfy='flex-start'>
                <MyBalance />
                <Basket />
            </Row>
            <img alt='logo' className='header__logo' src={Logo} />
            <Row className='header__right' justyfy='flex-end'>
                {/* {(!isFinish() && user.profile?.promocode) &&
                    <Promocode />
                }   */}
                <Profile />
            </Row>
            
            {!isFinish() &&
                <Popup
                    isOpen={isOpenPromo}
                    content={<PromocodePopup promocode={user?.profile?.promocode} />}
                    classes='promocode'
                />
            }
        </Row>
    );
};

const Header = () => {
    const { user } = useSelector(state => state);
    
    if (user.isLogin) {
        return <ForAuth />
    }

    return (
        <Row className='header'>
            <Row className='header__left' justyfy='flex-start'>
            </Row>
            <img alt='logo' className='header__logo' src={Logo} />
            <Row className='header__right' justyfy='flex-end'>
                <Popup content={<RegistrationPopup />} classes='registration' />
            </Row>
        </Row>
    )
}

export default Header
