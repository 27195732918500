import { memo } from 'react';

import Avatar from '@assets/svg/review-ava.svg?react';
import Like from '@assets/svg/review-like.svg?react';
import Star from '@assets/svg/review-star.svg?react';

const Review = ({ text, date }) => {
    return (
        <div className="Test-Review">
            <div className="Test-Review-user">
                <Avatar />
                <div>
                    <strong>Анонимный пользователь</strong>
                    <span>{date}</span>
                </div>
            </div>
            <div className="Test-Review-stat">
                <Star />
                <span>WLZ00083-S | 6</span>
            </div>
            <div className="Test-Review-ques">{text}</div>
            <Like className="Test-Review-like" />
        </div>
    )
};

export default memo(Review);