import { memo } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import Title from '@components/common/Title';
import Button from '@components/common/Button';
import { setOpenBasket } from '@store/features';

import styles from './BasketPopup.module.scss';

const Success = ({
    name,
    data,
    fails,
    sales,
}) => {
    const dispatch = useDispatch();

    const closeBasket = ()=> {
        dispatch(setOpenBasket({ isOpenBasket: false }));
    }

    return (
        <div className={styles.BasketPopup}>
            <div className={classNames(styles.column, styles.is_success)}>
                <div className={classNames(styles.head, styles.is_success)}>
                    <Title cssClass={styles.title}>
                        Статус заказа
                    </Title>
                </div>

                <div className={styles.result}>
                    {
                        name === 'success-office' ? 
                        <>
                            <div className={styles.descr}>
                                Спасибо за заказ! Ниже ты можешь ознакомиться с деталями о товарах. Успешно оформленные товары будут ждать тебя с 16 декабря в офисе с 15:00 до 17:00
                            </div> 
                            {data && 
                                <div className={styles.information}>
                                    {data}
                                </div>
                            }
                        </>
                        
                        : name === 'success-office-online' ?
                        <>
                            <div className={styles.descr}>
                                Спасибо за заказ! Ниже ты можешь ознакомиться с деталями о товарах. Успешно оформленные товары будут ждать тебя с 16 декабря в офисе с 15:00 до 17:00
                            </div> 
                            {data && 
                                <div className={styles.information}>
                                    {data}
                                </div>
                            }
                            <div className={styles.descr}>
                            А все детали об онлайн-товарах мы пришлём на твою корпоративную почту совсем скоро
                            </div> 
                        </> : 
                        name === 'success-courier' ?
                        <div className={styles.descr}>
                            Спасибо за заказ! Ниже ты можешь ознакомиться с деталями оформления. Всю информацию о доставке успешно оформленных товаров пришлём позднее на твою корпоративную почту
                        </div>  : 
                        name === 'success-courier-online' ?
                        <div className={styles.descr}>
                            Спасибо за заказ! Ниже ты можешь ознакомиться с деталями об оформлении товаров. Успешно оформленные онлайн-товары пришлём на твою корпоративную почту в течение 2 недель
                        </div> : 
                        name === 'success-online' ?
                        <div className={styles.descr}>
                            Спасибо за заказ! Ниже ты можешь ознакомиться с деталями об оформлении товаров. Успешно оформленные онлайн-товары пришлём на твою корпоративную почту в течение 2 недель
                        </div>  : null
                    }
                </div>
                
                <div className={styles.flex}>
                    {sales &&
                        <div className={styles.sales}>
                            <span>Эти товары уже в сборке:</span>
                            <ol>
                                {sales.map((item) => 
                                    <li key={item.id}>
                                        <img src={item.main_image} alt="" />
                                        {item.name}
                                    </li>
                                )}
                            </ol>
                        </div>
                    }
                    {fails &&
                        <div className={styles.fails}>
                            <span>Эти товары уже раскупили:</span>
                            <ol>
                                {fails.map((item) => 
                                    <li key={item.id}>
                                        <img src={item.main_image} alt="" />
                                        {item.name}
                                    </li>
                                )}
                            </ol>
                        </div>
                    }
                </div>

                <div className={styles.submit}>
                    <Button 
                        variant="red" 
                        cssClass={styles.okey}
                        onClick={closeBasket}
                    >
                        Отлично!
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default memo(Success);