import { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';


import Button from '@components/common/Button';
import { setOpenSharedCoin } from '@store/features';
import { useTogglePopup } from '@hooks';
import { priceFormatter } from '@utils';

import Btn from '@assets/svg/balance/desktop.svg?react';
import BtnTablet from '@assets/svg/balance/tablet.svg?react';
import Coin from '@assets/svg/coin.svg?react';
import styles from './MyBalance.module.scss';

const MyBalance = ({ cssClass }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const { toggle, onEnter, onLeave, onToggle } = useTogglePopup(user.isLogin);

    const openSharedCoin = ()=> {
        dispatch(setOpenSharedCoin({ isOpenSharedCoin: true }))
    }

    return (
        <div
            className={styles.wrapper}
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
        >
            <button 
                className={classNames(styles.btn, toggle && styles.is_active, cssClass)} 
                onClick={onToggle}
            >
                <Btn className={classNames(styles.btn_bg, styles.ds)} />
                <BtnTablet className={classNames(styles.btn_bg, styles.tb)} />
                <span className={styles.btn_inner}>
                    <Coin />
                    <span>МОЙ БАЛАНС</span>
                </span>
            </button>

            {toggle &&
                <div className={styles.data}>
                    <strong className={styles.data_title}>Мой баланс</strong>
                    <div className={styles.data_coins}>
                        {priceFormatter(user.profile.bonuses || 0)}
                        <Coin />
                        <span>ALI COINS</span>
                    </div>
                    {user.profile.bonuses > 0 &&
                        <Button onClick={openSharedCoin}>Поделиться коинами</Button>
                    }
                </div>
            }
        </div>
    );
};

export default memo(MyBalance);