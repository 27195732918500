import { memo, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import classNames from 'classnames';

import useLeaders from './useLeaders';
import Title from '@components/common/Title';
import Button from '@components/common/Button';
import RulesAndPrizes from '@components/RulesAndPrizes/RulesAndPrizes';

import { useScreenSize } from '@hooks';

import background from '@assets/img/friend/bg.png'
import background2 from '@assets/img/friend/bg-t.png'
import background3 from '@assets/img/friend/bg-m.png'
import Hint from '@assets/svg/hint.svg?react';
import HintLeader from '@assets/svg/hints/leader.svg?react';
import Prev from '@assets/svg/slide-prev.svg?react';
import Next from '@assets/svg/slide-next.svg?react';
import styles from './BringAFriend.module.scss';
import 'swiper/css';

const BringAFriend = () => {
    const [swiper, setSwiper] = useState(null);
    const { toplist } = useLeaders();
    const { isTablet } = useScreenSize();
    const onPrev = () => swiper?.slidePrev();
    const onNext = () => swiper?.slideNext();
    const data = toplist?.length > 0 ? [...toplist].reverse() : toplist;

    const content = (
        <>
            <div className={styles.top}>
                      
                <a
                    href="https://docs.google.com/spreadsheets/d/1gIt570C98FMNxbdvezV55UDLAmW3LGPogCvpLAdq5X8/edit?usp=sharing"
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <Button cssClass={styles.button}>
                        Смотреть таблицу участников
                    </Button> 
                </a>    
                <div className={styles.hint}>
                    <Hint />
					<HintLeader className={styles.prompt} />
				</div>
            </div>

            <div className={styles.winners}>
                <div className={styles.winners_bg}>
                    <picture>
                        <source media="(max-width: 480px)" srcSet={background3} />   
                        <source media="(max-width: 1024px)" srcSet={background2} />   
                        <img src={background} alt="" />
                    </picture>
                </div>

                {isTablet ?
                    <div className={styles.slider}>
                        <Swiper
                            pagination={{ clickable: true }}
                            className={styles.swiper}
                            spaceBetween={50}
                            slidesPerView={1}
                            onSwiper={swiper => setSwiper(swiper)}
                        >
                            {data?.map((item) => (
                                <SwiperSlide key={item?.id} className={styles.swiper_slide}>
                                    <div className={classNames(styles.winners_card)}>
                                        <strong>{item?.fio}</strong>
                                        <span>Созданные заказы: <i>{item?.position}</i></span>
                                        <span>GMV: <i>{item?.GMV}</i></span>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        
                        <div className={styles.nav}>
                            <button onClick={onPrev}>
                                <Prev />        
                            </button>
                            <button onClick={onNext}>
                                <Next />        
                            </button>
                        </div>
                    </div>
                        :
                    <div className={styles.winners_list}>
                        {toplist?.map((item, i)=> (
                            <div className={classNames(styles.winners_card, styles['_'+i])} key={item?.id}>
                                <strong>{item?.fio}</strong>
                                <span>Созданные заказы: <i>{item?.position}</i></span>
                                <span>GMV: <i>{item?.GMV}</i></span>
                            </div>
                        ))}
                    </div>
                }
            </div>    
        </>
    );

    return (
        <div className={styles.block}>
            <Title cssClass={styles.title}>Гонка промокодов</Title>
            {content}
            <RulesAndPrizes />
        </div>
    )
}

export default memo(BringAFriend);
