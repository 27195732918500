import { memo } from "react";
import { useDispatch } from "react-redux";

import Button from "@components/common/Button";
import Arrow from '@assets/svg/arrow.svg?react';
import { setCompleteTest, setOpenTest, setTestStart } from "@store/features";
import './TestPopup.scss'


const CompleteTest = () => {
    const dispatch = useDispatch();

    const closeWarn = () => {
        dispatch(setCompleteTest({ isCompleteTest: false }));
    }

    const closeTest = () => {
        dispatch(setCompleteTest({ isCompleteTest: false }));
        dispatch(setOpenTest({ isOpenTest: false, testType: null }));
        dispatch(setTestStart({ isTestStart: false }));
    }

    return (
        <div className="CompleteTest">
            <div className="CompleteTest-title">Вы точно хотите <br/>завершить тест?</div>
            <Button variant="red" cssClass="CompleteTest-continue" onClick={closeWarn}>Продолжить игру</Button>
            <button className="CompleteTest-btn" onClick={closeTest}>
                Выйти на главную
                <Arrow />
            </button>
        </div>
    );
};

export default memo(CompleteTest);