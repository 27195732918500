import { useState } from "react"
import { ArrowLink, Btn } from ".."
import IconClose from '../../assets/icon-close.svg'
import './game-container.scss'


const CONTENT = {
  confirmText: 'Ты точно хочешь выйти из игры?',
  cancelText: 'Продолжить игру',
  quitText: 'Выйти на главную'
}

export function GameContainer({
  fullscreen,
  children,
  closable = true,
  lightCloseBtn,
  smallCloseBtn,
  onClose = () => { },
  onInitClose = () => { },
  onCancelClose = () => { }
}) {
	const [modalVisible, setModalVisible] = useState(false)
	const close = () => {
		onClose()
	}

	const toggleModal = (visible = true) => {
		setModalVisible(visible)
		if (visible)onInitClose()
		else onCancelClose()
	}

	return (
		<div className={`game-container ${fullscreen ? 'game-container_fs' : ''}`.trim()}>
			<div className="game-container__content">
			{children}
			{closable && (
				<div 
					className={`
						game-container__close
						${lightCloseBtn ? 'game-container__close_light' : ''}
						${smallCloseBtn ? 'game-container__close_small' : ''}
					`.trim()}
					onPointerDown={() => toggleModal(true)}
				>
				<img src={IconClose} />
			</div>
			)}
			</div>
			{modalVisible && (
				<div className="game-container__modal">
					<div className="game-container__modal-content">
						<div className="game-container__modal-title">{CONTENT.confirmText}</div>
						<Btn onClick={() => toggleModal(false)}>{CONTENT.cancelText}</Btn>
						<ArrowLink 
							className="game-container__modal-confirm"
							onClick={() => close()}
						>{CONTENT.quitText}</ArrowLink>
					</div> 
				</div>
			)}
		</div>
	)
}
