import { useState } from 'react';

// Custom hook for localStorage with remove functionality
function useLocalStorage(key) {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : null;
        } catch (error) {
            console.error('Error reading localStorage', error);
            return null;
        }
    });
    
    // Function to update the state and localStorage
    const setValue = (value) => {
        const valueToStore = value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        try {
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.error('Error writing to localStorage', error);
        }
    };

    // Function to remove the item from localStorage and reset the state
    const removeValue = () => {
        setStoredValue(null); // This will trigger useEffect to remove the key
    };

    // Function to completely remove the key from localStorage without updating the state
    const hardRemove = () => {
        window.localStorage.removeItem(key);
        setStoredValue(null);
    };

    return { storedValue, setValue, removeValue, hardRemove };
}

export {useLocalStorage};
