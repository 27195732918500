import { memo } from 'react';
import classNames from 'classnames';

import Loader from '@components/common/Loader';
import photo from '@assets/img/photo-booth/photo.png';
import styles from './PhotoBooth.module.scss';

const PhotoCard = ({ data, isSelect, onSelected, isLoading }) => {    
    return (
        <div className={classNames(styles.day_card, isLoading && styles.is_loading)}>
            {isLoading &&
                <div className={styles.loader}>
                    <Loader />
                </div>
            }
            <div className={styles.ava}>
                <picture>
                    <img src={data.filename || photo} alt="" width={290} height={290} />
                </picture>
            </div>
            <div className={styles.user}>
                {data.user_name} {data.user_surname}
            </div>
            <button disabled={isLoading} className={classNames(styles.like, isSelect && styles.is_active)} onClick={onSelected}>
                <svg width="49" height="48" viewBox="0 0 49 48" fill="none">
                    <circle cx="24.5" cy="24" r="24" />
                    <path d="M30.5213 16.3198C27.5114 16.3198 26.2557 19.2532 24.5005 19.2532C22.7452 19.2532 21.4895 16.3198 18.4797 16.3198C15.629 16.3198 12.9805 18.7635 12.9805 22.0606C12.9805 28.1747 23.2375 32.1716 24.5005 35.5198C25.7635 32.1705 36.0205 28.1747 36.0205 22.0606C36.0205 18.7635 33.3719 16.3198 30.5213 16.3198Z" />
                </svg>
            </button>
        </div>
    );
};

export default memo(PhotoCard);