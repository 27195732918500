import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Title from '@components/common/Title';
import Button from '@components/common/Button';

import { setGameType } from '@store/features'; 
import styles from './Fortune.module.scss';


const Fortune = () => {
    const dispatch = useDispatch();
    const games = useSelector(state => state.games);
    const isFinish = () => new Date().getTime() >= new Date('2024-11-25T00:00:00').getTime();

    const onOpen = () => {
        if (games?.attempts?.random > 0 && !isFinish()) {
            dispatch(setGameType('random'));
        }
    }
    
    return (
        <div className={styles.block}>
            <Title cssClass={styles.title}>Рычаг фортуны</Title>
        
            {!isFinish() &&
                <div className={styles.btn}>
                    <Button
                        onClick={onOpen}
                        disabled={games?.attempts?.random === 0}
                    >
                        Испытать удачу
                    </Button>
                </div>
            }

            <div className={styles.flex}>
                <div className={styles.fortune}></div>
                <div className={styles.content}>
                    <div className={styles.text}>
                        Испытай свою удачу! <br/>
                        Ежедневно крути рандомайзер, <br/>
                        чтобы заработать дополнительные <br/>
                        Ali Coin. Готов проверить, что <br/>
                        достанется тебе сегодня?
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(Fortune);
