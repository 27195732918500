import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { Darts, Memorium, AliWord, Random } from './games'
import './index.scss'
import { getAttempts, start, finish } from './api'
import { useMounted } from '@hooks';
import { setParams, setGameType, fetchUser } from '@store/features';
import { convertKeysToCamelCase } from '@utils';

const GAMES = [
	{
		code: 'darts',
		label: 'Дартс',
		game: Darts
	},
	{
		code: 'memorium',
		label: 'Мемориум',
		game: Memorium
	},
	{
		code: 'random',
		label: 'Рычаг фортуны',
		game: Random
	},
	{
		code: 'aliWord',
		label: 'Найди Али-Слово',
		game: AliWord
	}
]

function GamesModue() {
	const [saveAttempt, setSaveAttempt] = useState(null);
	const dispatch = useDispatch();
	const { user, games } = useSelector(state => state);
	const [currentGameCode, setCurrentGameCode] = useState(null);
	const CurrentGame = currentGameCode === null
		? null
		: GAMES.find(({ code }) => code === currentGameCode).game;
	
	const [attempts, setAttempts] = useState({
		darts: 3,
		memorium: 3,
		aliWord: 1,
		random: 3,
	});
	
	useEffect(() => {
		setCurrentGameCode(games.gameType);
	}, [games]);
	
	useMounted(() => {
		const init = async () => {
			const _attempts = await getAttempts(user.accessToken);
			setAttempts(convertKeysToCamelCase(_attempts));
			dispatch(
				setParams(convertKeysToCamelCase(_attempts))
			);
		}
		init()
	});

	const onStart = async (code) => {
		const res = await start(code, user.accessToken);
		setSaveAttempt(res);
		const _attempts = await getAttempts(user.accessToken);
		setAttempts(convertKeysToCamelCase(_attempts));
		dispatch(
			setParams(convertKeysToCamelCase(_attempts))
		);
	}

	const onFinish = async (code, state) => {
		await finish(code, {
			bonuses: state.score,
			attempt_id: saveAttempt.id,
		}, user.accessToken);
		dispatch(
			fetchUser(user.accessToken)
		);
		setSaveAttempt(null);
	}
	
	const onClose = () => {
		dispatch(
			setGameType(null)
		);
	}

	return (
		<>
			{attempts && CurrentGame !== null && currentGameCode !== null &&
				<CurrentGame
					state={{
						attempts: attempts[currentGameCode],
					}}
					onClose={onClose}
					onStart={(state) => onStart(currentGameCode, state)}
					onFinish={(state) => onFinish(currentGameCode, state)}
				/>
			}
		</>
	)
}

export default GamesModue;
