import { getImageUrl } from '@assets';

export const phrases = [
    { id: 1, phrase: 'У тебя FAIL на ревью', type: ['random'] },
    { id: 2, phrase: 'Алич закрыли!', type: ['random'] },
    { id: 3, phrase: 'Всю распродажу дежуришь в одиночку', type: ['random'] },
    { id: 4, phrase: 'За опоздание на работу – штраф!', type: ['random'] },
    { id: 5, phrase: 'Зарплату получишь баллами лояльности', type: ['random'] },
    { id: 6, phrase: 'Теперь кодишь на Perl', type: ['orlov', 'rijuhin'] },
    { id: 7, phrase: 'Придётся поработать на январских', type: ['random'] },
    { id: 8, phrase: 'Спасибо за работу, но есть небольшие правочки', type: ['gruntova'] },
    { id: 9, phrase: 'Теперь работаем с 8 утра', type: ['random'] },
    { id: 10, phrase: 'У меня к тебе серьёзный разговор…', type: ['random'] },
    { id: 11, phrase: 'Мне кажется, ты переплачен', type: ['random'] },
    { id: 12, phrase: 'Готовый проект нужен через час!', type: ['random'] },
    { id: 13, phrase: 'Вкусняшек по средам в офисе больше не будет', type: ['safanuk', 'gazizova'] },
    { id: 14, phrase: 'Я знаю, что ты вытворял на корпоративе', type: ['random'] },
    { id: 15, phrase: 'Никакого отпуска в ближайший год', type: ['random'] },
    { id: 16, phrase: 'Я вижу твои соседние вкладки', type: ['random'] },
    { id: 17, phrase: 'Тебя в аутинг мы не возьмём', type: ['random'] },
    { id: 18, phrase: 'Объяснительную мне на стол', type: ['random'] },
    { id: 19, phrase: 'На демо отдуваешься за всю команду', type: ['gruntova', 'orlov', 'sahautdinov'] },
    { id: 20, phrase: 'Мы вводим систему учёта рабочего времени', type: ['safanuk'] },
    { id: 21, phrase: 'Буду штрафовать за выключенную вебку на дейликах ', type: ['grishin', 'sahautdinov', 'orlov', 'gruntova', 'ahmetshin', 'zevelev-2', 'zevelev', 'rijuhin', 'gabdulin', 'gromov', 'makeev', 'grechin'] },
    { id: 22, phrase: 'В следующем месяце – 20 дней рождения коллег', type: ['random'] },
    { id: 23, phrase: 'Мне звонили из военкомата, про тебя спрашивали', type: ['random'] },
    { id: 24, phrase: 'Ты – рекордсмен по постмортемам', type: ['orlov', 'sahautdinov'] },
    { id: 25, phrase: 'Приветствую! С тобой не связывался сотрудник ФСБ по поводу проверки?', type: ['zevelev'] },
]


export const users = [
    { id: 1, name: 'safanuk', phrase: 'random', avatar: getImageUrl('fear-comnat/safanuk.png') },
    { id: 2, name: 'gabdulin', phrase: 'random', avatar: getImageUrl('fear-comnat/gabdulin.png') },
    { id: 3, name: 'zevelev', phrase: 'random', avatar: getImageUrl('fear-comnat/zevelev.png') },
    { id: 4, name: 'makeev', phrase: 'random', avatar: getImageUrl('fear-comnat/makeev.png') },
    { id: 5, name: 'zevelev-2', phrase: 'random', avatar: getImageUrl('fear-comnat/zevelev-2.png') },
    { id: 6, name: 'ahmetshin', phrase: 'random', avatar: getImageUrl('fear-comnat/ahmetshin.png') },
    { id: 7, name: 'rijuhin', phrase: 'random', avatar: getImageUrl('fear-comnat/rijuhin.png') },
    { id: 8, name: 'gromov', phrase: 'random', avatar: getImageUrl('fear-comnat/gromov.png') },
    { id: 9, name: 'gruntova', phrase: 'random', avatar: getImageUrl('fear-comnat/gruntova.png') },
    { id: 10, name: 'sahautdinov', phrase: 'random', avatar: getImageUrl('fear-comnat/sahautdinov.png') },
    { id: 11, name: 'grishin', phrase: 'random', avatar: getImageUrl('fear-comnat/grishin.png') },
    { id: 12, name: 'orlov', phrase: 'orlov', avatar: getImageUrl('fear-comnat/orlov.png') },
    { id: 13, name: 'lebedev', phrase: 'random', avatar: getImageUrl('fear-comnat/lebedev.png') },
    { id: 14, name: 'gazizova', phrase: 'random', avatar: getImageUrl('fear-comnat/gazizova.png') },
    { id: 14, name: 'grechin', phrase: 'random', avatar: getImageUrl('fear-comnat/grechin.png') },
];
