import { memo } from 'react';
import classNames from 'classnames';

import BasketRu from './modules/BasketRu';
import BasketUz from './modules/BasketUz';

import styles from './Forms.module.scss';
import BasketOnline from './modules/BasketOnline';

const BasketForm = ({ sum, orderFinish, formType }) => {
    return (
        <div className={classNames(styles.Forms, styles.Forms_order)}>
            {formType === 'ru' ? 
                <BasketRu
                    sum={sum}
                    orderFinish={orderFinish}
                /> : 
                formType === 'global' ?
                <BasketUz
                    sum={sum}
                    orderFinish={orderFinish} 
                /> : 
                formType === 'online' ? 
                <BasketOnline
                    sum={sum}
                    orderFinish={orderFinish}
                /> : null
            }
        </div>
    );
};

export default memo(BasketForm);