/* eslint-disable react/no-unknown-property */
import { memo, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import Title from '@components/common/Title';
import PhotoSegment from './PhotoSegment';
import buttons from './data.jsx';

import Arrow from '@assets/svg/circle-arrow-1.svg?react';
import styles from './PhotoCam.module.scss';

const PhotoCam = ({ result, setResult }) => {
    const context = useRef(null);
    const [stage, setStage] = useState(1);
    const [countDown, setCountDown] = useState(3);
    const [isSnap, setIsSpan] = useState(false);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        const video$ = context.current.querySelector('[data-selector="video"]');
        video$.setAttribute("playsinline", true);
        video$.setAttribute("autoplay", true);
        video$.setAttribute("muted", true);
        video$.style.pointerEvents = "none"; 
        const onExitFullScreen = () => {
            if (document.fullscreenElement) {
                document.exitFullscreen();
            }
        }

        video$.addEventListener("fullscreenchange", onExitFullScreen);

        return () => video$.removeEventListener("fullscreenchange", onExitFullScreen);
    }, [])

    useEffect(() => {
        const canvas1$ = context.current.querySelector('[data-selector="canvas1"]');
        const canvas2$ = context.current.querySelector('[data-selector="canvas2"]');
        const canvas3$ = context.current.querySelector('[data-selector="canvas3"]');
        const final$ = context.current.querySelector('[data-selector="final"]');

        let timeInterval = null;

        const renderFinal = () => {
            const finalCtx = final$.getContext('2d');
            finalCtx.clearRect(0, 0, final$.width, final$.height);
            finalCtx.drawImage(canvas1$, 0, 0);
            finalCtx.drawImage(canvas2$, 0, 0);
            finalCtx.drawImage(canvas3$, 0, 0);
            setResult(final$.toDataURL('image/png'));
        }
       
        if (isSnap) {
            timeInterval = setInterval(() => {
                if (countDown <= 0) {
                    clearInterval(timeInterval);
                    return;
                }
                setCountDown(countDown-1)
            }, 1000);
        }

        if (countDown === 0) {
            renderFinal();
        }
        
        return () => {
            clearTimeout(timeInterval);
        }
    }, [isSnap, countDown, setResult])

    useEffect(() => {
        
        const video$ = context.current.querySelector('[data-selector="video"]');
        const canvas1$ = context.current.querySelector('[data-selector="canvas1"]');
        const canvas2$ = context.current.querySelector('[data-selector="canvas2"]');
        const canvas3$ = context.current.querySelector('[data-selector="canvas3"]');

        let background$ = null;
        let sticker$ = null;
        let frame$ = null;
        let back$ = null;
        let stick$ = null;
        let border$ = null;

        const camera$ = new window.Camera(video$, {
            onFrame: async () => {
                back$ && await back$.send({ image: video$ });
                stick$ && await stick$.send({ image: video$ });
                border$ && await border$.send({ image: video$ });
            },
            width: 300,
            height: 300,
            selfieMode: true,
        });

        camera$.start();

        const readyTimer = setTimeout(() => {
            clearTimeout(readyTimer);
            setIsReady(true);
        }, 100);
        
        const renderFrame = () => {
            const segment = new PhotoSegment();
            const ctx = canvas3$.getContext('2d');
            const WIDTH = (canvas3$.width = 300);
            const HEIGHT = (canvas3$.height = 300);
            
            function onResults() {
                ctx.save();
                ctx.clearRect(0, 0, WIDTH, HEIGHT);
                ctx.imageSmoothingEnabled = false; 
                if (frame$) {
                    ctx.drawImage(frame$, 0, 0, WIDTH, HEIGHT);
                }
                ctx.restore();
            }

            return segment.run(onResults);
        }

        const renderBackground = () => {
            const segment = new PhotoSegment();
            const ctx = canvas1$.getContext('2d');
            const WIDTH = (canvas1$.width = 300);
            const HEIGHT = (canvas1$.height = 300);
            

            function onResults(results) {
                ctx.save();
                ctx.clearRect(0, 0, WIDTH, HEIGHT);
                ctx.imageSmoothingEnabled = false; 
                if (background$) {
                    ctx.drawImage(results.segmentationMask, 0, 0, WIDTH, HEIGHT);
                    ctx.globalCompositeOperation = "source-out";
                    ctx.drawImage(background$, 0, 0, WIDTH, HEIGHT);
                    ctx.globalCompositeOperation = "destination-atop";
                    ctx.drawImage(results.image, 0, 0, WIDTH, HEIGHT);
                }
                ctx.restore();
            }

            return segment.run(onResults);
        };

        const renderSticker = () => {
            const segment = new PhotoSegment();
            const ctx = canvas2$.getContext('2d');
            const WIDTH = (canvas2$.width = 300);
            const HEIGHT = (canvas2$.height = 300);
        
            function onResults(results) {
                ctx.save();
                ctx.clearRect(0, 0, WIDTH, HEIGHT);
                ctx.imageSmoothingEnabled = false; 
                const drawSticker = (landmarks) => {
                    const x = landmarks[4].x * WIDTH - sticker$.width / 2;
                    const y = (landmarks[4].y * HEIGHT - sticker$.height / 2) - (HEIGHT / 3);
                    ctx.drawImage(sticker$, x, y, sticker$.width, sticker$.height);
                }

                if (results.multiFaceLandmarks.length) {
                    drawSticker(results.multiFaceLandmarks[0]);
                }
                ctx.restore();
            }

            return segment.useFace(onResults);
        }
        
        back$ = renderBackground();
        const st = renderSticker();
        const fr = renderFrame();

        const extractImage = (element) => {
            const newSelectedImage = element;
            if (!newSelectedImage) return;
            const prevSelectedImage = element.parentNode.querySelector(".selected");
            if (prevSelectedImage) {
                prevSelectedImage.classList.remove("selected");
                prevSelectedImage.classList.remove(styles.is_acitve);
            }
            newSelectedImage.classList.add("selected");
            newSelectedImage.classList.add(styles.is_acitve);
            const source = context.current.querySelector(`[data-selector="data.photo.anchor"]>[data-photo-anchor="${element.dataset.photoAnchor}"]`);
            return source;
        };

        const getCurrentImage = () => {
            background$ = extractImage(context.current.querySelector('[data-selector="background"] img.selected'));
            sticker$ = extractImage(context.current.querySelector('[data-selector="sticker"] img.selected'));
            frame$ = extractImage(context.current.querySelector('[data-selector="frames"] img.selected'));
        };

        getCurrentImage();

        let stage = 1;

        const handlers = (e) => {
            if (e.target.closest('[data-selector="background"] img')) {
                background$ = extractImage(e.target.closest('[data-selector="background"] img'))
            }

            if (e.target.closest('[data-selector="sticker"] img')) {
                sticker$ = extractImage(e.target.closest('[data-selector="sticker"] img'));
            }

            if (e.target.closest('[data-selector="frames"] img')) {
                frame$ = extractImage(e.target.closest('[data-selector="frames"] img'));
            }

            if (e.target.closest('[data-selector="next"]')) {
                if (stage === 1) {
                    setStage(2);
                    stick$ = st;
                }
                
                if (stage === 2) {
                    setStage(3);
                    border$ = fr
                }

                stage++
            }

            if (e.target.closest('[data-selector="snap"]')) {
                setIsSpan(true);
                setStage(4);
            }
        }

        document.addEventListener('click', handlers);

        return () => {
            document.removeEventListener('click', handlers);
            camera$.stop();
            let timeOut = setTimeout(() => {
                clearTimeout(timeOut);
            }, 100);
        }
    }, [context]);


    return (
        <div className={styles.block} ref={context}>
            {buttons.preLoads}
            <Title cssClass={styles.title}>
                {stage === 1 ? 'Выбери фон'
                    : stage === 2 ? 'Выбери фильтр'
                        : stage === 3 ? 'Выбери рамку'
                            : 'Улыбочку!'
                }
            </Title>
            <div className={classNames(styles.box, (stage === 4 && countDown === 0) && styles.flash, isReady && styles.is_ready)}>
                {result ? 
                    <img src={result} alt="" width={300} height={300} />:
                    <>
                        <video
                            autoPlay
                            playsInline
                            muted
                            className={styles.video} data-selector="video"
                            width={300}
                            height={300}
                        />
                        <canvas className={styles.canvas1} data-selector="canvas1" />
                        <canvas className={styles.canvas2} data-selector="canvas2" />
                        <canvas className={styles.canvas3} data-selector="canvas3" />
                    </>
                }
                <canvas
                    data-selector="final"
                    className={styles.final}
                    width={300} height={300}
                /> 
            </div>
                
            {stage <= 3 ?
                <div className={styles.controls}>
                    <div className={styles.step}>
                        Шаг {stage}/3
                    </div>
                    {buttons.backgrounds(stage === 1, styles)}
                    {buttons.stickers(stage === 2, styles)}
                    {buttons.frames(stage === 3, styles)}
                    <div className={styles.button}>
                        {
                            stage < 3 ?
                            <button data-selector="next">
                                Далее
                                <Arrow />    
                            </button> :
                            <button data-selector="snap">
                                Сделать фото
                                <Arrow />    
                            </button>
                        }
                    </div>
                </div> :
                <div className={styles.counter}>
                    {countDown}
                </div>
            }
        </div>
    );
};

export default memo(PhotoCam);
