import { memo } from 'react';

import Loader from '@components/common/Loader';

import ava from '@assets/img/photo-booth/photo.png'
import label from '@assets/img/photo-booth/label.png'

import styles from './PhotoBooth.module.scss';

const PhotoDay = ({ data, isLoading }) => {
    return (
        <div className={styles.day_photo}>
            {isLoading &&
                <div className={styles.loader}>
                    <Loader />
                </div>
            }
            <picture>
                <img src={data?.filename || ava} alt="" width={210} height={210} />
            </picture>
            {data?.user_name && data?.user_surname ?
                <strong>{data.user_name} {data.user_surname}</strong> :
                <strong>Имя Фамилия</strong>
            }
            <div className={styles.day_label}>
                <picture>
                    <img src={label} alt="" width={166} height={98} />
                </picture>
            </div>
        </div>
    );
};

export default memo(PhotoDay);