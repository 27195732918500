import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import Row from '@components/common/Row';
import Column from '@components/common/Column';
import { useDomObserver, useLocalStorage } from '@hooks';

import './Popup.scss'

const Popup = ({ isOpen, children, content, classes = '', align = 'center' }) => {
    const { modals, user } = useSelector(state => state);
    const [showModal, setShowModal] = useState(false);
    const { storedValue: token } = useLocalStorage('_ali_access_token');

    useEffect(() => {
        setShowModal(isOpen);
    }, [isOpen]);

    useEffect(()=> {
        const timeOut = setTimeout(() => {
            if ((classes === 'registration' && !user.isLogin && !token)&& !modals.isOpenAuth) {
                setShowModal(true);
            }
        }, 700);
        return () => clearTimeout(timeOut);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classes, user, modals, token]);

    const { popupRef } = useDomObserver({
        isLogin: user.isLogin,
        showModal: showModal,
    });

    useEffect(()=> {
        if (modals.isOpenAuth) {
            setShowModal(false);
        }
    }, [modals]);
    
    const popupClasses = classNames('popup__content', classes);

    return (
        <>
            <div className='popup__button' onClick={() => setShowModal(true)}>
                {children}
            </div>
            {showModal && createPortal( 
                <Row
                    className='popup__layout'
                    innerRef={popupRef}
                    align='center'
                    justyfy='center'
                    styles={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 2000,
                        backgroundColor: 'color(srgb 0 0 0 / 0.5)',
                        backdropFilter: 'blur(15px)'
                    }}
                >
                    <Column align={align} className={popupClasses}>
                        <>
                            {user.isLogin &&
                                <button
                                    onClick={() => setShowModal(false)}
                                    className='popup__close'
                                />
                            }
                            {content}
                        </>
                    </Column>
                </Row>,
                document.body
            )}
        </>
    );
}

export default Popup
