import axios from 'axios'

function camelToKebab(str) {
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

export const getAttempts = async (token) => {
  if (!token) return
  const re = await axios.get(`${import.meta.env.VITE_BACKEND_API}/api/v1/user/game-attempts`, {
    headers: {
        'Authorization': 'Bearer '+token || token,
    }
  })
  return re.data.response
}

export const start = async (code, token) => {
  if (!token) return
  const re = await axios.post(`${import.meta.env.VITE_BACKEND_API}/api/v1/game/${camelToKebab(code)}/start`, {}, {
    headers: {
        'Authorization': 'Bearer '+token || '',
    }
  })
  return re.data.response
}

export const finish = async (code, data, token) => {
  if (!token) return
  const re = await axios.post(`${import.meta.env.VITE_BACKEND_API}/api/v1/game/${camelToKebab(code)}/finish`, { ...data }, {
    headers: {
        'Authorization': 'Bearer '+token || '',
    }
  })
  return re.data.response
}
