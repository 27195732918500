import styled from "styled-components"

const StyledColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${({ align }) => align};
    justify-content: ${({ justyfy }) => justyfy};
`

const Column = ({
    children,
    className = '',
    align = 'center',
    justyfy = 'flex-start'
}) => {
    return (
        <StyledColumn
            align={align}
            justyfy={justyfy}
            className={className}
        >
            {children}
        </StyledColumn>
    )
}

export default Column