import './btn.scss'

export function Btn({ children, onClick, onPointerDown, className, disabled, yellow, block }) {
	return (
		<div
			className={`
				btn
				${className} 
				${disabled ? 'btn_disabled' : ''} 
				${yellow ? 'btn_yellow' : ''}
				${block ? 'btn_block' : ''}
				`.trim()
			}
			onClick={disabled ? undefined : onClick}
			onPointerDown={disabled ? undefined : onPointerDown}
			role="button"
		>
			{children}
		</div>
	)
}
