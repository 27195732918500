import { memo, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import classNames from "classnames";

import Button from '@components/common/Button';
import Input from "@components/common/Input";
import { useEmailField } from "@hooks";
import { setOpenAuth, setUserProfile } from "@store/features";
import { getApiRoute } from "@config";

import Arrow from '@assets/svg/arrow.svg?react';
import doc1 from '@assets/docs/termsofuse.pdf';
import doc2 from '@assets/docs/politics.pdf';
import doc3 from '@assets/docs/conditions.pdf';

import styles from '../Forms.module.scss';

const validate = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = 'Это поле обязательно к заполнению';
    }
    if (!values.surname) {
        errors.surname = 'Это поле обязательно к заполнению';
    }
    if (!values.email) {
        errors.email = 'Это поле обязательно к заполнению';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Неверный адрес электронной почты';
    }
    return errors;
}

const Register = ({ changeStage }) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            name: '',
            surname: '',
            email: '',
        },
        validate,
        async onSubmit(values) {
            const isDev = import.meta.env.VITE_FRONT === 'develop';
            setIsLoading(true);
            try {
                const res = await axios.post(getApiRoute('auth.login'), {
                    name: values.name, 
                    surname: values.surname,
                    email: values.email,
                    dev_mode: isDev,
                });
                dispatch(setUserProfile({
                    profile: res.data.response.user,
                }));
                changeStage();
            } catch(err) {
                const errors = err.response.data.errors;
                Object.keys(errors).forEach(k=> {
                    formik.setFieldError(k, errors[k][0])
                })
            } finally {
                setIsLoading(false);
            }
        }
    });
    const [emailMask, changeEmail, fucusEmail] = useEmailField((value)=> {
        formik.setFieldValue('email', value)
    });
    const changeForm = ()=> {
        dispatch(setOpenAuth({
            isOpenAuth: true,
            stage: 'login',
        }))
    };

    const [
        nameError,  
        nameValue,  
        surnameError,  
        surnameValue,  
        emailError,
        emailValue,
    ] = [
        !formik.isValid && formik.errors.name,
        formik.values.name,
        !formik.isValid && formik.errors.surname,
        formik.values.surname,
        !formik.isValid && formik.errors.email,
        formik.values.email,    
    ];

    return (
        <>
            <form className={styles.Forms_form} onSubmit={formik.handleSubmit}>
                <div className={classNames(styles.Forms_row, styles.is_reg)}>
                    <label className={classNames(styles.Forms_field, nameError && styles.is_error)}>
                        <Input
                            name="name"
                            placeholder="Имя"
                            value={nameValue}
                            onChange={formik.handleChange}
                        />
                        {nameError && 
                            <span className={styles.Forms_error}>{nameError}</span>
                        }
                    </label>
                    <label className={classNames(styles.Forms_field, surnameError && styles.is_error)}>
                        <Input
                            name="surname"
                            placeholder="Фамилия"
                            value={surnameValue}
                            onChange={formik.handleChange}
                        />
                        {surnameError && 
                            <span className={styles.Forms_error}>{surnameError}</span>
                        }
                    </label>
                </div>
                <label className={classNames(styles.Forms_field, emailError && styles.is_error)}>
                    <Input
                        name="email"
                        placeholder="______ @aliexpress.ru"
                        value={emailMask}
                        onChange={changeEmail}
                        onFocus={fucusEmail}
                    />
                    {emailError && 
                        <span className={styles.Forms_error}>{emailError}</span>
                    }
                </label>
                <Button 
                    cssClass={styles.Forms_submit}
                    variant="red"
                    isLoading={isLoading}
                    disabled={
                        (nameError || surnameError || emailError) 
                        || (!nameValue.length && !surnameValue.length && !emailValue.length) 
                        || isLoading
                    }
                >
                    Зарегистрироваться
                </Button>
            </form>
            <button className={styles.Forms_btn} onClick={changeForm}>
                Войти
                <Arrow />
            </button>
            <p className={styles.Forms_info}>
                Нажимая кнопку «Зарегистрироваться» вы соглашаетесь <br/>
                с <a href={doc3} target="_blank" rel="noopener noreferrer">условиями акции</a>, <a href={doc1} target="_blank" rel="noopener noreferrer">пользовательским соглашением</a> и <br/>
                <a href={doc2} target="_blank" rel="noopener noreferrer">политикой конфиденциальности</a>
            </p>
        </>
    );
};

export default memo(Register);