import classNames from "classnames";

import './TestBanner.scss';
import Title from "../common/Title";
import Text from "../common/Text";
import Button from "../common/Button";
import { useTestStart } from "./useTestStart";

export const SecondBanner = ({ onOpen }) => {
    const { isLoading, getTestStart } = useTestStart({
        idx: 'skyeng',
        onSuccess() {
            onOpen();
        }
    });

    const cn = classNames('TestBanner', 'TestBanner_second');
    return (
        <div className={cn}>
            <div className="TestBanner-content">
                <Title cssClass="TestBanner-title">
                    Very очень-очень <br/> affordable
                </Title>
                <Text cssClass="TestBanner-text">
                    Порой понять карточки товаров на русском языке в AliExpress — та ещё задача… А что, если прогнать это странное описание через переводчик? Проверь, 
                    сможешь ли ты сопоставить cringe-описание товара с его названием на английском языке, а за его improvement-ом обращайся к нашим друзьям из SkyEng!
                </Text>
                <Button
                    isLoading={isLoading}
                    cssClass="TestBanner-btn"
                    onClick={getTestStart}
                >
                    Начать!
                </Button>
            </div>
        </div>
    );
};