import { memo } from 'react';

import BasketForm from '@components/Forms/BasketForm';
import Title from '@components/common/Title';
import Button from '@components/common/Button';
import BasketList from './BasketList';
import { useBasket } from './useBasket';
import { priceFormatter } from '@utils';

import Coin from '@assets/svg/coin.svg?react';
import styles from './BasketPopup.module.scss';
import Success from './Success';

const BasketPopup = () => {
    const { 
        products,
        openForm,
        localProducts, 
        sum,
        enough,
        balance, 
        orders, 
        isNotEnough,
        isLogin, 
        addProduct, 
        deleteOrder,  
        onOrdered,
        isOrderError,
        openSuccessScene,
        orderFinish,
        availability,
        fails,
        sales,
    } = useBasket();

    if (openSuccessScene) {
        return <Success fails={fails} sales={sales} {...openSuccessScene} />
    }
 
    const firstScreen = (
        <>
            <p className={styles.text}>
                Товары, не оформленные в заказ, будут автоматически удалены из корзины завтра
            </p>
            
            <BasketList 
                data={localProducts}
                orders={orders}
                setProduct={addProduct}
                deleteOrder={deleteOrder}
                availability={availability}
            />

            {isOrderError &&
                <div className={styles.error}>
                    В один заказ можно добавить оффлайн-товары только <br/> одной категории (Россия/Узбекистан)
                </div>
            }

            {(!isOrderError && (isNotEnough && sum > 0)) &&
                <div className={styles.error_en}>
                    У вас не хватает {priceFormatter(enough)} Ali Coins для покупки
                </div>
            }

            {(!isOrderError && (sum > 0)) &&
                <div className={styles.submit}>
                    <Button 
                        variant="red" 
                        onClick={onOrdered}
                        disabled={isNotEnough}
                    > 
                        <Coin />
                        <i>{priceFormatter(sum)}</i>
                        Оформить заказ
                    </Button>
                </div>
            }
        </>
    );

    return (
        <div className={styles.BasketPopup}>
            <div className={styles.column}>
                <div className={styles.head}>
                    <Title cssClass={styles.title}>
                        {openForm ? 'Оформление' : 'Корзина'}
                    </Title>
                    {isLogin &&
                        <div className={styles.coins}>
                            У тебя:
                            <span>
                                <Coin />
                                {priceFormatter(balance)}
                            </span>
                        </div>
                    }
                </div>

                {openForm ?
                    <BasketForm 
                        sum={sum}
                        products={products}
                        formType={openForm}
                        orderFinish={orderFinish}
                    /> : firstScreen
                }
            </div>
        </div>
    );
};

export default memo(BasketPopup);