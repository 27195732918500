import { memo } from "react"
import styled from "styled-components"

const StyledTitle = styled.h2`
    font-family: 'ali', sans-serif;
    font-size: 100px;
    font-weight: 400;
    line-height: 80px;
    letter-spacing: -0.03em;
    text-align: center;
    color: ${({ $color }) => $color};
    
    @media (max-width: 1280px) {
        font-size: 60px;
        line-height: 90%;
    }

    @media (max-width: 480px) {
        font-size: 44px;
    }
`
const Title = ({ children, cssClass, color = 'var(--c-red)' }) => {
    return (
        <StyledTitle $color={color} className={cssClass}>
            {children}
        </StyledTitle>
    )
}

export default memo(Title);