import { GameContainer, GameIntro, GamePanel, GameResult } from '../components'
import { GameMemorium } from '../components/GameMemoerium/GameMemorium'
import MemoriumCover from '../assets/memorium-cover.svg'
import { useGame } from '../hooks/useGame'
import { pluralize } from '../utils/pluralize'

export function Memorium({
  state = { attempts: 0 },
  onClose = () => {},
  onFinish = () => { },
  onStart = () => { }
}) {

  const game = useGame({
    rounds: 3,
    lives: 3,
    attempts: 3,
    durationRound: [60, 45, 30],
    scoreRound: [20, 40, 60],
    onStart: onStart,
    onGameover: onFinish,
  }, state)

  const close = () => {
    game.gameover(true)
    onClose()
  }

  return (
    <>
      <GameContainer
        onClose={close}
        onInitClose={() => !game.state.attempts ? close() : game.pause()}
        onCancelClose={() => game.resume()}
        lightCloseBtn={game.status === 'game'}
        smallCloseBtn={game.status === 'game'}
      >
        {game.status === 'intro' && (
          <GameIntro
            title="Мемориум"
            desc="Готов проверить свою память? Всё просто — поочередно открывай карточки и ищи пары. Чем больше пар найдёшь, тем больше Ali Coin ты получишь!"
            image={MemoriumCover}
            features={[
              pluralize(game.config.rounds, ['раунд', 'раунда', 'раундов'], true),
              `${[game.config.durationRound[0], game.config.durationRound[2]].join('-')} секунд`,
              `${pluralize(game.config.attempts, ['попытка', 'попытки', 'попыток'], true)} в день`
            ]}
            maxAttempts={game.config.attempts}
            attempts={game.state.attempts}
            onStart={() => game.start()}
          />
        )}
        {game.status === 'game' && (
          <>
            <GameMemorium
              onFind={() => game.score()}
              onFinish={() => game.gameover()}
            />
            <GamePanel
              score={game.state.score}
              timeleft={game.state.timeleft}
              round={game.state.round}
            />
          </>
        )}
        {game.status === 'result' && (
          <GameResult
            final={game.isFinished}
            title={game.isFinished ? 'Игра окончена!' : 'Ты отлично справляешься!'}
            desc={game.isFinished ? undefined : 'Осталось совсем чуть-чуть до выигрыша'}
            score={game.state.score}
            image={MemoriumCover}
            maxAttempts={game.config.attempts}
            attempts={game.state.attempts}
            btnText={game.isFinished ? 'Играть повторно' : 'Дальше'}
            onStart={() => game.start()}
            onClose={onClose}
          />
        )}
      </GameContainer>
    </>
  )
}
