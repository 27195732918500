import { memo, useState } from 'react';
import classNames from 'classnames';

import Title from '@components/common/Title';

import Arrow from '@assets/svg/arrow-down.svg?react';
import styles from './Faq.module.scss';
import doc1 from '@assets/docs/termsofuse.pdf';
import doc2 from '@assets/docs/politics.pdf';
import doc3 from '@assets/docs/conditions.pdf';
const data = [
    { 
        id: 1, 
        title: 'Что мне делать с промокодом?',
        desc: 'Твоя задача – отправить свой уникальный промокод как можно большему количеству людей. Мы будем засчитывать каждое применение твоего промокода, а <b>5 победителей</b>, чей промокод используют максимальное количество раз, получат главные призы',
    },
    {
        id: 2,
        title: 'Что будет, если количество применений у нескольких победителей совпадёт?',
        desc: 'В случае, если результаты нескольких лидеров «Промокодной гонки» совпадут, мы будем отталкиваться от показателя GMV по созданным заказам'
    },
    {
        id: 3,
        title: 'Как выиграть мерч? ',
        desc: 'Всё просто – ежедневно зарабатывай Ali Coin: проходи тематические тесты, играй в мини-игры и крути Рычаг Фортуны. В некоторых играх у тебя будет по <b>три попытки </b> в день, из которых мы засчитаем лучшую, а в некоторых – <b>всего одна! </b> Обменять накопленные монеты на мерч и другие призы ты сможешь с <b>25 ноября</b>'
    },
    {
        id: 4,
        title: 'Когда я смогу приобрести подарки?',
        desc: 'Добавить товар из витрины в корзину и оформить заказ ты сможешь с <b>25 ноября</b>. <br/> До этого момента зарабатывай Ali Coin и копи монеты для будущих покупок. Кстати, купить можно не <b>более 5 товаров</b> в одни руки'
    },
    {
        id: 5,
        title: 'Как мне получить свои товары?',
        desc: 'Есть несколько вариантов, как забрать выигрыш: ',
        list: [
            { 
                id: 11,
                text: 'Посетить офис, в котором ты оформил заказ, ежедневно <b>со 16 декабря</b> с 15:00 до 17:00 по местному времени'
            },
            { 
                id: 12,
                text: 'Оформить курьерскую доставку, если ты находишься на территории РФ (кроме Москвы, МО и Нижнего Новгорода)'
            }
        ]
    },
    {
        id: 6,
        title: 'Когда я получу свои онлайн-товары?',
        desc: 'Если ты приобрёл онлайн-товары, мы пришлём их на твою корпоративную электронную почту <br/> в течение 14 рабочих дней с момента оформления заказа'
    },
    {
        id: 8,
        title: 'Где я могу посмотреть юридические документы?',
        desc: 'Со всеми юридическими документами можно ознакомиться по ссылкам:',
        list: [
            {
                id: 23,
                text: `<a href="${doc3}" target="_blank" rel="noopener noreferrer">Условия акции</a>`
            },
            {
                id: 24,
                text: `<a href="${doc1}" target="_blank" rel="noopener noreferrer">Пользовательское соглашение</a>`
            },
            {
                id: 27,
                text: `<a href="${doc2}" target="_blank" rel="noopener noreferrer">Политика конфиденциальности</a>`
            }
        ]
    },
    {
        id: 9,
        title: 'К кому я могу обращаться с вопросами по активации?',
        desc: 'По всем вопросам, связанным с активацией Ali Verse, можно обращаться на почту <br/> <a href="mailto:aer_news@alibaba-inc.com">aer_news@alibaba-inc.com</a>, канал <a href="https://mm.ae-cis.tech/ae-cis/channels/funexpress-support" target="_blank">~funexpress-support<a> в MM или напрямую к Маше Измайловой в iDing / MM'
    },
]

const Item = ({ title, desc, list }) => {
    const [toggle, setToggle] = useState(false);

    const onClick = () => setToggle(!toggle);

    return (
        <div className={styles.item}>
            <div className={classNames(styles.head, toggle && styles.is_open)} onClick={onClick}>
                <span>{title}</span>

                <Arrow />
            </div>

            {toggle &&
                <div className={styles.content}>
                    <div className={styles.text} dangerouslySetInnerHTML={{ __html: desc }} />

                    {list &&
                        <ul>
                            {list.map(item => <li key={item.id} dangerouslySetInnerHTML={{ __html: item.text }} />)}
                        </ul>
                    }
                </div>
            }
        </div>
    );
};

const Faq = () => {
    return (
        <div className={styles.block}>
            <Title cssClass={styles.title}>FAQ</Title>
            <div className={styles.faq}>
                {data.map(item => <Item key={item.id} {...item} />)}
            </div>
        </div>
    );
};

export default memo(Faq);
