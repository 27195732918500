const cases = [2, 0, 1, 1, 1, 2]

export const pluralize = (
  value,
  words,
  withNumber = true,
) => {
  const index = (value % 100 > 4 && value % 100 < 20)
    ? 2
    : cases[Math.min(value % 10, 5)]

  const word = words[index] || words[2]

  return withNumber
    ? `${value} ${word}`
    : word
}
