import { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import Button from '@components/common/Button';
import styles from './FearCompantPopup.module.scss';
import { setOpenFear } from '@store/features';
import { phrases, users } from './data';

function getRandomNumber(min=1, max=25) {
    return Math.floor(Math.random() * max) + min;
}

const FearCompantPopup = () => {
    const [data, setData] = useState({
        phrase: '',
        image: '',
        name: '',
    })
    const dispatch = useDispatch();
 
    useEffect(()=> {
        const random = getRandomNumber(); 
        const phrase = phrases.find(item => item.id === random);
        let user = { phrase: phrase.phrase, image: '', name: '', }; 

        if (phrase.type[0] === 'random') {
            const random = getRandomNumber(1, 14);
            const res = users.find(item => item.id === random);
            user.image = res.avatar;
            user.name = res.name;
        } else {
            const random = phrase.type[getRandomNumber(0, phrase.type.length)];
            const res = users.find(item => item.name === random);
            user.image = res.avatar;
            user.name = res.name;
        }

        user && setData(user);
    }, []);

    const onClose = () => {
        dispatch(setOpenFear({ isOpenFear: false }));
    };
    
    return (
        <div className={styles.FearCompantPopup}>
            <div 
                className={classNames(
                    styles.FearCompantPopup_ava, styles[data.name]
                )}
            >
                <picture>
                    <img src={data.image} alt="" />
                </picture>
            </div>
            <div className={styles.FearCompantPopup_phrase}>
                <span>{data.phrase}</span>
            </div>
            <div className={styles.FearCompantPopup_btn}>
                <Button onClick={onClose}>
                    Страшно, вырубай!
                </Button>
            </div>
        </div>
    );
};

export default memo(FearCompantPopup);