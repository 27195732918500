import { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTestStart, setOpenTest } from "@store/features";

import { FirstBanner, ReviewResult, SecondBanner, AffordableResult, ThirdBanner } from "@components/TestBanner";
import TestForm from "@components/TestForm/TestForm";
import { data } from "./data";

import './TestPopup.scss';

const TestBanner = () => {
    const modals = useSelector(state => state.modals);
    const [isStart, setIsStart] = useState(false);
    const [result, setResult] = useState([0, 0]);
    const [isFinish, setIsFinish] = useState(false);
    
    const dispatch = useDispatch();

    const openTestForm = () => {
        dispatch(setTestStart({
            isTestStart: true,
        }));
        setIsStart(true);
    };

    const complete = () => {
        dispatch(setOpenTest({
            isOpenTest: false,
            testType: null,
        }));
    };

    const onFinish = (result, total) => {
        setResult([result, total]);
        
        const timeOut = setTimeout(() => {
            clearTimeout(timeOut);
            setIsFinish(true);
            setIsStart(false);
            dispatch(setTestStart({
                isTestFinish: true,
            }));
        }, 300);
    };

    if (isFinish) {
        return (
            (modals.testType === 'review' || modals.testType === 'aliexpress') ? <ReviewResult result={result[0]} total={result[1]} onClose={complete} /> :
            modals.testType === 'skyeng' ?  <AffordableResult result={result[0]} total={result[1]} onClose={complete} /> : 
            null
        )
    }

    if (isStart) {
        return (
            <TestForm 
                data={data[modals.testType]} 
                type={modals.testType}
                onFinish={onFinish}
            />
        );
    };

    return (
        modals.testType === 'review' ? <FirstBanner onOpen={openTestForm} /> :
        modals.testType === 'skyeng' ? <SecondBanner  onOpen={openTestForm} /> : 
        modals.testType === 'aliexpress' ? <ThirdBanner  onOpen={openTestForm} /> : 
        null
    );
};

export default memo(TestBanner);