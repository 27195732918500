import { getImageUrl } from '@assets';

export const data = {
    review: [
        { 
            id: "68078119-d73d-4111-ba6a-7928de292926",
            number: 1,
            image: "20 сентября 2024",
            question: "Отлично подойдёт для проведения эзотерических сходок и понравится всем. Качество очень хорошее, доставили быстро. Размер не очень большой, подойдёт для карлика или ребёнка. Я крайне довольна заказом!",
            variants: [
                { id: 1, label: "Шлем", image: null, isCorrect: false },
                { id: 2, label: "Плед", image: getImageUrl('test/prizes/review/1.png'), isCorrect: true },
                { id: 3, label: "Воротник", image: null, isCorrect: false },
                { id: 4, label: "Гроб", image: null, isCorrect: false }
            ]
        },
        { 
            id: "68078119-d73d-4111-ba6a-d37939502ea7",
            number: 2,
            reviewDate: "18 сентября 2024",
            question: "____ на самом деле большой. Насчёт 80 л не уверен, но ребёнок 30 кг входит без проблем.",
            variants: [
                { id: 1, label: "Таз", image: null, isCorrect: false },
                { id: 2, label: "Багажник для мотоцикла", image: null, isCorrect: false },
                { id: 3, label: "Рюкзак", image: getImageUrl('test/prizes/review/2.png'), isCorrect: true },
                { id: 4, label: "Тандыр", image: null, isCorrect: false }
            ]
        },
        { 
            id: "df295e1e-2a38-4326-a0fa-d540a7fa4854",
            number: 3,
            reviewDate: "12 августа 2024",
            question: "____ вообще не продуманы! Постоянно с палубы выглядывают либо капитан, либо его помощники. А если не аккуратно присесть на корточки, то свисает сразу весь экипаж! Меня это не очень-то устраивает, но хочу заметить, что жена довольна.",
            variants: [
                { id: 1, label: "Декорации для аквариума", image: null, isCorrect: false },
                { id: 2, label: "Трусы", image: getImageUrl('test/prizes/review/3.png'), isCorrect: true },
                { id: 3, label: "Маскарадные очки", image: null, isCorrect: false },
                { id: 4, label: "Конструктор «Пиратский корабль»", image: null, isCorrect: false }
            ]
        }, 
        {
            id: "8bf6e6ce-53a8-42d5-bf54-69e316dfa41e",
            number: 4,
            reviewDate: "8 июня 2024",
            question: "Нарядный, как невеста! Понравился всем, спасибо, шикарно!",
            variants: [
                { id: 1, label: "Парик", image: null, isCorrect: false },
                { id: 2, label: "Брючный костюм", image: null, isCorrect: false },
                { id: 3, label: "Комбинезон для собак", image: null, isCorrect: false },
                { id: 4, label: "Ободок для унитаза", image: getImageUrl('test/prizes/review/4.png'), isCorrect: true },
            ]
        },
        {
            id: "0c0dc4ee-399c-40af-a88f-5f25ad08b9a6",
            number: 5,
            reviewDate: "27 сентября 2024",
            question: "___ чёткий, действует бомбезно! Эффективный способ послать неприятеля, если только он не сильнее тебя…",
            variants: [
                { id: 1, label: "Кепарик", image: getImageUrl('test/prizes/review/5.png'), isCorrect: true },
                { id: 2, label: "Худосик", image: null, isCorrect: false },
                { id: 3, label: "Электрошокер", image: null, isCorrect: false },
                { id: 4, label: "Костюмчик", image: null, isCorrect: false },
            ]
        },
        {
            id: "5c7464a7-703b-4cdb-800b-190a78da5ea2",
            number: 6,
            reviewDate: "2 апреля 2024",
            question: "Я заказал её, когда был нетрезв, потому что думал, что это огромное мороженое-сендвич. Это не так. Это ____, а не мороженое-сендвич за 150$, которое я так хотел(",
            variants: [
                { id: 1, label: "Подушка", image: null, isCorrect: false },
                { id: 2, label: "Плед", image: null, isCorrect: false },
                { id: 3, label: "Матрас", image: getImageUrl('test/prizes/review/6.png'), isCorrect: true },
                { id: 4, label: "Батут", image: null, isCorrect: false },
            ]
        },
        {
            id: "3fe6b1a1-3ab2-4b66-ae01-3a4b65d4da14",
            number: 7,
            reviewDate: "21 мая 2024",
            question: "Соответствует описанию. Я боялся, что отверстие будет слишком маленьким, но это прекрасно!",
            variants: [
                { id: 1, label: "Игрушка для взрослых", image: null, isCorrect: false },
                { id: 2, label: "Ремешок для винного бокала", image: getImageUrl('test/prizes/review/7.png'), isCorrect: true },
                { id: 3, label: "Шарф-воротник", image: null, isCorrect: false },
                { id: 4, label: "Банки для массажа", image: null, isCorrect: false },
            ]
        },
        {
            id: "0093b60e-376f-4e72-819e-bd63743026d8",
            number: 8,
            reviewDate: "4 января 2024",
            question: "Наконец-то я нашёл аксессуар, полностью описывающий моё внутреннее я!",
            variants: [
                { id: 1, label: "Пиратская треуголка", image: null, isCorrect: false },
                { id: 2, label: "Маска Гая Фокса", image: null, isCorrect: false },
                { id: 3, label: "Дамская сумка", image: null, isCorrect: false },
                { id: 4, label: "Клоунский нос", image: getImageUrl('test/prizes/review/8.png'), isCorrect: true },
            ]
        },
        {
            id: "d19a50c2-5131-4e03-b80e-315dcf87cf3a",
            number: 9,
            reviewDate: "19 февраля 2024",
            question: "Очень мило, они выглядят вампирскими хе-хе, если нет хорошего света. Они выходят немного непрозрачными на фотографиях, но лично для меня они всё время выглядят невероятно!",
            variants: [
                { id: 1, label: "Линзы", image: getImageUrl('test/prizes/review/9.png'), isCorrect: true },
                { id: 2, label: "Накладные зубы", image: null, isCorrect: false },
                { id: 3, label: "Стразы для лица", image: null, isCorrect: false },
                { id: 4, label: "Чулки", image: null, isCorrect: false },
            ]
        },
        {
            id: "ee699193-56ff-49ee-bff4-b73b1a4395d4",
            number: 10,
            reviewDate: "7 июля 2024",
            question: "В хорошем состоянии, спасибо! Только его трудно засунуть внутрь, я думала, что оно гладкое…",
            variants: [
                { id: 1, label: "Вибратор", image: null, isCorrect: false },
                { id: 2, label: "Термометр", image: null, isCorrect: false },
                { id: 3, label: "Ирригатор для ушей", image: getImageUrl('test/prizes/review/10.png'), isCorrect: true },
                { id: 4, label: "Экспресс-тест на Covid-19", image: null, isCorrect: false },
            ]
        }
    ],
    skyeng: [
        { 
            id: "68078119-d73d-4111-ba6a-7928de292926",
            number: 1,
            image: getImageUrl('test/prizes/affordable/1.png'),
            question: "«3D силиконовая плесень в форме цветка, сделай сам». <span>Как назовёшь этот товар на английском языке?</span>",
            variants: [
                { id: 1, label: "Silicone mold", image: null, isCorrect: true },
                { id: 2, label: "Silicone cake", image: null, isCorrect: false },
                { id: 3, label: "Silicone flower form", image: null, isCorrect: false },
                { id: 4, label: "Silicone dish", image: null, isCorrect: false }
            ]
        },
        { 
            id: "68078119-d73d-4111-ba6a-d37939502ea7",
            number: 2,
            image: getImageUrl('test/prizes/affordable/2.png'),
            question: "«Рок-сад для декора двора». <br> <span>А здесь что было в оригинале?</span>",
            variants: [
                { id: 1, label: "Music garden", image: null, isCorrect: false },
                { id: 2, label: "Stone garden", image: null, isCorrect: false },
                { id: 3, label: "Hard garden", image: null, isCorrect: false },
                { id: 4, label: "Rock garden", image: null, isCorrect: true }
            ]
        },
        { 
            id: "df295e1e-2a38-4326-a0fa-d540a7fa4854",
            number: 3,
            image: getImageUrl('test/prizes/affordable/3.png'),
            question: "«Система освежения с функцией создания холодного тумана». <span>Как назовём эту вещь на английском?</span>",
            variants: [
                { id: 1, label: "Fog maker", image: null, isCorrect: false },
                { id: 2, label: "Mist maker", image: null, isCorrect: true },
                { id: 3, label: "Smoke make", image: null, isCorrect: false },
                { id: 4, label: "Fume maker", image: null, isCorrect: false }
            ]
        }, 
        {
            id: "8bf6e6ce-53a8-42d5-bf54-69e316dfa41e",
            number: 4,
            image: getImageUrl('test/prizes/affordable/4.png'),
            question: "«Электрический фанат для охлаждения». <span>А что было тут?</span>",
            variants: [
                { id: 1, label: "Electric supporter", image: null, isCorrect: false },
                { id: 2, label: "Electric friend", image: null, isCorrect: false },
                { id: 3, label: "Electric fan", image: null, isCorrect: true },
                { id: 4, label: "Electric cooler", image: null, isCorrect: false },
            ]
        },
        {
            id: "0c0dc4ee-399c-40af-a88f-5f25ad08b9a6",
            number: 5,
            image: getImageUrl('test/prizes/affordable/5.png'),
            question: "«Пробка из машин для варенья». <br/> <span>Это что за покемон?</span>",
            variants: [
                { id: 1, label: "Jam maker", image: null, isCorrect: true },
                { id: 2, label: "Traffic maker", image: null, isCorrect: false },
                { id: 3, label: "Fruit press", image: null, isCorrect: false },
                { id: 4, label: "Juice machine", image: null, isCorrect: false },
            ]
        },
        {
            id: "5c7464a7-703b-4cdb-800b-190a78da5ea2",
            number: 6,
            image: getImageUrl('test/prizes/affordable/6.png'),
            question: "«Fat lion biography paperback». <br/> <span>А про что здесь речь?</span>",
            variants: [
                { id: 1, label: "Тетрадь с толстыми львами", image: null, isCorrect: false },
                { id: 2, label: "Фотография сытых львов", image: null, isCorrect: false },
                { id: 3, label: "Рецепт лекарства с львиным жиром", image: null, isCorrect: false },
                { id: 4, label: "Биография Льва Толстого", image: null, isCorrect: true },
            ]
        },
        {
            id: "3fe6b1a1-3ab2-4b66-ae01-3a4b65d4da14",
            number: 7,
            image: getImageUrl('test/prizes/affordable/7.png'),
            question: "«Увлажняющий крем для гвоздей». <br /> <span>О каком товаре идёт речь?</span>",
            variants: [
                { id: 1, label: "Finger cream", image: null, isCorrect: false },
                { id: 2, label: "Polish cream", image: null, isCorrect: false },
                { id: 3, label: "Nail cream", image: null, isCorrect: true },
                { id: 4, label: "Wood cream", image: null, isCorrect: false },
            ]
        },
        {
            id: "0093b60e-376f-4e72-819e-bd63743026d8",
            number: 8,
            image: getImageUrl('test/prizes/affordable/8.png'),
            question: "«Летучая мышь деревянная 1,1 м для игры».  <br/> <span> Чем будем играть?</span>",
            variants: [
                { id: 1, label: "Wooden bird", image: null, isCorrect: false },
                { id: 2, label: "Wooden mouse", image: null, isCorrect: false },
                { id: 3, label: "Wooden bat", image: null, isCorrect: true },
                { id: 4, label: "Long stick", image: null, isCorrect: false },
            ]
        },
        {
            id: "d19a50c2-5131-4e03-b80e-315dcf87cf3a",
            number: 9,
            image: getImageUrl('test/prizes/affordable/9.png'),
            question: "«Dark mouse with lights». <br/><span>Как звучит оригинал на русском?</span>",
            variants: [
                { id: 1, label: "Мышка с горящими глазами", image: null, isCorrect: false },
                { id: 2, label: "Чёрная мышка с подсветкой", image: null, isCorrect: true },
                { id: 3, label: "Тёмная мышь с фарами", image: null, isCorrect: false },
                { id: 4, label: "Мышь, боящаяся света", image: null, isCorrect: false },
            ]
        },
        {
            id: "ee699193-56ff-49ee-bff4-b73b1a4395d4",
            number: 10,
            image: getImageUrl('test/prizes/affordable/10.png'),
            question: "«Hard cover for a chair». <span>Как назовём этот товар по-русски?</span>",
            variants: [
                { id: 1, label: "Плотный ковёр для стула", image: null, isCorrect: false },
                { id: 2, label: "Жёсткая обложка для стула", image: null, isCorrect: false },
                { id: 3, label: "Укрытие для руководителя", image: null, isCorrect: false },
                { id: 4, label: "Прочный чехол для стула", image: null, isCorrect: true },
            ]
        }
    ],
    aliexpress: [
        { 
            id: "68078119-d73d-4111-ba6a-7928de292926",
            number: 1,
            desc: "В 1995 году Джек Ма впервые приехал в США. Он работал переводчиком в составе китайской торговой делегации. Тогда Джек впервые узнал о существовании интернета. Вместе с приятелем они набрали в Yahoo! запрос «пиво», и выяснили, что на Западе ничего не известно о продукции из Китая. Так у Джека Ма появилась идея основать интернет-бизнес у себя на родине",
            question: "Какой запрос в интернете натолкнул Джека Ма на создание интернет-бизнеса в Китае?",
            variants: [
                { id: 1, label: "Купить что-нибудь онлайн", image: null, isCorrect: false },
                { id: 2, label: "Пиво", image: null, isCorrect: true },
                { id: 3, label: "Быстрая доставка", image: null, isCorrect: false },
                { id: 4, label: "Шлем для курицы заказать", image: null, isCorrect: false }
            ]
        },
        { 
            id: "68078119-d73d-4111-ba6a-d37939502ea7",
            number: 2,
            desc: "BabaZina - Бот в Mattermost, который умеет проверять статус товара в разных системах, включая локальный и глобальный статус в Item Center; наличие блокировок; наличие стоков и т.п. Главная задача - это ответить на вопрос, почему товар недоступен на карточке товара",
            question: "Что делает Баба Зина?",
            variants: [
                { id: 1, label: "Кормит голодных сотрудников", image: null, isCorrect: false },
                { id: 2, label: "Проверяет статус товара ", image: null, isCorrect: true },
                { id: 3, label: "Перехватывает звонки от мошенников", image: null, isCorrect: false },
                { id: 4, label: "Это не наше, это из OZON", image: null, isCorrect: false }
            ]
        },
        { 
            id: "df295e1e-2a38-4326-a0fa-d540a7fa4854",
            number: 3,
            desc: "Тапаешь? :)",
            question: "Какая кличка у главной страницы (Home Page)?",
            variants: [
                { id: 1, label: "Пока все дома", image: null, isCorrect: false },
                { id: 2, label: "Тук-тук", image: null, isCorrect: false },
                { id: 3, label: "Хомяк", image: null, isCorrect: true },
                { id: 4, label: "Хоме паге", image: null, isCorrect: false }
            ]
        }, 
        {
            id: "8bf6e6ce-53a8-42d5-bf54-69e316dfa41e",
            number: 4,
            desc: "Мадрид и Гонконг находятся на 11 этаже, Иркутск расположен на 10 этаже. А вот Апшеронск находится в краснодарском крае.  ",
            question: "Какой переговорки нет в Московском офисе?",
            variants: [
                { id: 1, label: "Мадрид", image: null, isCorrect: false },
                { id: 2, label: "Апшеронск", image: null, isCorrect: true },
                { id: 3, label: "Гонконг", image: null, isCorrect: false },
                { id: 4, label: "Иркутск", image: null, isCorrect: false },
            ]
        },
        {
            id: "0c0dc4ee-399c-40af-a88f-5f25ad08b9a6",
            number: 5,
            desc: "Оптимизм. Аутентичность. Разнообразие. Доступность. Это мы! (но и скорость мы не оставляем без внимания)", 
            question: "Что не является ценностью и миссией нашего бренда?",
            variants: [
                { id: 1, label: "Оптимизм", image: null, isCorrect: false },
                { id: 2, label: "Доступность", image: null, isCorrect: false },
                { id: 3, label: "Разнообразие", image: null, isCorrect: false },
                { id: 4, label: "Скорость", image: null, isCorrect: true },
            ]
        },
        {
            id: "5c7464a7-703b-4cdb-800b-190a78da5ea2",
            number: 6,
            desc: "Наши коллеги из команды логистики ускорили доставку товаров категории Battery Goods на 8 дней — Москва: с 24 дней до 16 дней, Топ-16 городов: с 25 дней до 17 дней, вся география: с 26 дней до 18 дней",
            question: "На сколько дней команде логистики удалось уменьшить срок доставки в этом году?",
            variants: [
                { id: 1, label: "На 8 дней", image: null, isCorrect: true },
                { id: 2, label: "На 4 дня", image: null, isCorrect: false },
                { id: 3, label: "На 6 дней", image: null, isCorrect: false },
                { id: 4, label: "На 11 дней", image: null, isCorrect: false },
            ]
        },
        {
            id: "3fe6b1a1-3ab2-4b66-ae01-3a4b65d4da14",
            number: 7,
            desc: "Надеемся, все эти аббревиатуры тебе знакомы. А если нет, скорее загляни в глоссарий AliExpress!",
            question: "Назови 4 ключевых фокуса компании в 2024 году",
            variants: [
                { id: 1, label: "GMV, EBITDA, C2D, Choice", image: null, isCorrect: false },
                { id: 2, label: "GMV, AOV, New Buyers, Choice", image: null, isCorrect: false },
                { id: 3, label: "GMV, New Buyers, C2D, Choice", image: null, isCorrect: true },
                { id: 4, label: "iHerb, C2D, GMV, EBITDA", image: null, isCorrect: false },
            ]
        },
        {
            id: "0093b60e-376f-4e72-819e-bd63743026d8",
            number: 8,
            desc: "Магия чисел!",
            question: "В какой раз распродажа 11.11 проходит в AliExpress Россия ?",
            variants: [
                { id: 1, label: "Юбилейный 10 раз", image: null, isCorrect: false },
                { id: 2, label: "7 раз", image: null, isCorrect: false },
                { id: 3, label: "5 раз", image: null, isCorrect: false },
                { id: 4, label: "11 раз", image: null, isCorrect: true },
            ]
        },
        {
            id: "d19a50c2-5131-4e03-b80e-315dcf87cf3a",
            number: 9,
            desc: "Распрода-а-ажа на AliExpress! Только на AliExpress!",
            question: "Какие товары в рекламе AliExpress не перечислял Максим Галкин?",
            variants: [
                { id: 1, label: "Комбайн", image: null, isCorrect: false },
                { id: 2, label: "Пенал", image: null, isCorrect: true },
                { id: 3, label: "Синтезатор", image: null, isCorrect: false },
                { id: 4, label: "Халат из велюра", image: null, isCorrect: false },
            ]
        },
        {
            id: "ee699193-56ff-49ee-bff4-b73b1a4395d4",
            number: 10,
            desc: "Самый красный и прекрасный! Только он и больше никаких других красных!",
            question: "Как называется оттенок красного цвета  AliExpress в цветовой карте? ",
            variants: [
                { id: 1, label: "Red Alert 18-1559 TCX", image: null, isCorrect: false },
                { id: 2, label: "Warm red c #FF2722", image: null, isCorrect: true },
                { id: 3, label: "Deep red #7B001C", image: null, isCorrect: false },
                { id: 4, label: "Tomat 685 / S 1085 - Y90R", image: null, isCorrect: false },
            ]
        }
    ],
}