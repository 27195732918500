import IconScore from '../../assets/icon-score.svg'
import { ArrowLink, Btn } from '..'
import IconPower from '../../assets/icon-power.svg'
import './game-result.scss'


export function GameResult({
  title,
  score,
  attempts,
  maxAttempts,
  image,
  btnText,
  desc,
  canRestart,
  hideAttempts,
  final,
  onStart = () => { },
  onClose = () => { }
}) {

  const noBtn = final && attempts === 0

  return (
    <>
    <div className="game-result">
        <img className="game-result__image" src={image} />
      <div className="game-result__content">
        <div className="game-result__title">{ title }</div>
        {(final || desc) && (
          <div className="game-result__desc">
            {final && <>Ты заработал { score } Ali Coin!</>}
            {(final && desc) && <br />}
            {desc && <>{ desc }</>}
          </div>
        )}
        {final && (
            <div className="game-result__coupon">+{score} <img src={IconScore} /> ALI COIN</div>
        )}
        <div className="game-result__footer">
            {!hideAttempts && <div className="game-result__attempts">
              Попытки на сегодня: <img src={IconPower} />{attempts}/{maxAttempts}
            </div>}
          
            {!noBtn && (
              <Btn
              className="game-result__start"
              onClick={() => onStart()}
            >
              {btnText}
              </Btn>
            )}
            {(final && canRestart) && (
              <ArrowLink
                className="game-result__close"
                onClick={() => onClose()}
              >
                Выйти из игры
              </ArrowLink>
            )}
        </div>
      </div>
    </div>
    </>
  )
}
