import { memo, useState } from "react";
import { useFormik } from "formik";
import classNames from "classnames";
import axios from "axios";

import Button from '@components/common/Button';
import InputPass from "@components/common/InputPass";
import { getApiRoute } from "@config";
import { useLocalStorage } from "@hooks";

import styles from '../Forms.module.scss';

const validate = (values) => {
    const errors = {};
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
    if (!values.password) {
        errors.password = 'Это поле обязательно к заполнению';
    } else if (!passwordRegex.test(values.password)) {
        errors.password = 'Пароль не соответствует требованиям.';
    }
    
    if (!values.repeat_password) {
        errors.repeat_password = 'Это поле обязательно к заполнению';
    } else if (values.password.toLowerCase() !== values.repeat_password.toLowerCase()) {
        errors.repeat_password = 'Пароли не совпадают';
    }
    return errors;
}

const CreatePassword = ({ changeStage }) => {
    const { storedValue: token } = useLocalStorage('_ali_access_token');
    const [isLoading, setIsLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            password: '',
            repeat_password: '',
        },
        validate,
        async onSubmit(values) {
            setIsLoading(true);
            try {
                await axios.post(getApiRoute('auth.password'), {
                    password: values.password,
                }, {
                    headers: {
                        Authorization: 'Bearer '+token?.access_token, 
                    }
                });
                changeStage();
            } catch(err) {
                formik.setFieldError('password', err.response.data.errors[0])
            } finally {
                setIsLoading(false);
            }
        }
    })

    const [
        passwordError,  
        passwordValue,  
        repeatError,
        repeatValue,
    ] = [
        !formik.isValid && formik.errors.password,
        formik.values.password,
        !formik.isValid && formik.errors.repeat_password,
        formik.values.repeat_password,    
    ];

    return (
        <>
            <p className={styles.Forms_text}>
                Придумайте пароль
            </p>
            <form className={styles.Forms_form} onSubmit={formik.handleSubmit}>
                <label className={classNames(styles.Forms_field, passwordError && styles.is_error)}>
                    <InputPass
                        name="password"
                        placeholder="Пароль"
                        value={passwordValue}
                        disabled={isLoading}
                        onChange={formik.handleChange}
                    />
                    {passwordError &&
                        <span className={styles.Forms_error}>{passwordError}</span>
                    }
                </label>
                <label className={classNames(styles.Forms_field, repeatError && styles.is_error)}>
                    <InputPass 
                        name="repeat_password"
                        placeholder="Повторите пароль"
                        value={repeatValue}
                        disabled={isLoading}
                        onChange={formik.handleChange}
                    />
                    {repeatError &&
                        <span className={styles.Forms_error}>{repeatError}</span>
                    }
                </label>
                <Button 
                    variant="red"
                    cssClass={styles.Forms_submit}
                    isLoading={isLoading}
                    disabled={
                        (passwordError || repeatError) 
                        || (!passwordValue.length && !repeatError.length) || isLoading
                    }
                >
                    Сохранить
                </Button>
            </form>
        </>
    );
};

export default memo(CreatePassword);