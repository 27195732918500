import { memo, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import classNames from "classnames";

import Button from '@components/common/Button';
import Input from "@components/common/Input";
import InputPass from "@components/common/InputPass";
import { setUserProfile, setUserToken, setOpenAuth } from "@store/features";
import { useEmailField, useLocalStorage } from "@hooks";
import { getApiRoute } from "@config";

import Arrow from '@assets/svg/arrow.svg?react';


import styles from '../Forms.module.scss';

const validate = (values) => {
    const errors = {};
    if (!values.password) {
        errors.password = 'Это поле обязательно к заполнению';
    }
    if (!values.email) {
        errors.email = 'Это поле обязательно к заполнению';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Неверный адрес электронной почты';
    }
    return errors;
}

const Login = ({ changeStage }) => {
    const { setValue: setAccsessToken } = useLocalStorage('_ali_access_token');

    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validate,
        async onSubmit(values) {
            setIsLoading(true);
            try {
                const res = await axios.post(getApiRoute('auth.login'), {
                    email: values.email,
                    code: values.password,
                });
                
                dispatch(setUserToken({
                    accessToken: res.data.response.auth.access_token,
                }));
                setAccsessToken(res.data.response.auth);
                dispatch(setUserProfile({
                    profile: res.data.response.user,
                }));
                dispatch(setOpenAuth({
                    isOpenAuth: false,
                    stage: null,
                }))
                changeStage();
            } catch(err) {
                const error = err.response.data.message;
                formik.setFieldError('email', error)
                formik.setFieldError('password', error)
            } finally {
                setIsLoading(false);
            }
        }
    });

    const [emailMask, changeEmail, fucusEmail] = useEmailField((value)=> {
        formik.setFieldValue('email', value)
    });

    const changeForm = ()=> {
        dispatch(setOpenAuth({
            isOpenAuth: true,
            stage: 'password',
        }))
    };

    const [
        passwordError,  
        passwordValue,  
        emailError,
        emailValue,
    ] = [
        !formik.isValid && formik.errors.password,
        formik.values.password,
        !formik.isValid && formik.errors.email,
        formik.values.email,    
    ];

    return (
        <>
            <form className={styles.Forms_form} onSubmit={formik.handleSubmit}>
                <label className={classNames(styles.Forms_field, emailError && styles.is_error)}>
                    <Input
                        name="email"
                        placeholder="______ @aliexpress.ru"
                        value={emailMask}
                        onChange={changeEmail}
                        onFocus={fucusEmail}
                    />
                </label>
                <label className={classNames(styles.Forms_field, passwordError && styles.is_error)}>
                    <InputPass
                        name="password"
                        placeholder="Пароль"
                        value={passwordValue}
                        onChange={formik.handleChange}
                    />
                </label>

                {emailError && 
                    <span className={styles.Forms_error} style={{ marginBottom: 12 }}>{emailError}</span>
                }
                <Button 
                    cssClass={styles.Forms_submit}
                    variant="red"
                    isLoading={isLoading}
                    disabled={
                        (passwordError || emailError) 
                        || (!passwordValue.length && !emailValue.length) 
                        || isLoading
                    }
                >
                    Войти
                </Button>
            </form>
            <button className={styles.Forms_btn} onClick={changeForm}>
                Не помню пароль
                <Arrow />
            </button>
        </>
    );
};

export default memo(Login);