import { createPortal } from "react-dom";
import { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { useDomObserver } from "@hooks";
import Close from '@assets/svg/close.svg?react';

const Modal = ({
    isOpen,
    onClose,
    content,
    isRight,
    isAuth,
    isCam,
    isSmall,
}) => {
    const user = useSelector(state => state.user);

    const { popupRef } = useDomObserver({
        isLogin: user.isLogin,
        showModal: isOpen,
    });

    useEffect(()=> {
        if (isOpen) {
            document.body.classList.add('is_hidden');
            document.body.style.setProperty('--modal-scrollbar-compensate', `${window.innerWidth - document.documentElement.clientWidth}px`)
        } else {
            document.body.classList.remove('is_hidden');
            document.body.style.removeProperty('--modal-scrollbar-compensate')
        }
    }, [isOpen]);

    const handleClose = () => {
        if (user.isLogin) {
            onClose();
        }
    }

    if (!isOpen) return null;
    return createPortal(
        (
            <div 
                ref={popupRef} 
                className={classNames('Modal',  (isAuth || isSmall) && 'Modal_is_auth')}
                style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    zIndex: 2000,
                    backgroundColor: 'color(srgb 0 0 0 / 0.5)',
                    backdropFilter: 'blur(15px)'
                }}
            >
                <div className="Modal-bg" onClick={handleClose}></div>
                <div className={
                    classNames(
                        'Modal-box', 
                        isRight && 'Modal-box_is_right',
                        isAuth && 'Modal-box_is_auth',
                        isSmall && 'Modal-box_is_small',
                        isCam && 'Modal-box_is_cam'
                    )
                }>
                    {user.isLogin &&
                        <button className="Modal-close" onClick={onClose}>
                            <Close />
                        </button>
                    }
                    {content}
                </div>
            </div>
        ),
        document.body
    )
};

export default memo(Modal);