import { memo } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@components/common/Button';
import Title from '@components/common/Title';
import { setOpenFear } from '@store/features';

import styles from './FearCompant.module.scss';

const FearCompant = () => {
    const dispatch = useDispatch();

    const onOpen = () => {
        dispatch(setOpenFear({
            isOpenFear: true,
        }));
    };

    return (
        <div className={styles.FearCompant}>
            <div className={styles.FearCompant_box}>
                <Title cssClass={styles.FearCompant_title}>
                    Загляни в комнату <br /> страха, если не <br />боишься!
                </Title>
                <Button onClick={onOpen}>Открыть</Button>
            </div>
        </div>
    );
};

export default memo(FearCompant);
