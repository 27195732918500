import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getApiRoute } from "@config";
import { setAttemptId, fetchUser } from '@store/features';

export const useTestFinish = ({
    idx,
    assembly,
    onSuccess,
    onError,
}) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const [isLoading, setIsLoading] = useState(false);
    const postTestFinish = async () => {
        setIsLoading(true);
        try {
            await axios.post(getApiRoute('test') + '/' + idx + '/finish', {
                attempt_id: user.attemptId,
                ...assembly,
            }, {
                headers: {
                    Authorization: 'Bearer ' +  user.accessToken || '',
                }
            });
            
            dispatch(fetchUser(user.accessToken));
            dispatch(setAttemptId(null));
            onSuccess && onSuccess();
        } catch (err) {
            if (err.response.status === 400) {
                onError && onError()
            }
        } finally {
            setIsLoading(false);
        }
    };

    return {
        postTestFinish,
        isLoading
    }
}