import { memo, useState } from "react"
import styled from "styled-components"

import EyeH from '@assets/svg/eye-h.svg?react';
import Eye from '@assets/svg/eye.svg?react';

const Span = styled.span`
    position: relative;

    svg {
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 32px;
        z-index: 10;
        transform: translateY(-50%);
    }
`

const StyledInput = styled.input`
    width: 100%;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    text-align: left;
    padding: 20px 30px;
    border-radius: 10px;
    background-color: var(--c-white);
    border: 0;
    outline: 0 !important;
    color: var(--c-black) !important;
    &:placeholder {
        color: #666;
    }
`
const Input = (props) => {
    const [isHide, setIsHide] = useState(true);
    const toggle = () => setIsHide(!isHide);
    return (
        <Span>
            <StyledInput type={isHide ? 'password' : 'text'} {...props} />
            {isHide ? <EyeH onClick={toggle} /> : <Eye onClick={toggle} />}
        </Span>
    );
};

export default memo(Input);