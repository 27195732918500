import { memo, useState } from "react";
import { useFormik } from "formik";
import classNames from "classnames";
import axios from "axios";

import Button from '@components/common/Button';
import Input from "@components/common/Input";
import { setUserEmail } from "@store/features";
import { useEmailField } from "@hooks";
import { getApiRoute } from "@config";

import styles from '../Forms.module.scss';
import { useDispatch } from "react-redux";

const validate = (values) => {
    const errors = {};
    if (!values.email) {
        errors.email = 'Это поле обязательно к заполнению';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Неверный адрес электронной почты';
    }
    return errors;
}

const SendEmail = ({ changeStage }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const formik = useFormik({
        initialValues: { email: '' },
        validate,
        async onSubmit(values) {
            setIsLoading(true);
            try {
                const isDev = import.meta.env.VITE_FRONT === 'develop';
                const data = {
                    email: values.email,
                }
                if (isDev) {
                    data.dev_mode = true;
                }
            
                
                const res = await axios.post(getApiRoute('auth.login'), {...data});
                dispatch(setUserEmail(res.data.response.user.email));
                changeStage();
            } catch(err) {
                const error = err.response.data.message;
                formik.setFieldError('email', error)
                formik.setFieldError('password', error)
            } finally {
                setIsLoading(false);
            }
        }
    });

    const [emailMask, changeEmail, fucusEmail] = useEmailField((value)=> {
        formik.setFieldValue('email', value)
    });

    const [
        emailError,  
        emailValue,  
    ] = [
        !formik.isValid && formik.errors.email,
        formik.values.email,
    ];
    
    return (
        <>
            <p className={styles.Forms_text}>
                Введите почту, привязанную к аккаунту, на которую мы вышлем ссылку для сброса пароля
            </p>
            <form onSubmit={formik.handleSubmit} className={styles.Forms_form}>
                <label className={classNames(styles.Forms_field, emailError && styles.is_error)}>
                    <Input
                        name="email"
                        placeholder="______ @aliexpress.ru"
                        value={emailMask}
                        onChange={changeEmail}
                        onFocus={fucusEmail}
                    />
                    {emailError && 
                        <span className={styles.Forms_error}>{emailError}</span>
                    }
                </label>

                <Button 
                    cssClass={styles.Forms_submit}
                    variant="red"
                    isLoading={isLoading}
                    disabled={
                        emailError || !emailValue?.length || isLoading
                    }
                >
                    Отправить
                </Button>
            </form>
        </>
    );
};

export default memo(SendEmail);