import './Home.scss';
import { useDispatch, useSelector } from 'react-redux';

import {
    setOpenAuth,
    setOpenTest, 
    setCompleteTest, 
    setOpenSharedCoin, 
    setOpenFear, 
    setOpenBasket,
    setOpenPhotoBooth
} from '@store/features';

import Column from '@components/common/Column';
import Header from '@components/Header/Header';
import FunExpress from '@components/FunExpress/FunExpress';

import AuthPopup  from '@components/Popup/AuthPopup/AuthPopup';
import Modal from '@components/Popup';
import PhotoBoothPopup from '@components/Popup/PhotoBoothPopup';
import Prognoz from '@components/Prognoz/Prognoz';
import Dashboard from '@components/Dashboard/Dashboard';
import Results from '@components/Results/Results';
import BringAFriend from '@components/BringAFriend';
import Tests from '@components/Tests/Tests';
import Games from '@components/Games';
import Merch from '@components/Merch';
import FearCompant from '@components/FearCompant';
import Fortune from '@components/Fortune';
import PhotoBooth from '@components/PhotoBooth';
import Faq from '@components/Faq';
import Footer from '@components/Footer';
import SharedCoin from '@components/Forms/SharedCoin';
import TestPopup from '@components/Popup/TestPopup/TestPopup';
import CompleteTest from '@components/Popup/TestPopup/CompleteTest';
import FearCompantPopup from '@components/Popup/FearCompantPopup';
import BasketPopup from '@components/Popup/BasketPopup';
import GamesModue from '../games';

const Home = () => {
    const { modals, user } = useSelector(state => state);
    const dispatch = useDispatch();

    const closeAuth = ()=> {
        dispatch(setOpenAuth({
            isOpenAuth: false,
            stage: 'register',
        }));
    }

    const closeTest = ()=> {
        if (modals.isTestStart && !modals.isTestFinish) return (
            dispatch(setCompleteTest({ isCompleteTest: true }))
        );
        dispatch(setOpenTest({
            isOpenTest: false,
            testType: null,
        }));
    }

    const closeTestWarn = ()=> {
        dispatch(setCompleteTest({ isCompleteTest: false }))
    }

    const closeSharedCoin = ()=> {
        dispatch(setOpenSharedCoin({ isOpenSharedCoin: false }))
    }

    const closeFear = ()=> {
        dispatch(setOpenFear({ isOpenFear: false }))
    }

    const closeBasket = ()=> {
        dispatch(setOpenBasket({ isOpenBasket: false }));
    }

    const closePhotoBooth = ()=> {
        dispatch(setOpenPhotoBooth({ isOpenPhotoBooth: false }));
    }

                        
    return (
        <div className="app">
            <Column align='center' className="app__wrapper">
                <Header />
                <FunExpress />
                
                {user.isLogin &&
                    <>
                        <Dashboard />
                        <Results />
                        <BringAFriend />
                        <Tests />
                        <Prognoz />
                        <Games />
                        <FearCompant />
                        <Merch />
                        <Fortune />
                        <PhotoBooth />
                        <Faq />
                        <Footer /> 

                        <GamesModue />
                    
                        <Modal
                            isAuth
                            isCam
                            isOpen={modals.isOpenTest}
                            onClose={closeTest}
                            content={<TestPopup />}
                        />
                        <Modal
                            isSmall
                            isOpen={modals.isCompleteTest}
                            onClose={closeTestWarn}
                            content={<CompleteTest />}
                        />

                        <Modal
                            isAuth
                            isOpen={modals.isOpenSharedCoin}
                            onClose={closeSharedCoin}
                            content={<SharedCoin />}
                        />
                        <Modal
                            isAuth
                            isCam
                            isOpen={modals.isOpenFear}
                            onClose={closeFear}
                            content={<FearCompantPopup />}
                        />
                        <Modal 
                            isRight
                            onClose={closeBasket}
                            isOpen={modals.isOpenBasket}
                            content={<BasketPopup />}
                        />
                        <Modal 
                            isAuth
                            isCam
                            onClose={closePhotoBooth}
                            isOpen={modals.isOpenPhotoBooth}
                            content={<PhotoBoothPopup />}
                        />
                    </>
                }
            </Column>

            <Modal 
                isAuth
                isOpen={modals.isOpenAuth}
                onClose={closeAuth}
                content={<AuthPopup />}
            />
        </div>
    );
}

export default Home;
