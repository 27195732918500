import { useState, useRef, useEffect } from "react"
import gsap from "gsap"
import "./game-memorium.scss"
import { shuffle } from "gsap/all"

export function GameMemorium({ onFind = () => {}, onFinish = () => {} }) {
  const OPTIONS_COUNT = 46
  const DISPLAYED_COUNT = 20

  const generateCards = () => {
    return shuffle(
      shuffle(Array.from({ length: OPTIONS_COUNT }, (_, i) => i))
        .slice(0, DISPLAYED_COUNT / 2)
        .reduce((tor, i) => [...tor, i, i], [])
    ).map(i => ({ value: i, visible: false }))
  }

  const [cards, setCards] = useState([])
  const [disabled, setDisabled] = useState(false)
  const lastValue = useRef(null)
  const cardsRef = useRef(null)

  useEffect(() => {
    setCards(generateCards())
  }, [])

  useEffect(() => {
    if (cards.filter(card => card.hidden).length === DISPLAYED_COUNT) {
      onFinish()
    }
  }, [cards, onFinish])

  const reset = async () => {
    const visibleCards = []
    const visibleRefs = []
    const flippers = []

    cards.forEach((card, i) => {
      if (card.visible) {
        visibleCards.push(card)
        visibleRefs.push(cardsRef.current?.childNodes[i])
        flippers.push(cardsRef.current?.childNodes[i].childNodes[0])
      }
    })

    await gsap
      .timeline()
      .set(
        visibleRefs,
        {
          zIndex: 9
        },
        0
      )
      .to(
        flippers,
        {
          scale: 1.2,
          rotateY: 90,
          duration: 0.25
        },
        0
      )
      .add(() => {
        setCards(
          cards.map(card => ({
            ...card,
            visible: visibleCards.includes(card) ? false : card.visible
          }))
        )
      }, 0.25)
      .to(
        flippers,
        {
          scale: 1,
          rotateY: 0,
          duration: 0.25
        },
        0.25
      )
      .set(
        visibleRefs,
        {
          zIndex: "auto"
        },
        0.5
      )
  }

  const showCard = i => {
    const _cards = [...cards]
    _cards[i].visible = true
    setCards(_cards)
  }

  const onCardClick = async i => {
    const card = cards[i]

    if (card.visible) return
    if (disabled) return

    setDisabled(true)

    const cardRef = cardsRef.current?.childNodes[i]
    const flipper = cardRef.childNodes[0]

    await gsap
      .timeline()
      .set(
        cardRef,
        {
          zIndex: 9
        },
        0
      )
      .to(
        flipper,
        {
          scale: 1.2,
          rotateY: 90,
          duration: 0.25
        },
        0
      )
      .add(() => {
        showCard(i)
      }, 0.25)
      .to(
        flipper,
        {
          scale: 1,
          rotateY: 0,
          duration: 0.25
        },
        0.25
      )
      .set(
        cardRef,
        {
          zIndex: "auto"
        },
        0.5
      )

    if (lastValue.current !== null && lastValue.current !== card.value) {
      lastValue.current = null
      gsap.delayedCall(0.5, async () => {
        await reset()
        setDisabled(false)
      })
      return
    }

    if (lastValue.current === null) {
      lastValue.current = card.value
      setDisabled(false)
    } else if (lastValue.current === card.value) {
      onFind()
      lastValue.current = null
      const _cards = [...cards]
      _cards.forEach(card => {
        card.hidden = card.hidden || card.visible
      })
      setCards(_cards)
      if (_cards.filter(card => card.hidden).length !== DISPLAYED_COUNT) {
        setDisabled(false)
      }
    }
  }

  return (
    <>
      <div className="game-memorium">
        <div className="game-memorium__cards" ref={cardsRef}>
          {cards.map((card, i) => (
            <div
              key={i}
              className={`
              game-memorium__card
              ${card.hidden ? "game-memorium__card_hidden" : ""}
            `.trim()}
              onClick={() => onCardClick(i)}
            >
              <div className="game-memorium__card-inner">
                {card.visible && (
                  <div
                    className="game-memorium__card-back"
                    style={{
                      backgroundImage: `url('/memorium/card${card.value +
                        1}.jpg')`
                    }}
                  ></div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
