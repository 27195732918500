import { useState } from "react";
const postFix = '@aliexpress.ru';
const regExp = /@aliexpress.ru/g;

export const useEmailField = (cb) => {
    const [value, setValue] = useState('');
    const changeEmail = (e) => {
        const value = e.target.value;
        const newVal = value.replace(regExp, '')+postFix;
        const position = newVal.indexOf('@');
        const actualVal = newVal.replace(newVal.slice(position), '')+postFix;
        setValue(actualVal);
        cb && cb(actualVal)
        setTimeout(() => {
            if (e.target) {
                e.target.setSelectionRange(position, position);
                e.target.focus();
            }
        }, 0);
    }

    const fucusEmail = (e) => {
        const position = e.target.value.indexOf('@');
        setTimeout(() => {
            if (e.target) {
                e.target.setSelectionRange(position, position);
                e.target.focus();
            }
        }, 0);
    }

    return [
        value,
        changeEmail,
        fucusEmail,
    ]
};