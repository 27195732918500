import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import cn from 'classnames';
import axios from 'axios';

import Column from '../common/Column';
import Row from '../common/Row';
import Button from '../common/Button';

import { useMounted } from '@hooks';
import { getApiRoute } from '@config';
import { priceFormatter } from '@utils';

import './Results.scss';
import hint from '@assets/img/results-hint.svg'
import topSales from '@assets/img/results-top.png'
import flag from '@assets/img/flag.png'
import scale from '@assets/img/range.png'
import scale2 from '@assets/img/range-m.png'
import scale3 from '@assets/img/range-t.png'
import classNames from 'classnames';

const StyledRange = styled.div`
	width: ${({ width }) => width};
	height: 100%;
	background: #FFE500;
	border-radius: 5px;
`

const TOP_CATEGORIES = 'topCategories'
const DAY_PRODUCT = 'dayProduct'

const DATE_START = Date.parse('11 Nov 2024 11:00:00 GMT+3');
const DATE_FINISH = Date.parse('18 Nov 2024 00:00:00 GMT+3');

const DATE_START_RES = Date.parse('12 Nov 2024 11:00:00 GMT+3');

function Results() {
	const [result, setResult] = useState(null);
	const user = useSelector(state => state.user);
	const [selectedChapter, setSelectedChapter] = useState(TOP_CATEGORIES);

	useMounted(() => {
		const getResult = async () => {
			try {
				const res = await axios.get(getApiRoute('results'), {
					headers: {
						Authorization: 'Bearer '+user.accessToken || '' 
					}
				});

				setResult(res.data.response)
			} catch (err) {
				console.log(err);
			}
		}
		getResult();
	});

	const getPercent = () => {
		const today = Date.now()
		if (today > DATE_FINISH) {
			return '100%'
		} else if (today < DATE_START) {
			return '0'
		}
		const range = DATE_FINISH - DATE_START
		const current = today - DATE_START
		const percent = current / range * 100
		return `${percent}%`
	};
	const categoriesClasses = cn(
		'results__select_btn',
		{ selected: selectedChapter === TOP_CATEGORIES }
	)
	const productClasses = cn(
		'results__select_btn',
		{ selected: selectedChapter === DAY_PRODUCT }
	);

	const isSoon = new Date().getTime() < DATE_START_RES;

	return (
		<Column className="results">
			<Row className='results__title'>
				<span>Результаты 11.11</span>
				<div className='hint'>
					<img
						className='hint__text'
						src={hint}
						alt='hint'
					/>
				</div>
			</Row>
			<Row className='results__select'>
				<button
					className={categoriesClasses}
					onClick={() => setSelectedChapter(TOP_CATEGORIES)}
				>
				Топ категорий
				</button>
				<button
					className={productClasses}
					onClick={() => setSelectedChapter(DAY_PRODUCT)}
				>
				Товар дня
				</button>
			</Row>
			
			<div className='content'>
				{isSoon ? 
					<div className="plug">
						<dir className="plug-title">
							Уже завтра здесь появятся результаты распродажи 11-11 по итогам сегодняшнего дня
						</dir>
					</div>
					:
					<>
						<div className={classNames('results__items', selectedChapter === TOP_CATEGORIES  && 'is_grid' )}>
							{selectedChapter === TOP_CATEGORIES && result?.categories.map(item => (
								<img
									className='categories__card'
									src={item.main_image}
									alt='item'
									key={item.id}
								/>
							))}

							{selectedChapter === DAY_PRODUCT && (
								<Row className='dayproduct' justyfy='center' align='flex-end'>
									<a href={result?.product_link} target='_blank' rel='noopener noreferrer'>
										<Button cssClass='dayproduct__btn'>Смотреть товар</Button>
									</a>
								</Row>
							)}
							<img src={topSales} alt='top' className='results__top' />
						</div>
						<div className='total'>
							<Column align='flex-start' justyfy='space-between' className='total__gmv'>
								<span className='total__title'>GMV</span>
								<span className='total__sum'>{priceFormatter(result?.gmv)} ₽</span>
							</Column>
							<Column align='flex-start' justyfy='space-between'  className='total__orders'>
								<span className='total__title'>Созданные заказы</span>
								<span className='total__sum'>{priceFormatter(result?.order_count)}</span>
							</Column>
							<Column align='flex-start' justyfy='space-between'  className='total__aov'>
								<span className='total__title'>AOV</span>
								<span className='total__sum'>{priceFormatter(result?.aov)} ₽</span>
							</Column>
						</div>
					</>
				}
			</div>
			<Column className='range'>
				<Row className='range__names' justyfy='space-between'>
					<span className='range__names_target'>СТАРТ</span>
					<span className='range__names_target'>ЦЕЛЬ<img className='range__names_flag' alt='flag' src={flag} /></span>
				</Row>
				<Row className='range__track' justyfy='flex-start'>
					<StyledRange width={getPercent()} />
				</Row>
				<picture>
					<source media="(max-width: 480px)" srcSet={scale2} />
					<source media="(max-width: 1024px)" srcSet={scale3} />
					<img className='range__scale' alt='range' src={scale} />
				</picture>
			</Column>
		</Column>
	);
}

export default Results;
