export const weightedRandom = (items) => {
    let total = 0
    for (let i = 0; i < items.length; i++) {
        total += items[i].weight
    }
    const threshold = Math.random() * total
    total = 0
    for (let i = 0; i < items.length - 1; i++) {
        total += items[i].weight
        if (total >= threshold) return items[i]
    }
    return items[items.length - 1]
}

export const random = (items) => {
    return items[Math.floor(Math.random() * items.length)]
}