import { memo } from 'react';

import banner from '@assets/img/footer/banner.png';

import Btn1 from '@assets/svg/soc-btn.svg?react';
import Btn2 from '@assets/svg/soc-btn-1.svg?react';
import Logo from '@assets/svg/logo.svg?react';
import doc1 from '@assets/docs/termsofuse.pdf';
import doc2 from '@assets/docs/politics.pdf';
import doc3 from '@assets/docs/conditions.pdf';

import styles from './Footer.module.scss';

const Footer = () => {
    return (
        <footer className={styles.block}>
            <div className={styles.bg}>
                <picture>
                    <img src={banner} alt="" width={1440} height={485} />
                </picture>
            </div>

            <div className={styles.bottom}>
                <div className={styles.logo}>
                    <Logo />
                </div>
                <div className={styles.links}>
                    <a href={doc1} target="_blank" rel="noopener noreferrer">Пользовательское соглашение</a>
                    <a href={doc2} target="_blank" rel="noopener noreferrer">Политика конфиденциальности</a>
                    <a href={doc3} target="_blank" rel="noopener noreferrer">Условия акции</a>
                </div>

                <div className={styles.btns}>
                    <a href="https://t.me/AliExpressofficial_channel" target="_blank" rel="noopener noreferrer">
                        <Btn1 />
                    </a>
                    <a href="https://mm.ae-cis.tech/ae-cis/channels/aer_news" target="_blank" rel="noopener noreferrer">
                        <Btn2 />
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default memo(Footer);
