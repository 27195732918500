import styled from "styled-components"

const StyledRow = styled.div`
    display: flex;
    align-items: ${({ align }) => align};
    justify-content: ${({ justyfy }) => justyfy};
`

const Row = ({
    children,
    className = '',
    align = 'center',
    justyfy = 'space-between',
    onClick,
    innerRef,
    styles,
}) => {
    return (
        <StyledRow
            ref={innerRef}
            align={align}
            justyfy={justyfy}
            className={className}
            onClick={onClick}
            style={styles}
        >
            {children}
        </StyledRow>
    )
}

export default Row