import { memo, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import CoinShared from './modules/CoinShared';
import Button from '@components/common/Button';
import { setOpenSharedCoin } from '@store/features';

import styles from './Forms.module.scss';

const SharedCoin = () => {
    const dispatch = useDispatch();
    const [stage, setStage] = useState('send');

    const changeStage = useCallback((stage)=> {
        return ()=> {
            setStage(stage)
        }
    }, []);

    const closed = () => {
        dispatch(
            setOpenSharedCoin({ isOpenSharedCoin: false })
        );
    };
    
    const success = (
        <div className={styles.Forms_coin_success}>
            <p>Поздравляем, твои Ali Coin <br/> отправлены!</p>
            <Button variant="red" onClick={closed}>На главную</Button>
        </div>
    );

    return (
        <div className={classNames(styles.Forms, styles.Forms_sec, stage === 'success' && styles.is_success)}>
            <div className={styles.Forms_title2}>Поделиться Ali Coin</div>
            {stage === 'send' ? <CoinShared changeStage={changeStage('success')}/> : success}
        </div>
    );
};

export default memo(SharedCoin);