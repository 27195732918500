import { GameContainer, GameIntro, GameLives, GamePanel, GameResult } from '../components'
import { GameDarts } from '../components/GameDarts/GameDarts'
import DartsCover from '../assets/darts-cover.svg'
import { useGame } from '../hooks/useGame'
import { pluralize } from '../utils/pluralize'

export function Darts({
    state = { attempts: 0 },
  onClose = () => {},
  onFinish = () => { },
  onStart = () => { }
}) {
  const game = useGame({
    rounds: 3,
    lives: 3,
    attempts: 3,
    durationRound: [60, 45, 30],
    scoreRound: [20, 30, 50],
    onStart: onStart,
    onGameover: onFinish,
  }, state)

  const close = () => {
    game.gameover(true)
    onClose()
  }

  return (
    <>
      <GameContainer
        onClose={close}
        onInitClose={() => !game.state.attempts ? close() : game.pause()}
        onCancelClose={() => game.resume()}
        lightCloseBtn={game.status === 'game'}
        smallCloseBtn={game.status === 'game'}
      >
        {game.status === 'intro' && (
          <GameIntro
            title="Дартс"
            desc="Соберись, сейчас понадобится твоя ловкость! Кидай дротик точно в цель, чтобы набрать максимум очков, а мы превратим их в Ali Coin’ы"
            image={DartsCover}
            features={[
              pluralize(game.config.rounds, ['раунд', 'раунда', 'раундов'], true),
              `${pluralize(game.config.attempts, ['жизнь', 'жизни', 'жизней'], true)} на игру`,
              `${pluralize(game.config.attempts, ['попытка', 'попытки', 'попыток'], true)} в день`
            ]}
            maxAttempts={game.config.attempts}
            attempts={game.state.attempts}
            onStart={() => game.start()}
          />
        )}
        {game.status === 'game' && (
          <>
            <GameDarts
              speed={game.state.round + 1}
              onHit={() => game.score()}
              onMiss={() => game.lose()}
              onGameover={() => game.gameover()}
            />
            <GamePanel
              score={game.state.score}
              timeleft={game.state.timeleft}
              round={game.state.round}
              leftContent={
                <GameLives
                  lives={game.state.lives}
                  maxLives={game.config.lives}
                />}
            />
          </>
        )}
        {game.status === 'result' && (
          <GameResult
            final={game.isFinished}
            title={game.isFinished ? 'Игра окончена!' : 'Ты отлично справляешься!'}
            desc={game.isFinished ? undefined : 'Осталось совсем чуть-чуть до выигрыша'}
            score={game.state.score}
            image={DartsCover}
            maxAttempts={game.config.attempts}
            attempts={game.state.attempts}
            btnText={game.isFinished ? 'Играть повторно' : 'Дальше'}
            onStart={() => game.start()}
            onClose={onClose}
          />
        )}
      </GameContainer>
    </>
  )
}
