import './game-lives.scss'

export function GameLives({ lives, maxLives }) {
  return (
    <>
      Жизни
      <div className="game-lives">
        {Array.from({ length: maxLives }, (_, i) => (
          <div
            key={i}
            className={`
              game-lives__life
              ${i < lives ?  'game-lives__life_active' : ''}
            `.trim()}
          ></div>
        ))}
      </div>
    </>
  )
}
