import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import Select from '@components/common/Select';
import { useLocalStorage } from '@hooks';
import { setProduct } from '@store/features';
import { priceFormatter } from '@utils';

import Basket from '@assets/svg/basket.svg?react';
import Clock from '@assets/svg/clock.svg?react';
import Checkmark from '@assets/svg/checkmark.svg?react';
import Coin from '@assets/svg/coin.svg?react';
import styles from './ProductCard.module.scss';

const renderSizes = (data, type) => {
    return data?.filter(item => !item.coming_soon || (type === 'ru' ? item.ru_balance : type === 'global' ? item.abroad_balance: item.online_balance)!== 0).map(item => ({
        id: item.id,
        value: item.size_id,
        label: item.size_name,
    }));
}

const ProductCard = ({ data, currentType, cssClass }) => {
    const [openSelect, setOpenSelect] = useState(false);
    const [selectSize, setSelectSize] = useState(null);
    const [isError, setIsError] = useState(false);
    const localProducts = useSelector(state => state.products.localProducts);
    const dispatch = useDispatch();
    const { setValue } = useLocalStorage('_ali_basket');


    
    const addBasket = ()=> {
        if (data.size_is_required && !selectSize) {
            setIsError(true);
            return setOpenSelect(true);
        }
        setBasketLocal();
    };

    const onSelect = (option) => {
        setSelectSize(option);
        setOpenSelect(false);
        setIsError(false);

        if (isError) {
            setBasketLocal(option.value);
        }
    };

    const toggleSelect = () => {
        setOpenSelect(false);
        setIsError(false);
    };


    function setBasketLocal (size) {
        const newData = { ...data, size_id: size || selectSize?.value || 1, type: currentType };

        if (!localProducts || localProducts?.length === 0) {
            setValue([newData]);
            dispatch(setProduct([newData]));
        } else if (localProducts.length > 0) {
            const clone = [...localProducts];
            clone.push(newData)         
            setValue(clone);
            dispatch(setProduct(clone));
        }
    }

    const basket = (
        <div className={styles.status}>
            <button className={styles.basket} onClick={addBasket}>
                <Basket />
            </button>
        </div>
    );

    const inBasket = (
        <div className={styles.status}>
            <Checkmark />
            У вас в корзине
        </div>
    );

    const soon = (
        <div className={styles.status}>
            <Clock />
            Появится позже
        </div>
    );

    const cn = classNames(styles.card, cssClass, data.status === 'waiting' && styles.is_disabled);
    const isInBasket = localProducts ? localProducts?.findIndex(item => item.id === data.id) !== -1 : false;
    const isSoon = data.balance.every(item => item.coming_soon) || data.balance.every(item => (currentType === 'ru' ? item.ru_balance : currentType === 'global' ? item.abroad_balance : item.online_balance) === 0);

    return (
        <div className={cn}>
            <div className={styles.box}>
                {isSoon ? soon : isInBasket ? inBasket : basket}
                <div className={styles.image}>
                    <picture>
                        <img src={data.main_image} alt=""  />
                    </picture>
                </div>

                {isError &&
                    <div className={styles.error}>
                        Выбери размер
                    </div>
                }
            </div>
            <div className={styles.bottom}>
                <div className={styles.name}>{data.name}</div>
                {(data.size_is_required && !isSoon) &&
                    <Select 
                        placeholder="Размер" 
                        openSelect={openSelect}
                        options={renderSizes(data.balance, currentType)}
                        onSelect={onSelect}
                        toggleSelect={toggleSelect}
                    />
                }
                <div className={styles.price}>
                    <Coin />
                    {priceFormatter(data.price)}
                </div>
            </div>
        </div>
    );
};

export default memo(ProductCard);