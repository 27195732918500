import { useState, useRef, useLayoutEffect, useEffect } from "react"
import gsap from "gsap"
import { Btn, GameBg } from ".."
import "./game-darts.scss"

export function GameDarts({
  speed = 1,
  onHit = () => {},
  onMiss = () => {},
  onGameover = () => {}
}) {
  const COLS = 5
  const ROWS = 3

  const [disabled, setDisabled] = useState(false)

  const start = { x: 34, y: 34 }
  const gap = { x: 80, y: 80 }
  const offset = { x: -25, y: -25 }
  const radius = 34

  const [targets, setTargets] = useState(
    Array.from({ length: COLS * ROWS }, (_, i) => ({
      x: start.x + gap.x * (i % COLS),
      y: start.y + gap.y * Math.floor(i / COLS),
      status: "idle"
    }))
  )

  const indicatorRef = useRef(null)
  const arrowRef = useRef(null)

  const indicatorFrom = { x: 0, y: -12 }
  const indicatorTo = { x: 382, y: -12 }
  const win = { x: 151, width: 78 }

  const indicator = useRef({
    x: indicatorFrom.x + (indicatorTo.x - indicatorFrom.x) / 2,
    y: indicatorFrom.y
  })

  const arrow = useRef({
    x: start.x,
    y: start.y
  })

  const currentTargetIndex = useRef(0)

  const tl = useRef(null)

  const updateIndicator = () => {
    if (indicatorRef.current)
      indicatorRef.current.setAttribute(
        "transform",
        `translate(${indicator.current.x} ${indicator.current.y})`
      )
  }

  const updateArrow = () => {
    if (arrowRef.current)
      arrowRef.current.setAttribute(
        "transform",
        `translate(${arrow.current.x + offset.x} ${arrow.current.y + offset.y})`
      )
  }

  const arrowCache = useRef({ x: 0, y: 0 })
  const useArrowCache = useRef(false)
  const randomDistance = () =>
    radius * 1 * (0.2 + Math.random() * 0.8) * Math.sign(Math.random() - 0.5)

  const initTimeline = () => {
    tl.current = gsap
      .timeline({
        repeat: -1,
        onUpdate: () => {
          updateArrow()
          updateIndicator()
        }
      })
      .to(
        arrow.current,
        {
          repeat: 3,
          yoyo: true,
          repeatRefresh: true,
          ease: "power1.inOut",
          onRepeat: () => {
            useArrowCache.current = false
          },
          x: () => {
            if (!useArrowCache.current) arrowCache.current.x = randomDistance()
            return targets[currentTargetIndex.current].x + arrowCache.current.x
          },
          y: () => {
            if (!useArrowCache.current) arrowCache.current.y = randomDistance()
            return targets[currentTargetIndex.current].y + arrowCache.current.y
          },
          duration: 1
        },
        0
      )

      .fromTo(
        indicator.current,
        {
          x: indicatorFrom.x + (indicatorTo.x - indicatorFrom.x) / 2
        },
        {
          x: indicatorTo.x,
          ease: "linear",
          duration: 1,
          yoyo: true,
          repeat: 1
        },
        0
      )

      .fromTo(
        indicator.current,
        {
          x: indicatorFrom.x + (indicatorTo.x - indicatorFrom.x) / 2
        },
        {
          x: indicatorFrom.x,
          ease: "linear",
          duration: 1,
          yoyo: true,
          repeat: 1
        },
        2
      )

      .timeScale(speed)
  }

  useLayoutEffect(() => {
    initTimeline()
    return () => {
      tl.current?.kill()
      tl.current = null
    }
  }, [])

  const shoot = async () => {
    if (disabled) return

    tl.current?.pause()
    setDisabled(true)

    const _targets = [...targets]
    if (
      indicator.current.x >= win.x &&
      indicator.current.x <= win.x + win.width
    ) {
      _targets[currentTargetIndex.current].status = "hit"
      onHit()
    } else {
      _targets[currentTargetIndex.current].status = "miss"
      onMiss()
    }
    setTargets(_targets)
    currentTargetIndex.current++

    if (currentTargetIndex.current > targets.length - 1) {
      return
    }

    useArrowCache.current = true
    await gsap.to(arrow.current, {
      x:
        targets[currentTargetIndex.current].x -
        targets[currentTargetIndex.current - 1].x +
        arrow.current.x,
      y:
        targets[currentTargetIndex.current].y -
        targets[currentTargetIndex.current - 1].y +
        arrow.current.y,
      ease: "back.out",
      onUpdate: () => {
        updateArrow()
      }
    })

    arrow.current.x = targets[currentTargetIndex.current].x
    arrow.current.y = targets[currentTargetIndex.current].y

    setDisabled(false)

    const time = tl.current?.time() ?? 0
    tl.current?.invalidate().play(time)
  }

  useEffect(() => {
    if (currentTargetIndex.current > targets.length - 1) {
      onGameover()
    }
  }, [targets, onGameover])

  return (
    <>
      <div className="game-darts">
        <GameBg />

        <div className="game-darts__container">
          <svg
            className="game-darts__field"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 390 230"
          >
            <defs>
              <linearGradient
                id="arrow1"
                x1="4.5"
                x2="21"
                y1="11"
                y2="23.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F684F6" />
                <stop offset="1" stopColor="#FFA0FF" />
              </linearGradient>
              <linearGradient
                id="arrow2"
                x1="378.697"
                x2="-43.9489"
                y1="15.4917"
                y2="2.8687"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FFABFF" />
                <stop offset="1" stopColor="#FFABFF" stopOpacity="0.1" />
              </linearGradient>

              <symbol id="target" style={{ overflow: "visible" }}>
                <circle cx="0" cy="0" r="34" fill="#FE2722" />
                <circle cx="0" cy="0" r="26.5" fill="#F6F8FF" />
                <circle cx="0" cy="0" r="18" fill="#FE2722" />
                <circle cx="0" cy="0" r="9" fill="#F6F8FF" />
              </symbol>

              <symbol id="lose" style={{ overflow: "visible" }}>
                <g transform="translate(-34 -34)" style={{ opacity: 0.2 }}>
                  <path
                    fill="#FE2722"
                    d="M33.92 67.84a33.92 33.92 0 1 0 0-67.84 33.92 33.92 0 0 0 0 67.84Z"
                  />
                  <path
                    fill="#FF5F5B"
                    d="M33.92 60.3a26.38 26.38 0 1 0 0-52.76 26.38 26.38 0 0 0 0 52.76Z"
                  />
                  <path
                    fill="#FE2722"
                    d="M33.93 51.7a17.77 17.77 0 1 0 0-35.54 17.77 17.77 0 0 0 0 35.54Z"
                  />
                  <path
                    fill="#FF5F5B"
                    d="M33.92 43.07a9.15 9.15 0 1 0 0-18.3 9.15 9.15 0 0 0 0 18.3Z"
                  />
                  <path
                    fill="#fff"
                    d="M41.69.89c1.69.41 2.58.7 4.1 1.24l-5.01 15.4c.5.18.75.29 1.22.47l-3.5 8M9.5 57.5c-.72-.78-2.1-2.22-2.5-3l7-2.5-1-1.5 8-4.5-6.5 5 1.5 2-6.5 4.5ZM63 51.5c1.5-2.5 1.5-3 2-4L55.5 43 45 40l10.5 4.5 7.5 7Z"
                  />
                </g>
              </symbol>

              <symbol id="win" style={{ overflow: "visible" }}>
                <g transform="translate(-34 -34)" style={{ opacity: 0.3 }}>
                  <circle
                    cx="33.9231"
                    cy="34.0769"
                    r="33.9231"
                    fill="#FFA0FF"
                  />
                  <circle
                    cx="33.9232"
                    cy="34.0769"
                    r="26.3846"
                    fill="#F684F6"
                  />
                  <circle cx="33.923" cy="34.077" r="17.7692" fill="#FFA0FF" />
                  <circle
                    cx="33.9231"
                    cy="34.0769"
                    r="9.15385"
                    fill="#FFA0FF"
                  />
                  <path
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="4"
                    d="M41.18 28.5 31.1 38.58 26.5 34"
                  />
                </g>
              </symbol>
            </defs>

            {targets.map(({ x, y, status }, i) => (
              <g key={i} className="target" transform={`translate(${x} ${y})`}>
                {status === "idle" && <use xlinkHref="#target" />}
                {status === "miss" && <use xlinkHref="#lose" />}
                {status === "hit" && <use xlinkHref="#win" />}
              </g>
            ))}

            <g className="arrow" ref={arrowRef}>
              <circle
                cx="25"
                cy="25"
                r="25"
                fill="url(#arrow1)"
                opacity="0.8"
              />
              <g
                className={`arrow__body ${
                  currentTargetIndex.current % 5 < 3 ? "arrow__body_flip" : ""
                }`.trim()}
              >
                <path fill="url(#arrow2)" d="M379 0v50H1V0h378Z" />
                <path fill="#F684F6" d="M380 17H177l-29 9 29 7h203V17Z" />
                <path
                  fill="#9455FF"
                  d="M452 0h-78l-25 26 25 24h78l-23-24 23-26Z"
                />
              </g>
            </g>
          </svg>

          <svg
            className="game-darts__indicator"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 392 18"
          >
            <path
              stroke="#FF5F5B"
              strokeLinecap="round"
              strokeWidth="10"
              d="M5 9h382"
            />
            <path stroke="#00AD30" strokeWidth="10" d="M156 9h78" />
            <rect
              width="76"
              height="16"
              x="157"
              y="1"
              stroke="url(#a)"
              strokeLinecap="round"
              strokeOpacity="0.5"
              strokeWidth="2"
              rx="2"
            />

            <g className="indicator" ref={indicatorRef}>
              <path
                fill="#000"
                d="M6.3 11.4a2 2 0 0 0 3.4 0L15 3A2 2 0 0 0 13.3 0H2.7A2 2 0 0 0 1 3l5.3 8.4ZM9.5 15.8a1.5 1.5 0 0 0-3 0v10a1.5 1.5 0 0 0 3 0v-10Z"
              />
            </g>

            <defs>
              <linearGradient
                id="a"
                x1="233"
                x2="157"
                y1="9"
                y2="9"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#00AD30" />
                <stop offset="0.15" stopColor="#fff" stopOpacity="0" />
                <stop offset="0.85" stopColor="#fff" stopOpacity="0" />
                <stop offset="1" stopColor="#00AD30" />
              </linearGradient>
            </defs>
          </svg>

          <Btn
            block={true}
            disabled={disabled}
            onPointerDown={() => !disabled && shoot()}
          >
            Жми
          </Btn>
        </div>
      </div>
    </>
  )
}
