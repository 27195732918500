import { GameContainer, GameResult } from '../components'
import { GameRandom } from '../components/GameRandom'
import RandomizeCover from '../assets/randomizer-cover.svg'
import { useGame } from '../hooks/useGame'
import { useEffect } from 'react'

export function Random({
  state = { attempts: 0 },
  onClose = () => {},
  onFinish = () => { },
  onStart = () => { }
}) {
  console.log(state)
  const game = useGame({
    rounds: 1,
    lives: 1,
    attempts: 1,
    durationRound: [5],
    scoreRound: [0],
    onStart: onStart,
    onGameover: onFinish,
  }, state)

  const close = () => {
    game.gameover(true)
    onClose()
  }

  useEffect(() => {
    game.start()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GameContainer
        onClose={() => close()}
        onInitClose={() => !game.state.attempts ? close() : game.pause()}
        onCancelClose={() => game.resume()}
        lightCloseBtn={game.status === 'game'}
        fullscreen={false}
        closable={game.status === 'result' || !game.state.attempts}
      >
    
        {game.status === 'game' && (
          <GameRandom onScore={game.score} />
        )}
        {game.status === 'result' && (
          <GameResult  
            final={game.isFinished}
            title="Результат"
            desc={"Уже завтра ты сможешь испытать удачу снова"}
            score={game.state.score}
            image={RandomizeCover}
            maxAttempts={game.config.attempts}
            attempts={game.state.attempts}
            btnText="Отлично!"
            onStart={onClose}
          />
        )}
      </GameContainer>
    </>
  )
}
