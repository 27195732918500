import { memo } from "react"
import classNames from "classnames";

import Review from "./Review";
import Loader from "../common/Loader";
import Affordable from "./Affordable";
import Express from "./Express";

import { useTesting } from "./hooks";

import Button from '@assets/svg/circle-arrow.svg?react';
import './TestForm.scss'

const activeClasses = (selectAnswer, item) => {
    return (
        (selectAnswer && selectAnswer === item.id && !item.isCorrect) ? 'is_fail' :
        (selectAnswer && selectAnswer !== item.id && item.isCorrect) ? 'is_correct' :
        (selectAnswer && selectAnswer === item.id && item.isCorrect) ? 'is_correct' : ''
    );
}

const TestForm = ({ data, type, onFinish }) => {
    const {
        isLoading,
        total,
        current,
        timeLeft,
        nextStage,
        selectAnswer,
        isStop,
        isComplete,
        currentQuestion,
        onSelect,
    } = useTesting({
        type,
        data,
        onFinish(correctAnswers, total) {
            onFinish(correctAnswers, total);
        }
    });

    console.log(type);

    const isNext = (!!selectAnswer || isStop) && !isComplete;
    const isLast = (!!selectAnswer || isStop) && isComplete;

    return (
        <div className="TestForm">
            {isLoading && 
                <div className="TestForm-loader">
                    <Loader />
                </div>
            }
            <div className="TestForm-wrap">
                <div className="TestForm-head">
                    <div className="TestForm-count">Вопрос {current}/{total}</div>
                    <div className="TestForm-timer">00:{timeLeft}</div>
                    <button disabled={isComplete} onClick={nextStage} className={classNames("TestForm-next", isNext && 'is_show')}>
                        Следующий&nbsp;<span>вопрос</span>
                        <Button />
                    </button>
                </div>

                <div className="TestForm-flex">
                    <div className="TestForm-review">
                        {type === 'review' ? <Review text={currentQuestion?.question} date={currentQuestion?.reviewDate} /> :
                            type === 'skyeng' ? <Affordable text={currentQuestion?.question} image={currentQuestion?.image} isNext={isNext || isLast}  /> :
                            type === 'aliexpress' ? <Express text={currentQuestion?.question} desc={currentQuestion?.desc} isNext={isNext || isLast} /> :
                            null
                        }
                    </div>
                    <div className="TestForm-quest">
                        <div className="TestForm-title">Варианты ответа</div>
                        <div className={"TestForm-cards"}>
                            {currentQuestion?.variants?.map((item, i) => (
                                <div 
                                    className={
                                        classNames(
                                            'TestForm-card', 
                                            (!!selectAnswer || (isStop && !selectAnswer)) && 'is_select', 
                                            (isStop && !selectAnswer && item.isCorrect) ? 'is_correct' 
                                            : activeClasses(selectAnswer?.id, item)
                                        )} 
                                    key={item.id}
                                    onClick={onSelect(item)}
                                >
                                    <i>0{i+1}</i>
                                    <span>{item.label}</span>
                                    {((item.image && selectAnswer) || (item.image && isStop && !selectAnswer)) &&
                                        <picture>
                                            <img src={item.image} alt="" width={64} height={64} />
                                        </picture>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(TestForm);