import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import Title from '@components/common/Title';
import Text from '@components/common/Text';
import Button from '@components/common/Button';
import PhotoCard from './PhotoCard';
import PhotoDay from './PhotoDay';
import { setOpenPhotoBooth } from '@store/features';
import { usePhotoDay } from './usePhotoDay';

import booth from '@assets/img/photo-booth/booth.png';
import booth2 from '@assets/img/photo-booth/booth-m.png';
import vs from '@assets/img/photo-booth/vs.png';
import Lock from '@assets/svg/lock.svg?react';
import styles from './PhotoBooth.module.scss';

const isAvailable = () => {
    const current = new Date().getTime();
    const start = new Date('2024-11-12T00:00:00').getTime();
    return current >= start;
}

const PhotoBooth = () => {
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const { photos, photoDay, isLoadingDay, isSend, selectId, selectPhoto } = usePhotoDay(user.accessToken);
    
    const openPhotoBooth = ()=> {
        dispatch(setOpenPhotoBooth({ isOpenPhotoBooth: true }));
    }
    const available = isAvailable();

    console.log(photos)

    return (
        <div className={styles.block}>
            <Title cssClass={styles.title}>Фотобудка</Title>
            <Text cssClass={styles.text}>
                Сделай классное селфи или фотку с коллегами на память!
                Заходи в фотобудку, создавай шедевры и участвуй
                в ежедневном челлендже «Фото дня»
            </Text>

            <div className={styles.box}>
                <div className={styles.box_img}>
                    <picture>
                        <source media="(max-width: 480px)" srcSet={booth2} />
                        <img src={booth} alt="" width={874} height={506} />
                    </picture>
                    <Button cssClass={styles.snap} onClick={openPhotoBooth}>Сделать фото</Button>
                </div>
                <PhotoDay data={photoDay} isLoading={isLoadingDay} />
            </div>

            <div className={classNames(styles.top, (available && photos) && styles.isShow)}>
                <Title cssClass={styles.title}>Выбери лучшее фото дня!</Title>
                <div className={styles.vs}>
                    <picture>
                        <img src={vs} alt="" width={297} height={278} />
                    </picture>
                </div>
                {!available || !photos ?
                    <div className={styles.lock}>
                        <Lock />
                        <span>
                            Уже завтра здесь начнётся битва за лайки, <br/> а пока загрузи свою фотографию!
                        </span>
                    </div> : 
                    <div className={styles.top_flex}>
                        {photos?.map(item => (
                            <PhotoCard
                                key={item.id}
                                data={item}
                                isLoading={isSend}
                                isSelect={selectId === item.id}
                                onSelected={selectPhoto(item.id)}
                            />
                        ))}
                    </div>
                }
            </div>
        </div>
    );
};

export default memo(PhotoBooth);
