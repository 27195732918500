class PhotoSegment {
    constructor() {
        this.selfieSegmentation$ = null
        this.faceMesh$ = null
        this.camera$ = null
    }

    static stop() {
        this.camera$.stop();
    }

    useFace(onResults) {
        this.faceMesh$ = new window.FaceMesh({
            locateFile: file => `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`,
        });

        this.faceMesh$.setOptions({
            maxNumFaces: 1,
            // refineLandmarks: true,
            selfieMode: true,
            minDetectionConfidence: 0.5,
            minTrackingConfidence: 0.5,
        });

        this.faceMesh$.onResults(onResults);
        return this.faceMesh$;
    }

    run(onResults) {
        this.selfieSegmentation$ = new window.SelfieSegmentation({
            locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`,
        });
        this.selfieSegmentation$.setOptions({
            modelSelection: 1,
            selfieMode: true,
            minDetectionConfidence: 0.5,
            minTrackingConfidence: 0.5
        });
        this.selfieSegmentation$.onResults(onResults);

        return this.selfieSegmentation$;
    }
}

export default PhotoSegment;