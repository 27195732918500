import classNames from 'classnames';
import { memo } from 'react';
const Express = ({ text, desc, isNext }) => {
    return (
        <div className="Test-Express">
            <div className="Test-Express-title">
                {text}
            </div>
            <div className={classNames("Test-Express-desc", isNext && 'Test-Express-desc_show ')}>
                {isNext && desc}
            </div>
        </div>
    )
};

export default memo(Express);