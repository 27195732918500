import { memo } from 'react';
import { useSelector } from 'react-redux';

import Title from '@components/common/Title';

import bg from '@assets/img/games/game-bg.png';
import logo1 from '@assets/img/games/game-1.png';
import logo2 from '@assets/img/games/game-2.png';
import logo3 from '@assets/img/games/game-3.png';

import './Games.scss';
import Card from './Card';

const periods = [
    {
        releaseDate: '2024-09-30T21:00:00.000Z',
        text: 'Проверь свою меткость – попробуй выбить все мишени и заработай Ali Coins на мерч',
        disable: null,
        logo: logo1,
        type: 'darts',
        startDate: 11,
        startAt: '2024-11-11T00:00:00',
        finishAt: '2024-11-25T15:35:00'
    },
    {
        releaseDate: '2024-10-14T21:00:00.000Z',
        text: 'Потренируй память — открывай карточки и ищи пары! За каждую пару накинем Ali Coins',
        disable: 'Игра откроется 15 ноября. <br/>Не пропусти!',
        logo: logo2,
        type: 'memorium',
        startDate: 15,
        startAt: '2024-11-15T00:00:00',
        finishAt: '2024-11-25T00:00:00'
    },
    {
        releaseDate: '2024-10-18T21:00:00.000Z',
        text: 'Лучшая игра расслабления! Попробуй отыскать все слова, связанные с AliExpress, в этих филвордах',
        disable: 'Игра откроется 15 ноября.<br/> Не пропусти!',
        logo: logo3,
        type: 'aliWord',
        startDate: 19,
        startAt: '2024-11-19T00:00:00',
        finishAt: '2024-11-25T00:00:00'
    }
]

const isAvailableRange = (s, f) => {
    const date = new Date().getTime();
    const start = new Date(s).getTime();
    const finish = new Date(f).getTime();

    return [
        date > start,
        date > finish
    ];
}

const Games = () => {
    const { attempts } = useSelector(state => state.games);

    return (
        <div className="Games">
            <Title cssClass="Games-title">Игры</Title>
            <div className="Games-wrap">
                <div className="Games-bg">
                    <picture>
                        <img src={bg} alt="" width={1360} height={827} />
                    </picture>
                </div>

                <div className="Games-content">
                    {periods.map(item => {
                        const [isStart, isFinish] = isAvailableRange(item.startAt, item.finishAt);
                        return (
                            <Card 
                                key={item.releaseDate}
                                logo={item.logo}
                                text={item.text}
                                isStart={isStart}
                                isFinish={isFinish}
                                startDate={item.startDate}
                                isDisabled={attempts[item.type] === 0}
                                type={item.type}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    );
};

export default memo(Games);