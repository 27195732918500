import { useEffect, useState, useRef } from "react";
import { useInterval } from "./useInterval";

export function useGame(
  config,
  initialState,
) {
  
  const _initialState = useRef({
    timeleft: 0,
    round: 0,
    score: 0,
    lives: config.lives,
    ...initialState
  })

  _initialState.current.timeleft = config.durationRound[_initialState.current.round]

  const [status, setStatus] = useState('intro')
  const [paused, setPaused] = useState(true)
  const [state, setState] = useState(_initialState.current)
  const [playing, setPlaying] = useState(false)

  useEffect(() => {
    setState((state) => ({
      ...state,
      attempts: initialState.attempts,
    }))
  }, [initialState])

  const score = (add) => setState({
    ...state,
    score: state.score + (add ?? config.scoreRound[state.round])
  }) 

  const start = () => {
    if (
      state.round === config.rounds - 1
      && state.attempts === 0
    ) return false

    const round = playing ? state.round + 1 : 0
    const _state = {
      ...state,
      timeleft: config.durationRound[round],
      round,
    }

    if (!playing) {
      if (state.attempts === 0) return false
      setPlaying(true)
      _state.score = 0
      _state.round = 0
      _state.lives = config.lives
      config.onStart(_state)
    }

    setState(_state)
    setStatus('game')

    resume()
    return true
  }

  const gameover = (force = false) => {
    if (!playing) return

    pause()
    
    if (force || state.round === config.rounds - 1) {
      setPlaying(false)
      if (force) setState({
        ...state,
        round: config.rounds - 1
      })
      config.onGameover(state)
    } else {
      if (config.onRoundOver) config.onRoundOver(state)
    }
  
    setStatus('result')
  }

  const lose = () => {
    setState({
      ...state,
      lives: state.lives - 1
    })

    if (state.lives === 1) {
      gameover(true)
    }
  }

  useInterval(() => {
    if (!paused) {
      if (state.timeleft === 0) {
        gameover()
        return
      }
      setState({
        ...state,
        timeleft: state.timeleft - 1
      })
    }
  }, 1000)

  const resume = () => {
    setPaused(false)
  }

  const pause = () => {
    setPaused(true)
  }

  const isFinished = !playing && state.round === config.rounds - 1

  return {
    config,
    state,
    status,
    score,
    start,
    gameover,
    resume,
    pause,
    lose,
    isFinished
  }
}
