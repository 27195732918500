import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLocalStorage, useMounted } from '@hooks';
import { setProduct, setOpenBasket } from '@store/features';

import BasketIcon from '@assets/svg/basket.svg?react';
import styles from './Basket.module.scss';

const Basket = ({ isNotAuth }) => {
    const { storedValue } = useLocalStorage('_ali_basket');
    const localProducts = useSelector(state => state.products.localProducts);
    const dispatch = useDispatch();

    useMounted(()=> {
        dispatch(setProduct(storedValue));
    });
    
    const openBasket = () => {
        if (isNotAuth) return;
        dispatch(setOpenBasket({ isOpenBasket: true }));
    };

    const count = localProducts?.length || null

    return (
        <button className={styles.Basket} onClick={openBasket}>
            {count && <i>{count}</i>}
            <BasketIcon />
            <span>Корзина</span>
        </button>
    );
};

export default memo(Basket);