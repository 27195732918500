import { useRef, useState } from 'react';

export const useTogglePopup = (isAvailable) => {
    const timer = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const onToggle = () => {
        clearTimeout(timer.current);
        if (isAvailable) {
            setIsOpen(!isOpen);
        }
    }

    const onEnter = () => {
        clearTimeout(timer.current);
        if (isAvailable) {
            setIsOpen(true);
        }
    }

    const onLeave = () => {
        timer.current = setTimeout(()=> {
            clearTimeout(timer.current);
            setIsOpen(false);
        }, 100);
    }

    return {
        toggle: isOpen,
        onLeave,
        onEnter,
        onToggle,
    }
};