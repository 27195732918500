import { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SendEmail from './modules/SendEmail';
import SendCode from './modules/SendCode';
import CreatePassword from './modules/CreatePassword';
import { setOpenAuth } from '@store/features';

import styles from './Forms.module.scss';

export const ResetPass = () => {
    const [stage, setStage] = useState('email');
    const dispatch = useDispatch();

    useEffect(()=> {
        if (stage === 'success') {
            dispatch(setOpenAuth({
                isOpenAuth: false,
                stage: null
            }));
        }
    }, [stage, dispatch]);
    const changeStage = useCallback((stage)=> {
        return ()=> {
            setStage(stage)
        }
    }, []);
    return (
        <div className={styles.Forms}>
            <div className={styles.Forms_title}>Изменить пароль</div>
            {stage === 'email' ? <SendEmail changeStage={changeStage('code')} /> : 
                stage === 'code' ? <SendCode changeStage={changeStage('password')} /> : 
                stage === 'password' ? <CreatePassword changeStage={changeStage('success')} /> : null
            }
        </div>
    );
};