import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import Loader from '@components/common/Loader';
import { setUserDestroy, setDestroyProduct } from '@store/features';
import { useLocalStorage, useTogglePopup } from '@hooks';
import { getApiRoute } from '@config';

import User from '@assets/svg/user.svg?react';
import Arrow from '@assets/svg/arrow.svg?react';

import styles from './Profile.module.scss';

const Profile = ({ isNotAuth }) => {
    const user = useSelector(state => state.user);
    const [isLoading, setIsLoading] = useState(false);
    const { hardRemove: removeToken } = useLocalStorage('_ali_access_token');
    const { hardRemove: removeBasket } = useLocalStorage('_ali_basket');
    const { hardRemove: removePromocode } = useLocalStorage('_is_open_promo');
    const { toggle, onEnter, onLeave, onToggle } = useTogglePopup(user.isLogin);

    const dispatch = useDispatch(); 
    const logOut = async () => {
        if (isLoading) return;
        setIsLoading(true);
        try {
            await axios.get(getApiRoute('auth.logout'), {
                headers: {
                    Authorization: 'Bearer '+user?.accessToken
                }
            })
            dispatch(setUserDestroy())
            dispatch(setDestroyProduct())
            removeToken();
            removeBasket();
            removePromocode();
        } catch (err) {
            console.log(err)
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div
            className={styles.block}
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
        >
            <div className={styles.user}>
                {isNotAuth ?
                    <button className={styles.account}>
                        <User />
                    </button> : 
                    <button onClick={onToggle} className={styles.account}>
                        <User />
                    </button>
                }

                {toggle &&
                    <div className={styles.profile}>
                        {isLoading && 
                            <div className={styles.loader}>
                                <Loader />
                            </div>
                        }
                        <strong>{user.profile?.name} {user.profile?.surname}</strong>
                        <span>{user.profile?.email}</span>
                        <div className={styles.bottom}>
                            <button onClick={logOut}>
                                Выйти
                                <Arrow />
                            </button>
                        </div>
                    </div>
                } 
            </div>
        </div>
    );
};

export default memo(Profile);
