import { useEffect, useState, useRef } from "react"
// @ts-expect-error Untyped lib
import { Wordfind } from "./wordfind.js"
import "./wordfind.scss"

export function WordfindGame({
  words = [],
  orientations = ["horizontal", "vertical"],
  forceOrientation,
  onFind = () => {},
  onComplete = () => {}
}) {
  const options = {
    allowedMissingWords: true,
    maxGridGrowth: 100,
    fillBlanks: true,
    allowExtraBlanks: false,
    maxAttempts: 100,
    preferOverlap: true,
    orientations,
    forceOrientation
  }

  const [puzzle, setPuzzle] = useState([])
  const solution = useRef([])
  const possibleSolutions = useRef([])

  useEffect(() => {
    const wf = Wordfind()
    let [puzzle, sol] = wf.newPuzzle(words, options)

    // Make sure orientation is forced, reroll if not
    if (forceOrientation) {
      // @ts-expect-error Untyped lib
      while (!sol.find(({ orientation }) => orientation === forceOrientation)) {
        ;[puzzle, sol] = wf.newPuzzle(words, options)
      }
    }

    setPuzzle(puzzle)
    solution.current = sol
  }, [])

  const [selected, setSelected] = useState(new Set())
  const [found, setFound] = useState(new Set())
  const [foundWords, setFoundWords] = useState(new Set())

  const firstSlot = useRef(null)
  const word = useRef("")
  // const orientation = useRef<string | null>(null)
  const complete = foundWords.size === words.length

  const getSlotCoordsByIndex = index => ({
    x: index % puzzle.length,
    y: Math.floor(index / puzzle.length)
  })

  const selectSlot = (index, text) => {
    if (complete) return
    if (firstSlot.current === null) return
    if (firstSlot.current === index) return
    if (possibleSolutions.current.length === 0) return

    const selectedSlots = [...selected]
    const lastSlot = selectedSlots[selectedSlots.length - 1]
    let backTo = null

    if (index === lastSlot) return

    // Check if we hit previously selected slots
    for (let i = 0, len = selectedSlots.length; i < len; i++) {
      if (selectedSlots[i] === index) {
        backTo = i + 1
        break
      }
    }

    // Update word if backed up
    if (backTo !== null) {
      while (backTo < selectedSlots.length) {
        selectedSlots.splice(backTo, 1)
        word.current = word.current.slice(0, word.current.length - 1)
      }
      setSelected(new Set([...selectedSlots, index]))
    }

    const valid = possibleSolutions.current.find(sol =>
      [...selectedSlots, index].every((slotIndex, i) => {
        const { x, y } = getSlotCoordsByIndex(slotIndex)
        return sol.slots[i] && sol.slots[i].x === x && sol.slots[i].y === y
      })
    )

    if (valid) {
      selectedSlots.push(index)
      word.current += text
      setSelected(new Set([...selectedSlots, index]))
    }
  }

  const startSelection = (index, text) => {
    if (complete) return
    firstSlot.current = index

    const { x, y } = getSlotCoordsByIndex(index)
    possibleSolutions.current = solution.current.filter(
      s => s.slots[0].x === x && s.slots[0].y === y
    )
    setSelected(new Set([index]))
    word.current = text
  }

  const endSelection = () => {
    if (words.includes(word.current)) {
      setFound(new Set([...found, ...selected]))
      const nextFoundWords = [...foundWords, word.current]
      setFoundWords(new Set(nextFoundWords))
      onFind(word.current)
    }
    cancelSelection()
  }

  useEffect(() => {
    if (foundWords.size === words.length) onComplete()
  }, [foundWords, onComplete, words])

  const cancelSelection = () => {
    setSelected(new Set())
    firstSlot.current = null
    possibleSolutions.current = []
    word.current = ""
  }

  const onTouchMove = function(e) {
    const xPos = e.touches[0].clientX
    const yPos = e.touches[0].clientY
    const targetElement = document.elementFromPoint(xPos, yPos)
    if (targetElement !== null) {
      const index = targetElement.getAttribute("data-index")
      const text = targetElement.textContent
      if (index && text) {
        selectSlot(+index, text)
      }
    }
  }

  useEffect(() => {
    document.body.style.overflow = "hidden"
    document.body.addEventListener("pointerup", cancelSelection)
    return () => {
      document.body.style.overflow = ""
      document.body.removeEventListener("pointerup", cancelSelection)
    }
  }, [])

  return (
    <>
      <div
        className={`
        wordfind
        ${complete ? "wordfind_complete" : ""}
      `.trim()}
      >
        {puzzle.map((row, y) => (
          <div className="wordfind__row" key={y}>
            {row.map((slot, x) => (
              <div
                key={x}
                data-index={y * row.length + x}
                className={`
                  wordfind__cell
                  ${
                    selected.has(y * row.length + x)
                      ? "wordfind__cell_selected"
                      : ""
                  } 
                  ${found.has(y * row.length + x) ? "wordfind__cell_found" : ""}
                `.trim()}
                onPointerDown={() => startSelection(y * row.length + x, slot)}
                onPointerMove={() => selectSlot(y * row.length + x, slot)}
                onTouchMove={e => onTouchMove(e)}
                onTouchEnd={() => endSelection()}
                onPointerUp={() => endSelection()}
              >
                <span>{slot}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  )
}
