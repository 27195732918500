import { useDispatch } from 'react-redux';

import Row from '@components/common/Row';
import Button from '@components/common/Button';
import { setOpenAuth } from '@store/features';

import './RegistrationPopup.scss'

const RegistrationPopup = () => {
    const dispatch = useDispatch();

    const handleRegister = ()=> {
        dispatch(setOpenAuth({
            isOpenAuth: true,
            stage: 'register',
        }));
    }

    const handleLogin = ()=> {
        dispatch(setOpenAuth({
            isOpenAuth: true,
            stage: 'login',
        }));
    }

    return (
        <>
            <span className='registration__title'>Привет!</span>
            <span className='registration__article'>
                Добро пожаловать в торгово-увлекательный<br/>
                парк Fun Express! Заполни форму<br/>
                регистрации, чтобы получить свой билет<br/>
                в мир приключений и ярких эмоций!<br />
            </span>
            <Row className='registration__signin'>
                <Button cssClass='registration__signin_btn' onClick={handleRegister}>
                    Зарегистрироваться
                </Button>
            </Row>
            <Row className='registration__login'>
                <button className='registration__login_btn' onClick={handleLogin} />
            </Row>
        </>
    );
}

export default RegistrationPopup
