import classNames from "classnames";
import './TestBanner.scss';
import Title from "../common/Title";
import Text from "../common/Text";
import Button from "../common/Button";
import CoinLabel from "./CoinLabel";

export const ReviewResult = ({ result, total, onClose }) => {
    const cn = classNames('TestBanner', 
        result <= 3 ? 'TestBanner_rev_result_1' : 'TestBanner_rev_result_2',
    );

    const text = result <= 3 ? 'Чаще делай покупки на AliExpress и изучай отзывы, — тогда ты точно сможешь распознавать товары даже по самому запутанному описанию! А пока лови цветочек для настроения' :
        (result >= 4 && result <= 7) ? 'Вот это да, ты неплохо разбираешься в AliExpress и наших пользователях! До учёной степени по пониманию отзывов осталось совсем чуть-чуть – так держать! Лови 250 Ali Coin' : 
        'Признавайся, все эти отзывы – твоих рук дело? Ты отлично справляешься! Можешь гордиться званием профессионального отзовика AliExpress! За такой крутой результат вручаем тебе 500 Ali Coin';

    return (
        <div className={cn}>
            <div className={classNames('TestBanner-content', 'TestBanner-content_rev_result')}>
                <Title cssClass="TestBanner-title">
                    Результат: {result}/{total}
                </Title>
                <Text cssClass="TestBanner-text">
                    {text}
                </Text>
                <CoinLabel result={result} />
                
                <Button variant="red" cssClass="TestBanner-btn" onClick={onClose}>
                    {result <=3 ? 'Ну ладно' : 'Отлично!'}
                </Button>
            </div>
        </div>
    );
};