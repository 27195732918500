import Login from './modules/Login';
import styles from './Forms.module.scss';

export const LoginForm = () => {
    return (
        <div className={styles.Forms}>
            <div className={styles.Forms_title}>Вход</div>
            <Login />
        </div>
    );
};