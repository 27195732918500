import { memo } from "react"
import styled from "styled-components"

const StyledInput = styled.input`
    width: 100%;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    text-align: left;
    padding: 20px 30px;
    border-radius: 10px;
    background-color: var(--c-white);
    border: 0;
    outline: 0 !important;
    color: var(--c-black) !important;
    &:placeholder {
        color: #666;
    }
`
const Input = (props) => {
    return <StyledInput {...props} />
}

export default memo(Input);