import Column from '../common/Column'
import Row from '../common/Row'

import './Dashboard.scss'
import footPlate from '@assets/img/plate-foot.png'
import articlePlate from '@assets/img/plate-article.png'

const Dashboard = () => {
    return (
        <Column className='dashboard'>
            <>
                <Row className='dashboard__title' justyfy='center'>Погнали распродажить!</Row>
                <Row className='dashboard__content'>
                    <div className='banner__foot'>
                        <img src={footPlate} className='banner__foot_plate' alt='foot' />
                    </div>
                    <div className='banner__article'>
                        <span> В этом году <i>11.11</i> пройдёт для нас в <i>11-й</i> раз, – <br/> магия чисел, не иначе! А у нас, как и всегда, грандиозные планы и цели на эту распродажу!</span>
                        <span>Отслеживать успехи и следить за нашими показателями можно в этом дашборде. Аналитику <br/> за предыдущий день обновляем ежедневно в <br/> <i>11:11</i> — не пропускай! </span>
                        <img src={articlePlate} className='banner__article_plate' alt='article' />
                    </div>
                </Row>
            </>
        </Column>
    )
}

export default Dashboard
