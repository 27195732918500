import { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import classNames from "classnames";
import axios from "axios";

import Button from '@components/common/Button';
import Input from "@components/common/Input";

import { setUserToken, setUserProfile } from "@store/features";
import { useCountDown, useLocalStorage } from "@hooks";
import { getApiRoute } from "@config";

import Arrow from '@assets/svg/arrow.svg?react';

import styles from '../Forms.module.scss';

const validate = (values) => {
    const errors = {};
    if (!values.code) {
        errors.code = 'Это поле обязательно к заполнению';
    }
    return errors;
}

const SendCode = ({
    changeStage,
    isRegister,
}) => {
    const { setValue: setAccsessToken } = useLocalStorage('_ali_access_token');
    const { startTimer, timerDisplay, isTicking } = useCountDown('_resend_code');

    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingResend, setIsLoadingResend] = useState(false);

    const formik = useFormik({
        initialValues: { code: '' },
        validate,
        async onSubmit(values) {
            setIsLoading(true);
            try {
                const data = {
                    email: user.userEmail || user.profile.email,
                    code: values.code,
                }
                const res = await axios.post(getApiRoute('auth.login'), {...data});
                dispatch(setUserToken({
                    accessToken: res.data.response.auth.access_token,
                }));
                setAccsessToken(res.data.response.auth);
                dispatch(setUserProfile({
                    profile: res.data.response.user,
                }));
                changeStage();
            } catch(err) {
                formik.setFieldError('code', err.response.data.message);
            } finally {
                setIsLoading(false);
            }
        }
    });

    const resendCode = async () => {
        setIsLoadingResend(true);
        try {
            const isDev = import.meta.env.DEV;
            const data = { email:  user.userEmail || user.profile.email };

            if (isDev) {
                data.dev_mode = true;
            }

            const res = await axios.post(getApiRoute('auth.login'), {...data});
            dispatch(setUserProfile({
                profile: res.data.response.user,
            }));
            startTimer();
        } catch(err) {
            console.log(err)
        } finally {
            setIsLoadingResend(false);
        }
    };

    const onChange = (e) => {
        const newValue = e.target.value;
        if ((!isNaN(Number(newValue)) || newValue === '') && newValue.length < 7) {
            formik.setFieldValue('code', newValue);
        }
    };

    const [
        codeError,  
        codeValue,  
    ] = [
        !formik.isValid && formik.errors.code,
        formik.values.code,
    ];
    
    return (
        <>
            <p className={styles.Forms_text}>
                {isRegister ? 
                    <>Отправили код подтверждения регистрации на вашу почту (не забудьте проверить папку «Спам»)</> :
                    <>Отправили код подтверждения <br/> на вашу почту</>
                }
            </p>
            <form onSubmit={formik.handleSubmit} className={styles.Forms_form}>
                <label className={classNames(styles.Forms_field, codeError && styles.is_error)}>
                    <Input
                        name="code"
                        placeholder="Код подтверждения"
                        value={codeValue}
                        onChange={onChange}
                    />
                    {codeError && 
                        <span className={styles.Forms_error}>{codeError}</span>
                    }
                </label>

                <Button 
                    cssClass={styles.Forms_submit}
                    variant="red"
                    isLoading={isLoading}
                    disabled={
                        codeError || !codeValue.length || isLoading
                    }
                >
                    Отправить
                </Button>
            </form>
            {isTicking ? 
                <button className={styles.Forms_btn}>
                    Запросить код повторно через {timerDisplay} сек
                </button> :
                <button className={styles.Forms_btn} disabled={isLoadingResend} onClick={resendCode}>
                    Отправить код повторно
                    <Arrow />
                </button>
            }
        </>
    );
};

export default memo(SendCode);