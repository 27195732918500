import { memo, useState, useCallback } from 'react';
import { InputMask } from '@react-input/mask';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Select, { components } from 'react-select';
import classNames from 'classnames';
import axios from 'axios';

import Input from '@components/common/Input';
import Radio from '@components/common/Radio';
import Button from '@components/common/Button';
import { isDisableFormik, isErrorFormik, priceFormatter } from '@utils';
import { getApiRoute } from '@config';

import Coin from '@assets/svg/coin.svg?react';
import ArrowDown from '@assets/svg/arrow-down.svg?react';
import styles from '../Forms.module.scss';


const addresses = [
    { id: 1, value: 'Москва, Пресненская наб., 10, 11 этаж', label: 'Москва, Пресненская наб., 10, 11 этаж' },
    { id: 2, value: 'Нижний Новгород, ул. Нартова, 6', label: 'Нижний Новгород, ул. Нартова, 6' },
]

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <ArrowDown />
        </components.DropdownIndicator>
    );
};

const validate = (isOffice) => (values) => {
    const errors = {};

    if (!values.fio) {
        errors.fio = 'Это поле обязательно к заполнению';
    }

    if (!values.phone || values.phone?.replace(/[\D]+/g, '').length < 11) {
        errors.phone = 'Это поле обязательно к заполнению';
    }

    if (!values.street) {
        errors.street = 'Это поле обязательно к заполнению';
    }

    if (!values.house) {
        errors.house = 'Это поле обязательно к заполнению';
    }

    if (!values.apartment) {
        errors.apartment = 'Это поле обязательно к заполнению';
    }
    if (!values.postcode) {
        errors.postcode = 'Это поле обязательно к заполнению';
    }

    return isOffice ? null : errors;
}

const isDisabled = (formik, isOffice) => {
    if (isOffice) return false;
    return isDisableFormik(formik)
}

const BasketRu = ({ sum, orderFinish }) => {
    const { accessToken, profile } = useSelector(state => state.user);
    const { orders } = useSelector(state => state.products);
    const [isLoading, setIsLoading] = useState(false);
    const [isOffice, setIsOffice] = useState(false);
    const [error, setError] = useState(null);

    const formik = useFormik({
        initialValues: {
            fullName: profile.name + ' ' + profile.surname,
            fio: "",
            email: profile.email,
            phone: '',
            street: '',
            house: '',
            apartment: '',
            postcode: '',
            office_address: 'Москва, Пресненская наб., 10, 11 этаж',
        },
        validate: validate(isOffice),

        async onSubmit(values) {
            const { fullName, ...rest } = values;
            setError(null);
            const newData = {
                ...rest,
                fio: isOffice ? fullName : rest.fio,
                products: orders,
            }

            setIsLoading(true);

            try {
                const res = await axios.post(getApiRoute('sale'), newData, {
                    headers: {
                        Authorization: 'Bearer ' + accessToken
                    }
                });
                orderFinish(
                    isOffice,
                    res.data.response.products,
                    orders,
                    newData.office_address,
                );
            } catch (err) {
                setError(err.response.data.message);
            } finally {
                setIsLoading(false);
            }
        }
    });

    const changeType = useCallback(() => {
        setIsOffice(!isOffice)
    }, [isOffice]);

    const selectAddress = ({ value }) => {
        formik.setFieldValue('office_address', value);
    };

    const onChange = (e) => {
        const newValue = e.target.value;
        if ((!isNaN(Number(newValue)) || newValue === '')) {
            formik.setFieldValue(e.target.name, newValue);
        }
    };

    const { values, errors } = formik;

    const officeAddress = (
        <div className={styles.Forms_address}>
            <h5>Адрес офиса</h5>
            <Select
                defaultValue={addresses[0]}
                options={addresses}
                classNamePrefix="basket-addresses"
                isSearchable={false}
                components={{ DropdownIndicator }}
                onChange={selectAddress}
                isDisabled={isLoading}
            />
        </div>
    );

    const recipient = (
        <>
            <label className={classNames(styles.Forms_field, isErrorFormik(formik, 'fio') && styles.is_error)}>
                <Input
                    name="fio"
                    placeholder="ФИО получателя"
                    value={values.fio}
                    onChange={formik.handleChange}
                    disabled={isLoading}
                />
                {isErrorFormik(formik, 'fio') &&
                    <span className={styles.Forms_error}>{errors.fio}</span>
                }
            </label>

            <div className={styles.Forms_row}>
                <label className={classNames(styles.Forms_field, isErrorFormik(formik, 'street') && styles.is_error)}>
                    <Input
                        name="street"
                        placeholder="Город, улица"
                        value={values.street}
                        onChange={formik.handleChange}
                        disabled={isLoading}
                    />
                    {isErrorFormik(formik, 'street') &&
                        <span className={styles.Forms_error}>{errors.street}</span>
                    }
                </label>
                <div className={classNames(styles.Forms_row, styles.Forms_row_sec)}>
                    <label className={classNames(styles.Forms_field, isErrorFormik(formik, 'house') && styles.is_error)}>
                        <Input
                            name="house"
                            placeholder="Дом"
                            value={values.house}
                            onChange={formik.handleChange}
                            disabled={isLoading}
                        />
                        {isErrorFormik(formik, 'house') &&
                            <span className={styles.Forms_error}>{errors.house}</span>
                        }
                    </label>

                    <label className={classNames(styles.Forms_field, isErrorFormik(formik, 'apartment') && styles.is_error)}>
                        <Input
                            name="apartment"
                            placeholder="Квартира"
                            value={values.apartment}
                            onChange={formik.handleChange}
                            disabled={isLoading}
                        />
                        {isErrorFormik(formik, 'apartment') &&
                            <span className={styles.Forms_error}>{errors.apartment}</span>
                        }
                    </label>
                </div>
            </div>

            <div className={styles.Forms_row}>
                <label className={classNames(styles.Forms_field, isErrorFormik(formik, 'postcode') && styles.is_error)}>
                    <Input
                        name="postcode"
                        placeholder="Индекс"
                        value={values.postcode}
                        onChange={onChange}
                        disabled={isLoading}
                    />
                    {isErrorFormik(formik, 'postcode') &&
                        <span className={styles.Forms_error}>{errors.postcode}</span>
                    }
                </label>
                <label className={classNames(styles.Forms_field, isErrorFormik(formik, 'phone') && styles.is_error)}>
                    <InputMask
                        name="phone"
                        mask="+7 (___) ___-__-__"
                        replacement={{ _: /\d/ }}
                        placeholder="Контактный телефон"
                        value={values.phone}
                        onChange={formik.handleChange}
                        disabled={isLoading}
                    />
                    {isErrorFormik(formik, 'phone') &&
                        <span className={styles.Forms_error}>{errors.phone}</span>
                    }
                </label>
            </div>
        </>
    );

    return (
        <form
            className={styles.Forms_form}
            onSubmit={formik.handleSubmit}
        >
            <div className={styles.Forms_row}>
                <label className={styles.Forms_field}>
                    <Input
                        name="fullName"
                        placeholder="Имя"
                        value={values.fullName}
                        readOnly
                        disabled
                    />
                </label>
                <label className={styles.Forms_field}>
                    <Input
                        name="email"
                        placeholder="E-mail"
                        readOnly
                        value={values.email}
                        disabled
                    />
                </label>
            </div>

            <div className={styles.Forms_radios}>
                <Radio checked={isOffice} onChange={changeType} disabled={isLoading}>
                    Забрать заказ из офиса Aliexpress
                </Radio>
                <Radio checked={!isOffice} onChange={changeType} disabled={isLoading}>
                    Доставка курьером по России <span>(кроме Москвы, МО и Нижнего Новгорода)</span>
                </Radio>
            </div>

            {isOffice ? officeAddress : recipient}


            <div className={styles.Forms_order_submit}>
                <div className={styles.Forms_error} style={{ marginBottom: 14 }}>{error}</div>
                <Button
                    variant="red"
                    isLoading={isLoading}
                    disabled={isDisabled(formik, isOffice)}
                >
                    <Coin />
                    <i>{priceFormatter(sum)}</i>
                    Оформить заказ
                </Button>
            </div>
        </form>
    )
}
export default memo(BasketRu);