import { createSlice } from '@reduxjs/toolkit'
import { cartState, deliveryState, orderTypeState } from '../../constants/cart'

const items = [
    {
        name: 'Умные часы Amazfit GTS',
        id: 1,
        isOnline: true,
        price: 480
    },
    {
        name: 'Умные часы Amazfit GTS',
        id: 2,
        isOnline: true,
        price: 480
    },
    {
        name: 'Умные часы Amazfit GTS',
        id: 3,
        isOnline: false,
        price: 480
    },
    {
        name: 'Умные часы Amazfit GTS',
        id: 4,
        isOnline: false,
        price: 480
    },
    {
        name: 'Умные часы Amazfit GTS',
        id: 5,
        isOnline: true,
        price: 480
    },
    {
        name: 'Умные часы Amazfit GTS',
        id: 6,
        isOnline: true,
        price: 480
    },
    {
        name: 'Умные часы Amazfit GTS',
        id: 7,
        isOnline: false,
        price: 480
    },
    {
        name: 'Умные часы Amazfit GTS',
        id: 8,
        isOnline: false,
        price: 480
    }
]

const getOrderType = (hasOnline, hasOffline) => {
    if (hasOnline && !hasOffline) {
        return orderTypeState.ONLINE
    } else if (!hasOnline && hasOffline) {
        return orderTypeState.OFFLINE
    } else {
        return orderTypeState.MIXED
    }
}

const initialState = {
    items,
    selectedItems: [],
    delivery: deliveryState.DELIVERY,
    orderType: null,
    currentState: cartState.CART
}

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addSelectedItem: (state, action) => {
            const item = action.payload
            const updatedSelected = [...state.selectedItems, item]
            const hasOnline = updatedSelected.some(({ isOnline }) => isOnline)
            const hasOffline = updatedSelected.some(({ isOnline }) => !isOnline)
            state.selectedItems = updatedSelected
            state.orderType = getOrderType(hasOnline, hasOffline)
        },
        removeSelectedItem: (state, action) => {
            const item = action.payload
            const updatedSelected = state.selectedItems.filter(({ id }) => id !== item.id)
            const hasOnline = updatedSelected.some(({ isOnline }) => isOnline)
            const hasOffline = updatedSelected.some(({ isOnline }) => !isOnline)
            state.selectedItems = updatedSelected
            state.orderType = getOrderType(hasOnline, hasOffline)
        },
        removeItem: (state, action) => {
            const item = action.payload
            const filteredItems = state.items.filter(({ id }) => id !== item.id)
            state.items = filteredItems
        },
        setDelivery: (state, action) => {
            state.delivery = action.payload
        },
        setCurrentState: (state, action) => {
            state.currentState = action.payload
        },
    },
})

export const { addSelectedItem, removeSelectedItem, removeItem, setDelivery, setCurrentState } = cartSlice.actions

export default cartSlice.reducer
