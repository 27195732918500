import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { setGameType } from '@store/features'; 

import Lock from '@assets/svg/lock.svg?react';
import Arrow from '@assets/svg/arrow.svg?react';

const Card = ({
    logo,
    isStart,
    isFinish,
    startDate,
    text,
    isDisabled,
    type,
}) => {
    const dispatch = useDispatch();
    const onOpen = () => {
        if (!isDisabled) {
            dispatch(setGameType(type));
        }
    }
    const showContent = (
        <div className="Games-show">
            <span dangerouslySetInnerHTML={{__html: text}} />
            <button disabled={isDisabled} onClick={onOpen}>
                Играть
                <Arrow />
            </button>
        </div>
    );
    const beforeStart = (
        <div className="Games-closed">
            <Lock />
            <span>
                Игра откроется {startDate} ноября. <br/> Не пропусти!
            </span>
        </div>
    );
    const afterFinish = (
        <div className="Games-closed">
            <Lock />
            <span>
                Игра окончена.
            </span>
        </div>
    );
    return (
        <div className="Games-card">
            <div className="Games-card-logo">
                <picture>
                    <img src={logo} alt="" width={207} height={122} />
                </picture>
            </div>

            <div className="Games-card-content">
                {!isStart ?
                    beforeStart :
                    (isStart && isFinish) ? afterFinish :
                    showContent
                }
            </div>
        </div>
    );
}

export default memo(Card);