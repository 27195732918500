import { useEffect, useState, useCallback} from 'react';
import axios from 'axios';

import { useMounted } from '@hooks';
import { getApiRoute } from '@config';

export const usePhotoDay = (token) => {
    const [photos, setPhotos] = useState(null);
    const [photoDay, setPhotoDay] = useState(null);
    const [selectId, setSelectId] = useState(null);
    const [isSend, setIsSend] = useState(false);
    const [isLoadingDay, setIsLoadingDay] = useState(false);

    const getPhotos = useCallback(async () => {
        try {
            const res = await axios.get(getApiRoute('photo'), {
                headers: {
                    Authorization: 'Bearer ' + token || '',
                }
            });
            if (res.data.response.length > 0) {
                setPhotos(res.data.response);    
            } else {
                setPhotos(null);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setSelectId(null);
            const time = setTimeout(() => {
                clearTimeout(time)
                setIsSend(false);
            }, 300);
        }
    }, [token])

    useMounted(() => {
        if (token) {
            getPhotos();
            getPhotosDay();
        }
    }, [])

    useEffect(() => {
        const sendPhoto = async () => {
            setIsSend(true);
            try {
                await axios.post(getApiRoute('photo') + `/${selectId}/vote`, {}, {
                    headers: {
                        Authorization: 'Bearer ' + token || '',
                    }
                });
                getPhotos();
            } catch (err) {
                console.log(err);
                const time = setTimeout(() => {
                    clearTimeout(time)
                    setIsSend(false);
                }, 300);
            }
        }

        if (selectId) {
            sendPhoto();
        }
    }, [selectId, getPhotos, token]);

    async function getPhotosDay() {
        setIsLoadingDay(true);
        try {
            const res = await axios.get(getApiRoute('photo')+'/day', {
                headers: {
                    Authorization: 'Bearer ' + token || '',
                }
            });
            if (!Array.isArray(res.data.response.legnth)) {
                setPhotoDay(res.data.response);    
            }
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoadingDay(false);
        }
    }

    const selectPhoto = useCallback((id) => {
        return () => {
            setSelectId(id);
        }
    }, []);

    return {
        photos,
        photoDay,
        isSend,
        selectId,
        isLoadingDay,
        selectPhoto
    };
};