import { memo } from "react"
import './ScrollBar.scss';
import classNames from "classnames";

const ScrollBar = ({ children, cssClass }) => {
    return (
        <div className={classNames('ScrollBar', cssClass)}>{children}</div>
    )
}

export default memo(ScrollBar);