import { useDispatch, useSelector } from "react-redux";

import { useState } from "react";
import axios from "axios";
import { getApiRoute } from "@config";
import { setAttemptId, fetchUser } from '@store/features';

export const useTestStart = ({
    idx,
    onSuccess,
    onError,
}) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const [isLoading, setIsLoading] = useState(false);
    const getTestStart = async () => {
        setIsLoading(true);
        try {
            const test = await axios.post(getApiRoute('test') + '/'+idx+'/start', {}, {
                headers: {
                    Authorization: 'Bearer ' + user.accessToken || '',
                }
            });
            dispatch(setAttemptId(test.data.response.id));
            dispatch(fetchUser(user.accessToken));
            
            onSuccess && onSuccess();
        } catch (err) {
            if (err.response.status === 400) {
                onError && onError()
            }
        } finally {
            setIsLoading(false);
        }
    };

    return {
        getTestStart,
        isLoading
    }
}
