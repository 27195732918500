import classNames from 'classnames';
import { memo } from 'react';
const Affordable = ({ text, image, isNext }) => {
    return (
        <div className="Test-Affordable">
            <div className="Test-Affordable-title" dangerouslySetInnerHTML={{__html: text}} />
            <div className={classNames('Test-Affordable-image', isNext && 'Test-Affordable-image_show')}>
                <picture>
                    {isNext && <img src={image} alt="" width={200} height={200} />}
                </picture>
            </div>
        </div>
    )
};

export default memo(Affordable);