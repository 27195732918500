import classNames from "classnames";
import './TestBanner.scss';
import Title from "../common/Title";
import Text from "../common/Text";
import Button from "../common/Button";
import CoinLabelSecond from "./CoinLabelSecond";

export const AffordableResult = ({ result, total }) => {
    const cn = classNames('TestBanner', 'TestBanner_affor_result');
    const text = result === 0 ? 'Oops, кажется кто-то либо совсем не понимает карточки товаров, либо совсем не знает английский язык… В первом случае рекомендуем больше шопиться на AliExpress, а во втором – посетить разговорные клубы от SkyEng' :
        result <= 3 ? 'Не идеально, но not bad – лови 100 Ali Coin! Кажется, в тебе есть перспектива стать fluent-speaker’ом. Кстати, развить разговорный навык отлично помогают разговорные клубы! ' :
        (result >= 4 && result <= 7) ? 'Ого, твой результат – очень даже impressive! Ты явно можешь не только поздороваться на английском, но и спросить как дела! Лови 500 Ali Coin за старания! А ещё рекомендуем тебе не забрасывать английский и попрактиковаться в разговорных клубах с носителями' : 
        'Ставим тебе Hero за знание английского языка и начисляем 1000 Ali Coin! Тебя явно не смогут запутать даже самые запутанные описания товаров. Не забывай чаще практиковаться, чтобы продолжать блистать. Делать это можно, не выходя из дома – просто запишись на разговорные клубы';

    const subtitle = result === 0 ? 'У нас как раз есть подарок – <br/> <span>3 месяца Speaking Clubs <br/>от SkyEng бесплатно!</span>' : 
        (result > 0 && result <=3) ? 'А SkyEng как раз подготовил для тебя подарок – целых <br/> <span>3 месяца Speaking Clubs <br/>от SkyEng бесплатно!</span>' : 
        (result >= 4 && result <= 7) ? 'Как раз есть подарок в тему – <br/><span>3 месяца Speaking Clubs <br/>от SkyEng бесплатно!</span>' : 
        'Это ещё и бесплатно, ведь за такой крутой результат SkyEng дарит тебе <span>3 месяца Speaking Clubs!</span>';
        
    return (
        <div className={cn}>
            <div className={classNames('TestBanner-content', 'TestBanner-content_affr_result')}>
                <Title cssClass="TestBanner-title">
                    Результат: {result}/{total}
                </Title>
                <Text cssClass="TestBanner-text">
                    {text}
                </Text>

                <CoinLabelSecond result={result} />

                <div className="TestBanner-bottom">
                    <div className="TestBanner-subtitle" dangerouslySetInnerHTML={{__html: subtitle}} />
                    <a href="https://go.skyeng.ru/free-rk/aliexpress" target="_blank" rel="noopener noreferrer">
                        <Button cssClass="TestBanner-btn">
                            Забрать подарок
                        </Button>
                    </a>
                </div>
            </div>
        </div>
    );
};