import { useState, useRef, useEffect, memo } from 'react';
import styled from 'styled-components';

import Arrow from '@assets/svg/collaps-arow.svg?react';
import classNames from 'classnames';

const Wrapper = styled.div`
    display: inline-block;
    position: relative;
    width: auto;
    z-index: 1000;
    margin-bottom: 10px;
`;

const Header = styled.div`
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #FF5F5B;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;

    .arrow {
        margin-left: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            transition: all .3s linear;
        }
        &.is_open {
            svg {
                transform: rotate(180deg);
            }
        }
    }
`;

const List = styled.ul`
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: #FF5F5B;
    z-index: 100;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    overflow: hidden;
    li {
        padding: 4px 10px;
        cursor: pointer;
        background-color: ${props=> props.$isSelect ? '#f0f0f0' : '#FF5F5B'};
        transition: all .3s linear;
        &:hover {
            color: #FF5F5B;
            background-color: #f0f0f0;
        }
    }
`;

const MyCustomSelect = ({ options, openSelect, onSelect, toggleSelect, placeholder }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const dropdownRef = useRef(null);
    const toggleDropdown = () => {
        document.querySelectorAll('.MyCustomSelect[data-open="true"]').forEach(item => item.setAttribute('data-open', 'false'))
        setIsOpen(!isOpen)
        toggleSelect && toggleSelect()
    };

    const handleOptionSelect = (option) => {
        onSelect && onSelect(option);
        setSelectedOption(option);
        setIsOpen(false);
    };

    useEffect(()=> {
        setIsOpen(openSelect);
    }, [openSelect]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
                toggleSelect && toggleSelect()
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [toggleSelect]);

    useEffect(() => {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'attributes') {
                    if (mutation.target.getAttribute('data-open') === 'false') {
                        setIsOpen(false);
                        toggleSelect && toggleSelect()
                    }
                }
            });
        });
    
        if (dropdownRef.current) {
            observer.observe(dropdownRef.current, {
                attributes: true,
            });
        }
    
        return () => {
            observer.disconnect();
        };
    }, [toggleSelect]);
    
    return (
        <Wrapper ref={dropdownRef} className={'MyCustomSelect'} data-open={isOpen}>
            <Header onClick={toggleDropdown}>
                <span>{selectedOption ? selectedOption.label : (placeholder || 'Select an option')}</span>
                <span className={classNames('arrow', isOpen && 'is_open' )}><Arrow /></span>
            </Header>

            {isOpen && (
                <List>
                    {options?.map((option) => (
                        <li
                            key={option.value}
                            onClick={() => handleOptionSelect(option)}
                        >
                            {option.label}
                        </li>
                    ))}
                </List>
            )}
        </Wrapper>
    );
};

export default memo(MyCustomSelect);
