import { memo, useState } from "react"
import Modal from "../Popup/Modal";
import RulesPopup from "../Popup/RulesPopup/RulesPopup";
import PrizesPopup from "../Popup/PrizesPopup/PrizesPopup";
import './RulesAndPrizes.scss';
import card1 from '@assets/img/prizes/1.png';
import card1t from '@assets/img/prizes/1-t.png';
import card1m from '@assets/img/prizes/1-m.png';
import card2 from '@assets/img/prizes/2.png';
import card2t from '@assets/img/prizes/2-t.png';
import card2m from '@assets/img/prizes/2-m.png';

const RulesAndPrizes = () => {
    const [openRules, setOpenRules] = useState(false);
    const [openPrize, setOpenPrize] = useState(false);

    const togglePrize = ()=> setOpenPrize(!openPrize);
    const toggleRules = ()=> setOpenRules(!openRules);

    return (
        <div className="rules-and-prizes">
            <div className="rules-and-prizes-card" onClick={toggleRules}>
                <picture>
                    <source media="(max-width: 480px)" srcSet={card1m} />
                    <source media="(max-width: 1024px)" srcSet={card1t} />
                    <img src={card1} alt=""  width={670} height={212} />
                </picture>
            </div>
            <div className="rules-and-prizes-card" onClick={togglePrize}>
                <picture>
                    <source media="(max-width: 480px)" srcSet={card2m} />
                    <source media="(max-width: 1024px)" srcSet={card2t} />
                    <img src={card2} alt=""  width={670} height={212} />
                </picture>
            </div>

            <Modal 
                isRight
                isOpen={openRules}
                onClose={toggleRules}
                content={<RulesPopup />}
            />
            <Modal 
                isRight
                isOpen={openPrize}
                onClose={togglePrize}
                content={<PrizesPopup />}
            />
        </div>
    )
} 

export default memo(RulesAndPrizes);