import { memo } from "react"
import { InputMask } from '@react-input/mask';

import Title from "@components/common/Title";
import Button from "@components/common/Button";
import Loader from "@components/common/Loader";
import usePrognoz from "./usePrognoz";

import prog from '@assets/img/prognoz/prog.png';
import prog2 from '@assets/img/prognoz/prog-t.png';
import prog3 from '@assets/img/prognoz/prog-t.png';
import './Prognoz.scss'

const Prognoz = () => {
    const { dateInput, onSubmit, onChange, isLoading, current } = usePrognoz();

    return (
        <div className="Prognoz">
            <div className="Prognoz-inner">
                <div className="Prognoz-bg">
                    <picture>
                        <source media="(max-width: 560px)" srcSet={prog3} />
                        <source media="(max-width: 1024px)" srcSet={prog2} />
                        <img src={prog} alt="" width={1360} height={690} />
                    </picture>
                </div>

                <div className="Prognoz-content">
                    <Title cssClass="Prognoz-title" color="#fff">
                        Онлайн прогноз <br/> на неделю
                    </Title>

                    <div className="Prognoz-form">
                        <div className="Prognoz-form-top">
                            <InputMask 
                                mask="__.__" 
                                replacement={{ _: /\d/ }}
                                name="birthday"
                                placeholder="Введите дату рождения"
                                value={dateInput}
                                onChange={onChange}
                            />
                            <Button onClick={onSubmit}>
                                Проверить
                            </Button>
                        </div>
                        <div className="Prognoz-form-result">
                            {isLoading &&
                                <div className="Prognoz-loader">
                                    <Loader />
                                </div>
                            }

                            <div className="Prognoz-form-icon">
                                {current?.icon}
                            </div>
                            <div className="Prognoz-form-title">
                                {current?.title}
                            </div>
                            <div className="Prognoz-form-desc">
                                {current?.desc}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(Prognoz);