export const cartState = {
    CART: 'cart',
    ORDER: 'order',
    ORDERED: 'ordered'
}

export const deliveryState = {
    PICKPOINT: 'pickpoint',
    DELIVERY: 'delivery',
    ONLINE: 'online'
}

export const orderTypeState = {
    ONLINE: 'online',
    OFFLINE: 'offline',
    MIXED: 'mixed'
}
