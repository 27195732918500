import Oven from '@assets/svg/zodiac/oven.svg?react';
import Telec from '@assets/svg/zodiac/telec.svg?react';
import Blezneci from '@assets/svg/zodiac/blezneci.svg?react';
import Rak from '@assets/svg/zodiac/rak.svg?react';
import Lev from '@assets/svg/zodiac/lev.svg?react';
import Deva from '@assets/svg/zodiac/deva.svg?react';
import Vesi from '@assets/svg/zodiac/vesi.svg?react';
import Skorpion from '@assets/svg/zodiac/skorpion.svg?react';
import Strelec from '@assets/svg/zodiac/strelec.svg?react';
import Kozerog from '@assets/svg/zodiac/kozerog.svg?react';
import Vodoley from '@assets/svg/zodiac/vodoley.svg?react';
import Ribi from '@assets/svg/zodiac/ribi.svg?react';

export const prognoz = [
    { 
        time: 1727730000000, 
        list: [
            {
                dateRange: ['21.03', '19.04'],
                id: 1,
                icon: <Oven />,
                title: 'Овен (21 марта — 19 апреля)', 
                desc: 'Твоя энергия зашкаливает! Успех в начинаниях будет зависеть от твоей решительности. Не бойся проявлять инициативу. Надо быть решительнее, как волк. Помни, что он слабее льва и тигра, но в цирке волк не выступает. Ауф!',
            },
            {
                dateRange: ['20.04', '20.05'],
                id: 2,
                icon: <Telec />,
                title: 'Телец (20 апреля — 20 мая)', 
                desc: 'Удачный момент для творчества. Попробуй заняться чем-то новым, например, рисованием, кулинарией, конспирологическими теориями или приворотом на твой промокод. ',
            },
            {
                dateRange: ['21.05', '20.06'],
                id: 3,
                icon: <Blezneci />,
                title: 'Близнецы (21 мая — 20 июня)', 
                desc: 'Твои коммуникативные навыки будут на высоте. Используй это для решения старых конфликтов. На этой неделе ты точно будешь Ali-slayyy, а не Ali-cringe.',
            },
            {
                dateRange: ['21.06', '22.07'],
                id: 4,
                icon: <Rak />,
                title: 'Рак (21 июня — 22 июля)', 
                desc: 'Время для семейных встреч. Поделись своими чувствами с близкими. А лучше поделись с ними своим промокодом!',
            },
            {
                dateRange: ['23.07', '22.08'],
                id: 5,
                icon: <Lev />,
                title: 'Лев (23 июля — 22 августа)', 
                desc: 'Твоё обаяние привлечет внимание окружающих. Используй это для продвижения своих идей. Или для новых фоток в фотобудке.',
            },
            {
                dateRange: ['23.08', '22.09'],
                id: 6,
                icon: <Deva />,
                title: 'Дева (23 августа — 22 сентября)', 
                desc: 'Порядок и организация будут твоим приоритетом. Научись делегировать задачи. Желательно все, чтобы пойти домой, лечь на диванчик и заказать новые товары.',
            },
            {
                dateRange: ['23.09', '22.10'],
                id: 7,
                icon: <Vesi />,
                title: 'Весы (23 сентября — 22 октября)', 
                desc: 'Социальные связи укрепляются. Участвуй в мероприятиях и общайся с новыми людьми. Можешь рассказать им, как получить скидку 500 рублей на товары по твоему уникальному промокоду!',
            },
            {
                dateRange: ['23.10', '21.11'],
                id: 8,
                icon: <Skorpion />,
                title: 'Скорпион (23 октября — 21 ноября)', 
                desc: 'Эмоции будут накалены. Постарайся контролировать свои реакции и избегать конфликтов. Лучше расслабься и закажи успокаивающие БАДы от iHerb на AliExpress.',
            },
            {
                dateRange: ['22.11', '21.12'],
                id: 9,
                icon: <Strelec />,
                title: 'Стрелец (22 ноября — 21 декабря)', 
                desc: 'Приключения зовут! Спонтанные поездки или новые увлечения подарят тебе радость. Не важно, уедешь ли ты в Витебск или в Монте-Карло, закажешь на AliExpress костюм квадробера или набор для вязания — главное, чтобы тебе понравилось.',
            },
            {
                dateRange: ['22.12', '19.01'],
                id: 10,
                icon: <Kozerog />,
                title: 'Козерог (22 декабря — 19 января)', 
                desc: 'Рабочие успехи будут на первом плане. Усердие и организованность принесут плоды. А вечером можешь заказать себе что-нибудь новенькое, ведь на этой неделе ты молодец!',
            },
            {
                dateRange: ['20.01', '18.02'],
                id: 11,
                icon: <Vodoley />,
                title: 'Водолей (20 января — 18 февраля)', 
                desc: 'Креативные идеи будут приходить в голову. Не бойся делиться ими с окружающими, даже если ты придумываешь новый танец на корпоратив.',
            },
            {
                dateRange: ['19.02', '20.03'],
                id: 12,
                icon: <Ribi />,
                title: 'Рыбы (19 февраля — 20 марта)', 
                desc: 'Твои мечты будут особенно яркими. Позволь себе немного помечтать и расслабиться. Именно это и скажи начальнику, если спросит, почему ты целыми днями сидишь и заказываешь товары на AliExpress.',
            }
        ]
    },
    { 
        time: 1731877200000, 
        list: [
            {
                dateRange: ['21.03', '19.04'],
                id: 1,
                icon: <Oven />,
                title: 'Овен (21 марта — 19 апреля)', 
                desc: 'Возможны неожиданные встречи. Будь готов к общению и новым знакомствам. Возможно, именно у тебя возьмут интервью о том, сколько миллионов в день ты тратишь на AliExpress.',
            },
            {
                dateRange: ['20.04', '20.05'],
                id: 2,
                icon: <Telec />,
                title: 'Телец (20 апреля — 20 мая)', 
                desc: 'Финансовые вопросы выйдут на первый план. Будь внимательнее к своим расходам. Пей кофе с корицей по четвергам и говори «Меняю отходы на доходы», когда выбрасываешь упаковку от новых заказов. ',
            },
            {
                dateRange: ['21.05', '20.06'],
                id: 3,
                icon: <Blezneci />,
                title: 'Близнецы (21 мая — 20 июня)', 
                desc: 'Возможны интересные предложения в работе или учебе. Не упусти шанс! Шанс отказаться от всего этого и просто уехать в отпуск тоже есть. ',
            },
            {
                dateRange: ['21.06', '22.07'],
                id: 4,
                icon: <Rak />,
                title: 'Рак (21 июня — 22 июля)', 
                desc: 'Эмоциональная стабильность будет важна. Постарайся избегать конфликтов и сосредоточься на позитиве. Купи, что ли, коврики Live Love Life и развесь по всей квартире.',
            },
            {
                dateRange: ['23.07', '22.08'],
                id: 5,
                icon: <Lev />,
                title: 'Лев (23 июля — 22 августа)', 
                desc: 'Возможны творческие успехи. Дай волю своему воображению! Запиши рэп альбом или придумай стихи про AliExpress, а потом выступи со своим шедевром на Ali Voice!',
            },
            {
                dateRange: ['23.08', '22.09'],
                id: 6,
                icon: <Deva />,
                title: 'Дева (23 августа — 22 сентября)', 
                desc: 'Возможны новые знакомства в профессиональной сфере. Не упусти шанс заработать свой первый (или не первый?) миллион!',
            },
            {
                dateRange: ['23.09', '22.10'],
                id: 7,
                icon: <Vesi />,
                title: 'Весы (23 сентября — 22 октября)', 
                desc: 'Время для романтики! Проведи время с любимым человеком или устрой свидание. Дейт-идея: встреть любимого человека дома на полу, в кругу сердца из упаковок товаров с AliExpress.',
            },
            {
                dateRange: ['23.10', '21.11'],
                id: 8,
                icon: <Skorpion />,
                title: 'Скорпион (23 октября — 21 ноября)', 
                desc: 'Возможны неожиданные повороты в личной жизни. Готовься к изменениям. Возможно, сейчас именно твоя очередь поучаствовать в «Давай поженимся» или «Четыре свадьбы».',
            },
            {
                dateRange: ['22.11', '21.12'],
                id: 9,
                icon: <Strelec />,
                title: 'Стрелец (22 ноября — 21 декабря)', 
                desc: 'Твой оптимизм привлечет людей. Используй это для налаживания связей. Ведь все любят беспричинно счастливых людей во время тяжелых рабочих недель.',
            },
            {
                dateRange: ['22.12', '19.01'],
                id: 10,
                icon: <Kozerog />,
                title: 'Козерог (22 декабря — 19 января)', 
                desc: 'Возможны новые карьерные возможности. Готовься к переменам. Тебя явно ждёт повышение, и мы сейчас не про цены.',
            },
            {
                dateRange: ['20.01', '18.02'],
                id: 11,
                icon: <Vodoley />,
                title: 'Водолей (20 января — 18 февраля)', 
                desc: 'Социальные мероприятия принесут радость и новые знакомства. Главное не заходить не в ту дверь и не посещать вечеринки P-Diddy.',
            },
            {
                dateRange: ['19.02', '20.03'],
                id: 12,
                icon: <Ribi />,
                title: 'Рыбы (19 февраля — 20 марта)', 
                desc: 'Время для творчества! Занятия искусством или музыкой принесут удовольствие. Запиши свой первый трек про лям в кармане.',
            }
        ]
    },
    { 
        time: 1732482000000, 
        list: [
            {
                dateRange: ['21.03', '19.04'],
                id: 1,
                icon: <Oven />,
                title: 'Овен (21 марта — 19 апреля)', 
                desc: 'Время для саморазмышлений. Позаботься о себе и удели время отдыху. Поиграй в плойку, посмотри в одну точку, сгоняй на ретрит или просто позалипай, выбирая товары!',
            },
            {
                dateRange: ['20.04', '20.05'],
                id: 2,
                icon: <Telec />,
                title: 'Телец (20 апреля — 20 мая)', 
                desc: 'Обрати внимание на свои близкие отношения. Проведи время с семьей или друзьями! Или с кошкой, или с хомяком, или с крысой, или с муравьиной фермой… Смотря кто вместе с тобой выбирает позиции на AliExpress.',
            },
            {
                dateRange: ['21.05', '20.06'],
                id: 3,
                icon: <Blezneci />,
                title: 'Близнецы (21 мая — 20 июня)', 
                desc: 'Пора заняться саморазвитием. Только пожалуйста, не золоти ручку инфоцыганам. Чтение книг или изучение нового языка принесет пользу.',
            },
            {
                dateRange: ['21.06', '22.07'],
                id: 4,
                icon: <Rak />,
                title: 'Рак (21 июня — 22 июля)', 
                desc: 'Займись своим здоровьем. Ощути себя ученым, применяя эффект плацебо: пей на выходных безалкогольное пиво, катаясь на колесе обозрения.',
            },
            {
                dateRange: ['23.07', '22.08'],
                id: 5,
                icon: <Lev />,
                title: 'Лев (23 июля — 22 августа)', 
                desc: 'Не забывай о своих обязанностях. Баланс между работой и отдыхом важен. Звезды разрешают тебе спать на рабочем месте.',
            },
            {
                dateRange: ['23.08', '22.09'],
                id: 6,
                icon: <Deva />,
                title: 'Дева (23 августа — 22 сентября)', 
                desc: 'Удели время своему здоровью. Занятия спортом помогут восстановить силы. Можешь попробовать квадробику или хобби-хорсинг — будь в тренде.',
            },
            {
                dateRange: ['23.09', '22.10'],
                id: 7,
                icon: <Vesi />,
                title: 'Весы (23 сентября — 22 октября)', 
                desc: 'Обрати внимание на свои внутренние чувства. Медитация или йога помогут тебе найти гармонию. И пускай все твои завистники трясутся от непоколебимости в твоей собственной офигенности.',
            },
            {
                dateRange: ['23.10', '21.11'],
                id: 8,
                icon: <Skorpion />,
                title: 'Скорпион (23 октября — 21 ноября)', 
                desc: 'Пора заняться самоанализом. Размышления о будущем помогут определиться с приоритетами. Только не задумывайся слишком сильно, а то пропустишь новые акции.',
            },
            {
                dateRange: ['22.11', '21.12'],
                id: 9,
                icon: <Strelec />,
                title: 'Стрелец (22 ноября — 21 декабря)', 
                desc: 'Обрати внимание на финансовые вопросы. Планирование бюджета поможет избежать проблем. Используй математику: если сумка за двадцать тысяч, которая лежит у тебя в корзине, выйдет в свет очень много раз, то у нее цена меньше, чем у стаканчика кофе.',
            },
            {
                dateRange: ['22.12', '19.01'],
                id: 10,
                icon: <Kozerog />,
                title: 'Козерог (22 декабря — 19 января)', 
                desc: 'Удели время своим близким. Забота о семье укрепит твои отношения. Что, в общем-то, и так очевидно…. Можно было и не напоминать!',
            },
            {
                dateRange: ['20.01', '18.02'],
                id: 11,
                icon: <Vodoley />,
                title: 'Водолей (20 января — 18 февраля)', 
                desc: 'Пора задуматься о своих целях. Четкое планирование помогает достичь желаемого. Запланируй, например, отпуск или сходи и забери новую посылку.',
            },
            {
                dateRange: ['19.02', '20.03'],
                id: 12,
                icon: <Ribi />,
                title: 'Рыбы (19 февраля — 20 марта)', 
                desc: 'Обрати внимание на свое эмоциональное состояние. Медитация или прогулки на свежем воздухе помогут восстановить баланс. Вдохни на четыре счета и скажи фразу: «Я работаю в лучшей компании». Это ведь настоящая правда, не так ли?',
            }
        ]
    }
];
