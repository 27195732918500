import classNames from "classnames";

import Title from "../common/Title";
import Text from "../common/Text";
import Button from "../common/Button";
import { useTestStart } from "./useTestStart";
import './TestBanner.scss';

export const FirstBanner = ({ onOpen }) => {
    const { isLoading, getTestStart } = useTestStart({
        idx: 'review',
        onSuccess() {
            onOpen();
        }
    });

    const cn = classNames('TestBanner', 'TestBanner_first');

    return (
        <div className={cn}>
            <div className="TestBanner-content">
                <Title cssClass="TestBanner-title">
                    Профессиональный отзовик
                </Title>
                <Text cssClass="TestBanner-text">
                    Сколько товаров ты сможешь опознать по отзыву? 
                    Проверь себя – внимательно читай реальные отзывы, которые мы нашли на 
                    просторах AliExpress, и за 30 секунд попытайся понять, о чём же рассказывает его автор?
                </Text>
                <Button cssClass="TestBanner-btn" isLoading={isLoading} onClick={getTestStart}>
                    Начать!
                </Button>
            </div>
        </div>
    );
};