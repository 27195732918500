import { configureStore } from '@reduxjs/toolkit';
import cartReducer from './features/cartSlice';
import modalsReducer from './features/modalsSlice';
import userReducer from './features/userSlice';
import productsReducer from './features/productsSlice';
import gamesReducer from './features/gamesSlice';

export const store = configureStore({
    reducer: {
        cart: cartReducer,
        modals: modalsReducer,
        user: userReducer,
        products: productsReducer,
        games: gamesReducer,
    },
});