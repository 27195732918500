import { memo } from 'react';
import styled from 'styled-components';

const RadioStyles = styled.label`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    input {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;

        &:checked ~ i {
            background: #000;
        }
    }

    i {
        width: 20px;
        min-width: 20px;
        height: 20px;
        background-color: transparent;
        border: 5px solid #fff;
        border-radius: 50%;
        outline: 1px solid #bf9494;
        transition: all .3s linear;
    }

    .radio-text {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;
        color: #000;
        span {
            color: #FE2722;
        }
    }
`


const Radio = ({ children, ...inputProps }) => {
    return (
        <RadioStyles>
            <input type="radio" {...inputProps} />
            <i></i>
            <span className="radio-text">{children}</span>
        </RadioStyles>
    );
};

export default memo(Radio);