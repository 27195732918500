import Row from '../common/Row'
import './FunExpress.scss';
import express from '@assets/img/fun-express.svg';
import express1 from '@assets/img/fun-express-t.png';
import express2 from '@assets/img/fun-express-m.png';

const FunExpress = () => {
    return (
        <Row className='express'>
            <picture>
                <source media="(max-width: 480px)" srcSet={express2} />
                <source media="(max-width: 768px)" srcSet={express1} />
                <img alt='logo' className='express__pic' src={express} />
            </picture>
        </Row>
    )
}

export default FunExpress
