import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    attempts: {
        aliWord: null,
        darts: null,
        memorium: null,
        random: null,
    },
    gameType: null,
};

export const gamesSlice = createSlice({
    name: 'games',
    initialState,
    reducers: {
        setParams(state, action) {
            state.attempts = action.payload
        },
        setGameType(state, action) {
            state.gameType = action.payload
        }
    }
})

export const { setParams, setGameType } = gamesSlice.actions

export default gamesSlice.reducer