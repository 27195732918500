import { useEffect, useRef } from 'react'
import gsap from 'gsap'
import Card1Image from '../../assets/card1.svg'
import Card2Image from '../../assets/card2.svg'
import Card3Image from '../../assets/card3.svg'
import Card4Image from '../../assets/card4.svg'
import Card5Image from '../../assets/card5.svg'
import Card6Image from '../../assets/card6.svg'

export function GameRandomCard({
  delay = 0,
  resultIndex = 0,
  className = '',
  onComplete = () => {}
}) {
  const innerRef = useRef(null)
  const wrapperRef = useRef(null)
  const timeline = useRef(null)
  const spin = useRef({ 
    y: 0
  })
  
  useEffect(() => {
    const repeat = 3
    const duration = 1
    const count = 6
    const height = 100 / (count + 1)
    const totalHeight = count * height
    const offset = -height * resultIndex

    innerRef.current.style.transform = `translateY(${offset}%)`

    timeline.current = gsap.timeline({
      delay,
      onComplete
    })
      .to(spin.current, {
        y: -100 * repeat,
        onUpdate: () => {
          innerRef.current.style.transform = `translateY(${(offset + spin.current.y) % (totalHeight)}%)`
        },
        ease: 'power2.inOut',
        duration: duration * repeat
      }, 0)
      .to(wrapperRef.current, {
        scaleY: 1.3,
        duration: duration * repeat / 2,
        yoyo: true,
        repeat: 1
      }, 0)
    
      return () => {
        timeline.current?.kill()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className={`randomizer-card ${className}`.trim()}>
        <div ref={wrapperRef} className="randomizer-card__wrapper">
          <div ref={innerRef} className="randomizer-card__inner">
            <img src={Card1Image} />
            <img src={Card2Image} />
            <img src={Card3Image} />
            <img src={Card4Image} />
            <img src={Card5Image} />
            <img src={Card6Image} />
            <img src={Card1Image} />
          </div>
        </div>
      </div>
    </>
  )
}
