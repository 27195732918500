import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Title from '@components/common/Title';
import Text from '@components/common/Text';
import Button from '@components/common/Button';
import { getApiRoute } from "@config";
import { setOpenPhotoBooth } from '@store/features';

import Loop from '@assets/svg/loop.svg?react';
import Download from '@assets/svg/download.svg?react';
import styles from './Result.module.scss';
import axios from 'axios';

function generateId() {
    return `id-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
}

function base64ToBlob(base64, mime) {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mime });
}

const Result = ({ result, onReset }) => {
    const dispatch = useDispatch();
    const { accessToken } = useSelector(state => state.user);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);

    const downloadPhoto = () => {
		const link = document.createElement('a');
		link.href = result;
		link.download = 'aliverse_photo_'+generateId();
		link.click();
    }

    const onSubmit = async () => {
        setIsLoading(true);
        try {
            const mimeType = "image/png";
            const blob = base64ToBlob(result, mimeType);
            const formData = new FormData();
            formData.append('photo', blob, 'image.png'); 
            
            await axios.post(getApiRoute('photo'), formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + accessToken || '',
                }
            });
            setMessage('success');
        } catch (err) {
            console.log(err);
            setMessage('error');
        } finally {
            setIsLoading(false);
        }
    }

    const handleReset = () => {
        if (message === 'error') {
            onReset();
        };
        
        if (message === 'success') {
            dispatch(setOpenPhotoBooth({ isOpenPhotoBooth: false }));
        }; 
    }

    if (message) {
        return (
            <div className={styles.block}>
                <div className={styles.message}>
                    <Title cssClass={styles.title}>
                        {message === 'error' ?
                            'Что-то пошло не так...' :
                            'Твоё фото успешно загружено!' 
                        }
                    </Title>

                    <Text cssClass={styles.text}>
                        {message === 'error' ? 
                            'Попробуй ещё раз' : 'Ищи себя в «Фото дня»'
                        }
                    </Text>

                    <Button cssClass={styles.button} onClick={handleReset}>
                        Хорошо
                    </Button>
                </div>
            </div>
        );
    }


    return (
        <div className={styles.block}>
            <div className={styles.content}>
                <Title cssClass={styles.title}>
                    Отлично <br/> получилось!
                </Title>
                <div className={styles.bottom}>
                    <Text cssClass={styles.text}>
                        Участвуй в «Фото дня», чтобы
                        собрать миллионы лайков коллег!
                    </Text>

                    <div className={styles.control}>
                        <Button
                            variant="red"
                            cssClass={styles.submit}
                            onClick={onSubmit}
                            isLoading={isLoading}
                        >
                            Участвовать
                        </Button>
                        <button
                            className={styles.btn}
                            onClick={onReset}
                            data-tooltip="Новое фото"
                        >
                            <Loop />
                        </button>
                        <button
                            className={styles.btn}
                            onClick={downloadPhoto}
                            data-tooltip="Сохранить фото"
                        >
                            <Download />
                        </button>
                    </div>
                </div>
            </div>

            <div className={styles.image}>
                <picture>
                    <img src={result} alt="" width={366} height={366} />
                </picture>
            </div>
        </div>
    );
};

export default memo(Result);