import { useInsertionEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import Home from "./Pages/Home";
import { useLocalStorage } from "@hooks";
import { getApiRoute } from "@config";
import { setUserProfile, setUserToken, setIsLoading, setUserDestroy } from "@store/features";

function App() {
    const [isTokenDeat, setIsTokenDeat] = useState(false);
    const dispatch = useDispatch();
    const { storedValue: token, setValue, hardRemove } = useLocalStorage('_ali_access_token');

    useInsertionEffect(()=> {
        const refreshToken = async () => {
            dispatch(setIsLoading({ isLoading: true }));
            try {
                const res = await axios.post(getApiRoute('auth.refresh'), {
                    refresh_token: token.refresh_token
                })
                dispatch(setUserToken({
                    accessToken: res.data.response,
                }))
               setValue(res.data.response)
               setIsTokenDeat(false);
            } catch (err) {
                console.log(err);
                hardRemove();
                dispatch(setUserDestroy());
            } finally {
                dispatch(setIsLoading({ isLoading: false }));
            }
        }
        if (isTokenDeat) {
            refreshToken();
        }
    }, [isTokenDeat]);

    useInsertionEffect(()=> {
        const getUser = async () => {
            dispatch(setIsLoading({ isLoading: true }));
            try {
                const res = await axios.get(getApiRoute('user'), {
                    headers: {
                        Authorization: 'Bearer '+token.access_token
                    }
                });
                dispatch(setUserProfile({
                    profile: res.data.response,
                }));
                dispatch(setUserToken({
                    accessToken: token.access_token,
                }));
            } catch (err) {
                console.log(err);
                setIsTokenDeat(true);
            } finally {
                dispatch(setIsLoading({ isLoading: false }));
            }
        }

        if (token && token.access_token && !isTokenDeat) {
            getUser();
        } else {
            hardRemove();
            dispatch(setUserDestroy());
        }
    }, [token, isTokenDeat]);

    return (
       <Home />
    );
}

export default App;
