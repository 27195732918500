import { useState, useEffect } from 'react';

export const useCountDown = (name, initialSeconds) => {
    const [seconds, setSeconds] = useState(initialSeconds || 59);
    const [isTicking, setIsTiking] = useState(false);
    useEffect(() => {
        const getRegisterData = sessionStorage.getItem(name);
        if (getRegisterData) {
            const rezerv = new Date(getRegisterData);
            const date = new Date();
            const time =
                (rezerv.getMinutes() - date.getMinutes()) * 60 +
                rezerv.getSeconds() -
                date.getSeconds();
            if (time >= 0) {
                setSeconds(time);
                setIsTiking(true);
            } else {
                sessionStorage.removeItem(name);
            }
        }
    }, [name, setSeconds]);

    useEffect(() => {
        if (isTicking) {
            const interval = setInterval(() => {
                setSeconds((prevSeconds) => {
                    if (prevSeconds > 0) {
                        return prevSeconds - 1;
                    } else {
                        setIsTiking(false);
                        sessionStorage.removeItem(name);
                        clearInterval(interval);
                        return 0;
                    }
                });
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [isTicking, name]);

    const startTimer = () => {
        const date = new Date();
        date.setMinutes(date.getMinutes() + 1);
        sessionStorage.setItem(name, date.toString());
        setSeconds(59);
        setIsTiking(true);
    };

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return {
        startTimer,
        min: minutes,
        sec: remainingSeconds,
        isTicking,
        timerDisplay: `0${minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`,
    };
};
