import { memo } from 'react';
import classNames from 'classnames';

import { priceFormatter } from '@utils';

import BasketIcon from '@assets/svg/basket.svg?react';
import Coin from '@assets/svg/coin.svg?react';
import Checked from '@assets/svg/checked-1.svg?react';
import Delete from '@assets/svg/garbage.svg?react';
import styles from './BasketPopup.module.scss';

const BasketList = ({
    data,
    orders,
    setProduct,
    deleteOrder,
    availability,
}) => {
    const isChoose = id => orders ? orders?.indexOf(id) !== -1 : false;

    if (!data || data.length === 0) {
        return (
            <div className={styles.empty}>
                <BasketIcon />
                Ваша корзина пуста
            </div>
        );
    };

    const isDisable = ({ id, type, size_id }) => {
        const item = availability?.find(item => item.id === id).balance.find(item => item.size_id === size_id);
        const res = item ? (type === 'ru' ? item.ru_balance : type === 'global' ? item.abroad_balance : item.online_balance) === 0 : false;
        return res;
    };

    return (
        <ul className={styles.list}>
            {data?.map(item => {
                const isDisabled = isDisable(item);
                return (
                    <li key={item.id} className={classNames(isDisabled && styles.is_disabled)}>
                        <button 
                            className={classNames(styles.checked, isChoose(item.id) && styles.is_choose)} 
                            onClick={isDisabled ? null : setProduct(item.id)}
                        >
                            <Checked />
                        </button>
                        <div className={styles.image}>
                            <picture>
                                <img src={item.main_image} alt="" width={125} height={125} />
                            </picture>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.name}>{item.name}</div>
                            {isDisabled ? 
                                <div className={styles.red}>Товар раскупили</div> :
                                <>
                                    <div className={styles.type}>
                                        {item?.type === 'global' ? 'Узбекистан' : item?.type === 'ru' ? 'Россия' : 'Онлайн' }
                                    </div>
                                    <div className={styles.cion}>
                                        <Coin />
                                        {priceFormatter(item.price)}
                                    </div>
                                </>
                            }
                        </div>
                        <button className={styles.delete} onClick={deleteOrder(item.id)}>
                            <Delete />
                        </button>
                    </li>
                )
            })}
        </ul>
    );
};
export default memo(BasketList);