import './arrow-link.scss'

export function ArrowLink({ children, onClick, className }) {
	return (
		<div
			className={`arrow-link ${className}`.trim()}
			onClick={onClick}
			role="button"
		>
			{children}
		</div>
  	)
}
