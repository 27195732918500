import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpenAuth: false,
    stage: 'register',
    isOpenTest: false,
    testType: null, 
    isTestStart: false,
    isTestFinish: false,
    isCompleteTest: false,
    isOpenSharedCoin: false,
    isOpenFear: false,
    isOpenBasket: false,
    isOpenPhotoBooth: false,
    isErrorTest: false,
};

export const modalsSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        setOpenAuth(state, action) {
            state.isOpenAuth = action.payload.isOpenAuth
            state.stage = action.payload.stage
        },
        setOpenTest(state, action) {
            state.isOpenTest = action.payload.isOpenTest
            state.testType = action.payload.testType
        },
        setTestStart(state, action) {
            state.isTestStart = action.payload.isTestStart
        },
        setTestFinish(state, action) {
            state.isTestFinish = action.payload.isTestFinish
        },
        setCompleteTest(state, action) {
            state.isCompleteTest = action.payload.isCompleteTest
        },
        setOpenSharedCoin(state, action) {
            state.isOpenSharedCoin = action.payload.isOpenSharedCoin
        },
        setOpenFear(state, action) {
            state.isOpenFear = action.payload.isOpenFear
        },
        setOpenBasket(state, action) {
            state.isOpenBasket = action.payload.isOpenBasket
        },
        setOpenPhotoBooth(state, action) {
            state.isOpenPhotoBooth = action.payload.isOpenPhotoBooth
        },
        setOpenErrorTest(state, action) {
            state.isErrorTest = action.payload
        }
    },
});


export const { 
    setOpenAuth,
    setOpenTest, 
    setTestStart, 
    setCompleteTest, 
    setTestFinish, 
    setOpenSharedCoin, 
    setOpenFear, 
    setOpenBasket,
    setOpenPhotoBooth,
    setOpenErrorTest,
} = modalsSlice.actions;

export default modalsSlice.reducer;